import React from 'react';
import { Box } from '@mui/material';
import Svg1 from "../../assets/Svg/vector1.svg";
import Svg2 from "../../assets/Svg/vector2.svg";
import Svg3 from "../../assets/Svg/vector3.svg";
import * as styles from "./Styles";

function Background() {
  return (
    <Box sx={styles.backgroundContainer}>
      <img src={Svg1} alt="Background 1" style={styles.svg1} />
      <img src={Svg2} alt="Background 2" style={styles.svg2} />
      <img src={Svg3} alt="Background 3" style={styles.svg3} />
    </Box>
  );
}

export default Background;
