import {
  Typography,
  IconButton,
  Card,
  CardContent,
  CardActions,
  Box,
} from "@mui/material";
import { ArrowForward, WidthFull } from "@mui/icons-material";
import { Link } from "react-router-dom";
import * as TextVariables from "../../assets/Variables/TextVariables.js";
import { Spaces } from "../../assets/Variables/SpaceVariables.js";
import Colors from "../../assets/Variables/ColorsVariables.js";

function CardChat({ from, value, date }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        padding:
          from === "Support"
            ? `0 ${Spaces.Size_14} 0 0`
            : `0 0 0 ${Spaces.Size_14}`,
        justifyContent: from === "Support" ? "flex-start" : "flex-end",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: Spaces.Size_1,
          fontFamily: TextVariables.Caption_2_Bold,
          color: Colors.Secondary_700
        }}
      >
        {from},
        <Typography sx={{fontFamily: TextVariables.Caption_2, color: Colors.Secondary_400}}>{date}</Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          padding: Spaces.Size_6,
          alignItems: "center",
          borderRadius: from === "Support" ? `0 ${Spaces.Size_3} ${Spaces.Size_3} ${Spaces.Size_3}` : `${Spaces.Size_3} 0 ${Spaces.Size_3} ${Spaces.Size_3}`,
          background: from === "Support" ? Colors.Gray_100 : Colors.Secondary_50,
        }}
      >
        <Typography sx={{fontFamily: TextVariables.Caption_1}}>{value}</Typography>
      </Box>
    </Box>
  );
}

export default CardChat;
