import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Paper,
  Box,
  Grid,
  Card,
  CardContent,
  Button,
} from "@mui/material";
import BarChart from "../../components/Charts/BarChart.js";
import LinesChart from "../../components/Charts/LinealChart.js";
import TitledSelector from "../../components/Selectors/TitledSelector.js";
import DateSelector from "../../components/Selectors/DateSelector.js";
import InfoCard from "../../components/Cards/InfoCard.js";
import TextDate from "../../components/Texts/DateText.js";
import ApplyButton from "../../components/Buttons/ApplyButton.js";
import LoadingSpinner from "../../components/Loaders/SpinLoader.js";
import * as styles from "../Dashboard/Styles.js";
import { Spaces, mainBox } from "../../assets/Variables/SpaceVariables.js";
import Colors from "../../assets/Variables/ColorsVariables.js";
import * as TextVariables from "../../assets/Variables/TextVariables";
import { useCookies } from "../../Tools/CookieChecker.js";
import ClientInfoRequest from "../../Endpoints/ClientInfoRequest.js";
import {
  SetDashboardData,
  GetDashboardData,
  RemoveDashboardData,
  SetDashboardSelectors,
  GetDashboardSelectors,
} from "../../Tools/LocalStorage.js";
import {
  getLocalTimeZoneOffsetInSeconds,
  getTimestamps,
} from "../../Tools/DateTimeConvertions";
import dayjs from 'dayjs';
import WalletRequest from "../../Endpoints/WalletRequest.js";

function DashboardIndexPage(props) {
  const { user, name, type, token } = useCookies();
  const [dateSelect, setDateSelect] = useState("Today");
  const [brokerSelect, setBrokerSelect] = useState("All");
  const [showDataPickers, setShowDataPickers] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const navigate = useNavigate();
  const [dashboard, setDashboard] = useState({
    total_accounts: 0,
    new_accounts: 0,
    total_volume: 0,
    total_deposits: 0,
    total_withdraws: 0,
    total_commissions: 0,
  });
  const [ibs, setIbs] = useState([]);
  const [wallet, setWallet] = useState({});
  const [loading, setLoading] = useState(false);
  const [applyDisable, setApplyDisable] = useState(false);
  const [barData, setBarData] = useState({
    labels: [],
    datasets: [
      {
        axis: "y",
        label: ["My First Dataset"],
        data: [0, 0, 0],
        fill: false,
        backgroundColor: [
          Colors.Secondary_900,
          Colors.Info_500,
          Colors.Primary_400,
        ],
        borderColor: [
          Colors.Secondary_900,
          Colors.Info_500,
          Colors.Primary_400,
        ],
        borderWidth: 1,
      },
    ],
  });

  useEffect(() => {
    RequestWalletData();
    if (!GetLocalStorageData()) {
      let requestData = getTimestamps("Today");
      RequestDashboardData(requestData);
      SetDashboardSelectors({
        dateSelector: "Today",
        fromDate: null,
        toDate: null,
        ib: "All"
      })
      setDateSelect("Today")
      setFromDate(null)
      setToDate(null)
      setBrokerSelect("All")
    }
  }, []);

  const RequestWalletData = async () => {
    try{
      const response = await WalletRequest();
      if (response.data != null) {
        setWallet(response.data.data.wallet)
      }
      else{
        setWallet({id: "", currency:"USD", balance:0})
      }
    }
    catch(error){
      setWallet({id: "", currency:"USD", balance:0})
      console.log(error);
    }
  }

  const GetLocalStorageData = () => {
    let dataSelector = GetDashboardSelectors()
    if(dataSelector != null){
      if(dataSelector.dateSelector === "Custom") 
        setShowDataPickers(true)
      setDateSelect(dataSelector.dateSelector)
      setFromDate(dayjs(dataSelector.fromDate))
      setToDate(dayjs(dataSelector.toDate))
      setBrokerSelect(dataSelector.ib)
    }
    else{
      setDateSelect("Today")
      setFromDate(null)
      setToDate(null)
      setBrokerSelect("All")
    }

    let dataResponse = GetDashboardData();
    if (dataResponse.dataExits) {
      setDashboard(dataResponse.dashboard);
      const formattedData = dataResponse.ibs.map((item) => ({
        label: item.name,
        value: item.email_ib,
      }));

      setIbs([{ label: "All", value: "All" }, ...formattedData]);
      setBarData((prevBarData) => ({
        ...prevBarData,
        labels: [
          `Active accounts (${dataResponse.dashboard.total_active_accounts})`,
          `Inactive accounts (${dataResponse.dashboard.total_inactive_accounts})`,
          `Unfunded accounts (${dataResponse.dashboard.total_unfunded_accounts})`,
        ],
        datasets: [
          {
            ...prevBarData.datasets[0],
            data: [
              dataResponse.dashboard.total_active_accounts,
              dataResponse.dashboard.total_inactive_accounts,
              dataResponse.dashboard.total_unfunded_accounts,
            ],
          },
        ],
      }));
      return true;
    } else {
      return false;
    }
  };

  const RequestDashboardData = async (datetime) => {
    try {
      setLoading(true);
      setApplyDisable(true);
      if(brokerSelect && brokerSelect != "All")
        datetime.ib_email = brokerSelect
      const response = await ClientInfoRequest(datetime);
      if (response.data.data != null) {
        SetDashboardData(response, brokerSelect !== "All");
        setDashboard({
          total_accounts: response.data.data.dashboard.total_accounts,
          new_accounts: response.data.data.dashboard.new_accounts,
          total_volume: response.data.data.dashboard.total_volume,
          total_deposits: response.data.data.dashboard.total_deposits,
          total_withdraws: response.data.data.dashboard.total_withdraws,
          total_commissions: response.data.data.dashboard.total_commissions
        });
        setBarData((prevBarData) => ({
          ...prevBarData,
          labels: [
            `Active accounts (${response.data.data.dashboard.total_active_accounts})`,
            `Inactive accounts (${response.data.data.dashboard.total_inactive_accounts})`,
            `Unfunded accounts (${response.data.data.dashboard.total_unfunded_accounts})`,
          ],
          datasets: [
            {
              ...prevBarData.datasets[0],
              data: [
                response.data.data.dashboard.total_active_accounts,
                response.data.data.dashboard.total_inactive_accounts,
                response.data.data.dashboard.total_unfunded_accounts,
              ],
            },
          ],
        }));
              // Actualiza brokersOptions
      const formattedBrokers = response.data.data.ibs.map((item) => ({
        label: item.name,
        value: item.email_ib,
      }));

      if(brokerSelect && brokerSelect === "All")
        setIbs([{ label: "All", value: "All" }, ...formattedBrokers]);
      SetDashboardSelectors({
        dateSelector: dateSelect,
        fromDate: fromDate,
        toDate: toDate,
        ib: brokerSelect
      })
      setLoading(false);
      } else {
        RemoveDashboardData();
        setDashboard({});
        SetDashboardSelectors({})
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      RemoveDashboardData();
      setDashboard({});
      SetDashboardSelectors({});
      setLoading(false);
    }
    setLoading(false);
    setApplyDisable(false);
  };

  const handleDateSelector = (event) => {
    setDateSelect(event.target.value);
    if (event.target.value === "Custom") {
      setShowDataPickers(true);
    } else {
      setShowDataPickers(false);
    }
  };

  const handleBrokerIbSelector = (event) => {
    setBrokerSelect(event.target.value);
  };

  const handleFromChange = (date) => {
    setFromDate(date);
  };

  const handleToChange = (date) => {
    setToDate(date);
  };

  const handleSeeWalletdetails = () => {
    navigate("/my-wallet");
  };

  const HandleOnClick = () => {
    if (dateSelect !== "Custom") {
      let datetime = getTimestamps(dateSelect);
      RequestDashboardData({
        from: datetime.from,
        to: datetime.to,
      });
      SetDashboardSelectors({
        dateSelector: dateSelect,
        fromDate: null,
        toDate: null,
        ib: brokerSelect
      })
    } else {
      if (fromDate != null || toDate != null) {
        RequestDashboardData({
          from: fromDate / 1000 + getLocalTimeZoneOffsetInSeconds(),
          to: toDate / 1000 + getLocalTimeZoneOffsetInSeconds() + 86400,
        });
        SetDashboardSelectors({
          dateSelector: dateSelect,
          fromDate: fromDate,
          toDate: toDate,
          ib: brokerSelect
        })
      }
    }
  };

  const dateOptions = [
    { label: "Today", value: "Today" },
    { label: "This week", value: "This week" },
    { label: "This month", value: "This month" },
    { label: "This year", value: "This year" },
    { label: "Custom", value: "Custom" },
  ];

  const dataGrid = [
    {
      title: "Total accounts",
      value: `${dashboard.total_accounts}`,
      icon: "group",
      toolText: ""
    },
    {
      title: "New accounts",
      value: dashboard.new_accounts,
      icon: "person_add",
      toolText: "The amount of accounts opened during the selected period of time."
    },
    {
      title: "Volume (Lots)",
      value: (dashboard.total_volume || 0).toFixed(2),
      icon: "layers",
      toolText: ""
    },
    {
      title: "Deposits (USD)",
      value: `$${(dashboard.total_deposits|| 0).toFixed(2)}`,
      icon: "download",
      toolText: ""
    },
    {
      title: "Withdrawals (USD)",
      value: `$${(dashboard.total_withdraws|| 0).toFixed(2)}`,
      icon: "input",
      toolText: ""
    },
    {
      title: "Payouts (USD)",
      value: `$${(dashboard.total_commissions|| 0).toFixed(2)}`,
      icon: "monetization_on",
      toolText: ""  
    },
  ];

  const [chartData, setChartData] = React.useState({
    labels: ["Week 1", "Week 2", "Week 3", "Week 4"],
    datasets: [
      {
        label: "Volume",
        data: [65, 59, 80, 81],
        borderColor: "rgba(75, 192, 192, 1)",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        yAxisID: "y-axis-volumen",
        tension: 0.4,
      },
      {
        label: "Payouts",
        data: [28, 48, 40, 19],
        borderColor: "rgba(153, 102, 255, 1)",
        backgroundColor: "rgba(153, 102, 255, 0.2)",
        yAxisID: "y-axis-dinero",
        tension: 0.4,
      },
      {
        label: "Deposits",
        data: [35, 29, 50, 71],
        borderColor: "rgba(255, 159, 64, 1)",
        backgroundColor: "rgba(255, 159, 64, 0.2)",
        yAxisID: "y-axis-dinero",
        tension: 0.4,
      },
    ],
  });

  return (
    <Box sx={mainBox}>
      {loading ? <LoadingSpinner /> : <></>}
      <Paper elevation={0} sx={styles.PaperStyle}>
        <Box sx={styles.TitleBox}>
          <Typography gutterBottom fontFamily={TextVariables.Body_3_bold}>
            Dashboard
          </Typography>
          <Typography
            variant="h5"
            component="h2"
            gutterBottom
            fontFamily={TextVariables.Body_4}
          >
            Track your clients' performance and your results over time.
          </Typography>
        </Box>
        <Box sx={{display: "flex", flexDirection: "row", alignItems: "flex-end"}}>
          <TitledSelector
            title="Period of time"
            value={dateSelect}
            handleChange={handleDateSelector}
            options={dateOptions}
          />
          {showDataPickers && (
            <>
              <DateSelector
                title="From"
                value={fromDate}
                handleChange={handleFromChange}
              />
              <DateSelector
                title="To"
                value={toDate}
                handleChange={handleToChange}
              />
            </>
          )}
          {type === "BROKER" || type === "MASTER_IB" ? (
            <TitledSelector
              title="IB selector"
              value={brokerSelect}
              handleChange={handleBrokerIbSelector}
              options={ibs}
              tooltext="Select a Broker or IB linked to you through trees (payout structures) to see their general performance overview."
            />)
            : (null)
          }
          
          <ApplyButton HandleOnClick={HandleOnClick} disable={applyDisable}>Apply</ApplyButton>
        </Box>
        <Box margin={`${Spaces.Size_7} 0 0 0`}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={9} container spacing={3}>
              {dataGrid.map((item, index) => (
                <Grid key={index} item xs={12} sm={6} md={4}>
                  <InfoCard
                    title={item.title}
                    value={item.value}
                    icon={item.icon}
                    tooltext={item.toolText}
                  />
                </Grid>
              ))}
            </Grid>
            <Grid item xs={12} md={3}>
              <Card style={styles.BalanceCardStyle}>
                <CardContent style={styles.BalanceCardContentStyle}>
                  <Box>
                    <span
                      className="material-symbols-outlined"
                      style={{ fontSize: "24px" }}
                    >
                      monetization_on
                    </span>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection={"column"}
                    alignItems={"center"}
                  >
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{ fontFamily: TextVariables.Caption_1 }}
                    >
                      My wallet
                    </Typography>
                    <Typography
                      variant="h5"
                      component="div"
                      sx={{ fontFamily: TextVariables.Body_3_bold }}
                    >
                      ${(wallet.balance || 0).toFixed(2)} {wallet.currency || "USD"}
                    </Typography>
                  </Box>
                  <Button
                    onClick={handleSeeWalletdetails}
                    sx={{
                      height: 33,
                      width: 89,
                      color: "black",
                      fontFamily: TextVariables.Caption_bold_1,
                      textTransform: "none",
                      borderRadius: Spaces.Size_2,
                      padding: Spaces.Size_2,
                      gap: 8,
                      "&:hover": {
                        border: "1px solid black",
                      },
                    }}
                  >
                    See details
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
        <Paper elevation={0} sx={styles.PaperChildrenStyle}>
          <Box sx={styles.AccountStatisticalBoxStyle}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                textAlign: "left",
                marginRight: "20px",
                gap: Spaces.Size_3,
              }}
            >
              <Typography sx={TextVariables.Body_3_bold}>
                Monthly active account
              </Typography>
              <Typography sx={TextVariables.Body_4}>
                Keep updated on the general status of your accounts.
              </Typography>
              <TextDate icon={"calendar_today"} text={"June, 2024"}></TextDate>
            </Box>
            <Paper sx={styles.AccountsChartPaperStyle}>
              <BarChart dataChart={barData} />
            </Paper>
          </Box>
        </Paper>
        <Paper elevation={0} sx={styles.PaperPerformance}>
          <Box
            sx={{
              height: "100%",
              width: "100%",
              padding: Spaces.Size_7,
              transform: "scale(1)",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: Spaces.Size_7,
            }}
          >
            <Box>
              <Typography gutterBottom fontFamily={TextVariables.Body_3_bold}>
                Perfomance Metrics
              </Typography>
              <TextDate icon={"calendar_today"} text={"June, 2024"}></TextDate>
            </Box>

            <LinesChart data={chartData} />
          </Box>
        </Paper>
        <Box sx={{ marginBottom: "100px" }}></Box>
      </Paper>
    </Box>
  );
}

export default DashboardIndexPage;
