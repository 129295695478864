import { Typography, Card, CardContent, Box } from "@mui/material";
import * as TextVariables from "../../assets/Variables/TextVariables";
import {Spaces} from "../../assets/Variables/SpaceVariables.js";

function TextDate({ icon, text }) {
  return (
    <Box display={"flex"} flexDirection={"row"} gap={Spaces.Size_1}>
        <span className="material-symbols-outlined" style={{ fontSize: '20px' }}>{icon}</span>
        <Typography
            sx={TextVariables.Body_4}
          >
            {text}
          </Typography>
    </Box>
  );
}

export default TextDate;
