import { Box } from "@mui/material";

function CustomTapPanelComponent(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel" 
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
}

export default CustomTapPanelComponent;