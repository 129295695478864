import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  TextField,
  Box,
  ListSubheader,
  InputAdornment,
} from "@mui/material";
import * as TextVariables from "../../assets/Variables/TextVariables.js";
import Colors from "../../assets/Variables/ColorsVariables.js";
import { Spaces } from "../../assets/Variables/SpaceVariables.js";

const TitledSelectorGeneral = ({
  name,
  title,
  value,
  handleChange,
  options,
  error,
  helperText,
  placeholder,
  disabled = false,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedOption, setSelectedOption] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const selectStyle = {
    fontFamily: TextVariables.Caption_1,
    ".MuiSelect-select": {
      fontFamily: TextVariables.Caption_1, // Cambia el tipo de letra aquí
      lineHeight: "1.4",
    },

    borderRadius: Spaces.Size_2,
    border: error ? "1px solid red" : "0px solid",
    width: "100%",
    padding: "0px",
    border: "none", // Elimina el borde predeterminado
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none", // Asegúrate de que el borde del contorno también esté desactivado
    },
    border: error
      ? `1px solid ${Colors.Error_700}`
      : disabled
      ? `1px solid ${Colors.Gray_200}`
      : `1px solid ${Colors.Secondary_700}`,
  };

  const stopImmediatePropagation = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <div>
      <Typography
        sx={{
          marginRight: "10px",
          marginBottom: "5px",
          fontFamily: TextVariables.Body_4,
          color: disabled ? Colors.Gray_500 : Colors.Secondary_700,
        }}
      >
        {title}
      </Typography>

      <Select
        value={value}
        onChange={handleChange}
        sx={selectStyle}
        placeholder={placeholder}
        inputProps={{ name: name }}
        displayEmpty
        disabled={disabled}
        MenuProps={{
          disableAutoFocusItem: true, // Deshabilita el autofocus en los elementos del menú
        }}
      >
        <ListSubheader sx={{ display: "flex", padding: Spaces.Size_3 }}>
          <TextField
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: `1px solid ${Colors.Secondary_400}`,
              borderRadius: Spaces.Size_2,
              fontFamily: TextVariables.Caption_1,
              padding: "0px",
              "& .MuiInputBase-input": {
                fontFamily: TextVariables.Caption_1,
                padding: Spaces.Size_4,
              },
            }}
            onClickCapture={stopImmediatePropagation}
            onKeyDown={(e) => e.stopPropagation()}
            fullWidth
            placeholder="Search"
            onChange={handleSearchChange}
            value={searchTerm}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <span className="material-symbols-outlined">search</span>
                </InputAdornment>
              ),
              sx: {
                "& .MuiInputBase-input::placeholder": {
                  fontFamily: TextVariables.Caption_1,
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none", // Elimina el borde
                },
              },
              autoComplete: "off",
            }}
          />
        </ListSubheader>
        <MenuItem value="" disabled>
          <Typography
            sx={{
              fontFamily: TextVariables.Caption_1,
              color: Colors.Secondary_200,
            }}
          >
            Select an option
          </Typography>
        </MenuItem>

        {filteredOptions.map((option, index) => (
          <MenuItem
            key={index}
            value={option.value}
            sx={{ fontFamily: TextVariables.Caption_1 }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>

      {error && helperText}
    </div>
  );
};

TitledSelectorGeneral.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default TitledSelectorGeneral;
