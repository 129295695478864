import { Padding } from "@mui/icons-material";

export const backgroundContainer = {
  position: "absolute",
  width: "100%",
  height: "100%",
  overflow: "hidden",
  top: 0,
  left: 0,
  zIndex: -10,
};
export const svg1 = {
  position: "absolute",
  top: "0",
  right: "0",
  width: "40%",
  opacity: "0.75",
  filter: "blur(8vw)",
};

export const svg2 = {
  position: "absolute",
  top: "0",
  right: "0",
  width: "40%",
  opacity: "0.65",
  filter: "blur(4vw)",
};

export const svg3 = {
  position: "absolute",
  top: "0",
  right: "0",
  width: "15%",
  opacity: "0.65",
  filter: "blur(7vw)",
};

export const backgroundDashboardContainer = {
  position: "absolute",
  width: "100%",
  height: "100%",
  overflow: "hidden",
  top: 0,
  left: 0,
  zIndex: -10,
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  justifyContent: "center"
};

export const svg4 = {
  position: "absolute",
  top: "0",
  right: "0",
  

};

export const svg5 = {
  position: "absolute",
  top: "0",
  right: "0",
};

export const svg6 = {
  position: "absolute",
  top: "0",
  right: "0",
};

export const svg7 = {
  position: "absolute",
  top: "0",
  right: "0",
};
