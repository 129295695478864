import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function TreesCreatePage() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/my-trees/create/step-one');
  }, [navigate]);

  return null;
}

export default TreesCreatePage;
