import classes from "../../scss/Layout.module.scss";
import LanguageSelector from "../../components/Selectors/languageSelector.js";
import logo_tradeview from "../../assets/logo_tradeview_white.svg";
import { Box } from "@mui/material";
import * as styles from "../NavAndSide/Styles";
import Background from "../../components/Backgrounds/Background";
import DashboardBackground from "../../components/Backgrounds/DashboardBackground";

const NavbarLayout = (props) => {

  return (
    <div>
      {props.type === "register" && <DashboardBackground></DashboardBackground>}
      {props.type === undefined && <Background></Background>}     
      <Box sx={styles.navbar}>

        <img src={logo_tradeview} alt="Tradeview" width={"200"} />
        <LanguageSelector></LanguageSelector>
      </Box>
      <Box sx={styles.contentLogin}>{props.children}</Box>
    </div>
  );
};

export default NavbarLayout;
