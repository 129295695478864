export function getLocalTimeZoneOffsetInSeconds() {
    let offsetInMinutes = new Date().getTimezoneOffset();
    let offsetInSeconds = offsetInMinutes * -60;
    
    return offsetInSeconds;
}

export function getTimestamps(period) {
    let now = new Date();
    let start, end;

    // Convertir la fecha actual a UTC
    let nowUTC = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds()));

    switch (period) {
        case 'Today':
            start = new Date(Date.UTC(nowUTC.getUTCFullYear(), nowUTC.getUTCMonth(), nowUTC.getUTCDate()));
            end = new Date(Date.UTC(nowUTC.getUTCFullYear(), nowUTC.getUTCMonth(), nowUTC.getUTCDate(), 23, 59, 59, 999));
            break;
        case 'This week':
            let dayOfWeek = nowUTC.getUTCDay(); // 0 (Sun) - 6 (Sat)
            start = new Date(Date.UTC(nowUTC.getUTCFullYear(), nowUTC.getUTCMonth(), nowUTC.getUTCDate() - dayOfWeek));
            end = new Date(Date.UTC(nowUTC.getUTCFullYear(), nowUTC.getUTCMonth(), nowUTC.getUTCDate() - dayOfWeek + 6, 23, 59, 59, 999));
            break;
        case 'This month':
            start = new Date(Date.UTC(nowUTC.getUTCFullYear(), nowUTC.getUTCMonth(), 1));
            end = new Date(Date.UTC(nowUTC.getUTCFullYear(), nowUTC.getUTCMonth() + 1, 0, 23, 59, 59, 999));
            break;
        case 'This year':
            start = new Date(Date.UTC(nowUTC.getUTCFullYear(), 0, 1));
            end = new Date(Date.UTC(nowUTC.getUTCFullYear(), 11, 31, 23, 59, 59, 999));
            break;
        default:
            start = new Date(Date.UTC(nowUTC.getUTCFullYear(), nowUTC.getUTCMonth(), nowUTC.getUTCDate()));
            end = new Date(Date.UTC(nowUTC.getUTCFullYear(), nowUTC.getUTCMonth(), nowUTC.getUTCDate(), 23, 59, 59, 999));
            break;
    }

    let from = Math.floor(start.getTime() / 1000);
    let to = Math.floor(end.getTime() / 1000);

    return { from, to };
}

export function ConvertUTCToDate(utcFecha) {
    let fecha = new Date(utcFecha * 1000);

    let year = fecha.getUTCFullYear();
    let mounth = String(fecha.getUTCMonth() + 1).padStart(2, '0');
    let day = String(fecha.getUTCDate()).padStart(2, '0');
    let hour = String(fecha.getUTCHours()).padStart(2, '0');
    let minute = String(fecha.getUTCMinutes()).padStart(2, '0');
    let seconds = String(fecha.getUTCSeconds()).padStart(2, '0');

    return `${year}/${mounth}/${day} ${hour}:${minute}:${seconds}`;
}

export function ConvertUTCToDateEUFormat(utcFecha) {
    let fecha = new Date(utcFecha * 1000);

    let year = fecha.getUTCFullYear();
    let mounth = String(fecha.getUTCMonth() + 1).padStart(2, '0');
    let day = String(fecha.getUTCDate()).padStart(2, '0');
    let hour = String(fecha.getUTCHours()).padStart(2, '0');
    let minute = String(fecha.getUTCMinutes()).padStart(2, '0');
    let seconds = String(fecha.getUTCSeconds()).padStart(2, '0');

    return `${hour}:${minute}:${seconds} ${mounth}/${day}/${year}`;
}

export function convertToUnixTimestamp(dateString) {
    let [datePart, timePart] = dateString.split(' ');
    let [month, day, year] = datePart.split('/').map(Number);
    let [hour, minute, second] = timePart.split(':').map(Number);

    let date = new Date(Date.UTC(year, month - 1, day, hour, minute, second));

    return Math.floor(date.getTime() / 1000);
}

export function getCurrentUnixTimestamp() {
    let now = new Date();
    let unixTimestamp = Math.floor(now.getTime() / 1000);
    
    return unixTimestamp;
}

