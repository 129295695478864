import { Box, Button } from "@mui/material";
import IconComponent from "../Icons/IconComponent";
import * as styles from "./Styles.js";
import { useNavigate } from "react-router-dom";

function BreadkCrumbComponent(props) {
    const navigate = useNavigate();

    return(
        <Button 
            onClick={() => {navigate(props.backPageLink);}} 
            startIcon={<IconComponent icon="chevron_left" size="16px"/> } 
            sx={styles.BreakCrumStyle}
        >
            #{props.clientNumber} / <Box sx={styles.BreakCrumStyleName}>{props.clientName}</Box><Box sx={styles.BreakCrumStyleCountry}> • {props.clientCountry}</Box>
        </Button>
    );
}

export default BreadkCrumbComponent;