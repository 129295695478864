import React, { useState } from "react";
import { Typography, Box, Paper } from "@mui/material";
import * as styles from "../Styles";

function TransferModal({ requestInfo, formattedDate }) {
  return (
    <Paper sx={styles.paperTreeDetails}>
    <Box sx={styles.labelContainerTreeDetails}>
      <Typography sx={styles.labelTreeDetails}>ID:</Typography>
      <Typography sx={styles.valueTreeDetails}>
        {requestInfo.id}
      </Typography>
    </Box>
    <Box sx={styles.labelContainerTreeDetails}>
      <Typography sx={styles.labelTreeDetails}>
        Request time:
      </Typography>
      <Typography sx={styles.valueTreeDetails}>
        {formattedDate}
      </Typography>
    </Box>
    <Box sx={styles.labelContainerTreeDetails}>
      <Typography sx={styles.labelTreeDetails}>
        Request type:
      </Typography>
      <Typography sx={styles.valueTreeDetails}>
        {requestInfo.request_type.toLowerCase().replace("_", " ")}
      </Typography>
    </Box>
    <Box sx={styles.labelContainerTreeDetails}>
      <Typography sx={styles.labelTreeDetails}>State:</Typography>
      <Typography sx={styles.valueTreeDetails}>
        {requestInfo.state.toLowerCase()}
      </Typography>
    </Box>
    <Box sx={styles.labelContainerTreeDetails}>
      <Typography sx={styles.labelTreeDetails}>Amount:</Typography>
      <Typography sx={styles.valueTreeDetails}>
        {requestInfo.request.amount}
      </Typography>
    </Box>
    <Box sx={styles.labelContainerTreeDetails}>
      <Typography sx={styles.labelTreeDetails}>
        Server:
      </Typography>
      <Typography sx={styles.valueTreeDetails}>
        {requestInfo.request.server}
      </Typography>
    </Box>
    <Box sx={styles.labelContainerTreeDetails}>
      <Typography sx={styles.labelTreeDetails}>
        Login:
      </Typography>
      <Typography sx={styles.valueTreeDetails}>
        {requestInfo.request.login}
      </Typography>
    </Box>
  </Paper>
  );
}

export default TransferModal;
