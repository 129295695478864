import { Typography, Card, CardContent, Box } from "@mui/material";
import * as TextVariables from "../../assets/Variables/TextVariables";
import { Spaces } from "../../assets/Variables/SpaceVariables.js";
import TableToolTip from "../../components/ToolTips/TableToolTip";

function InfoCard({ icon, title, value, tooltext = "" }) {
  return (
    <Card
      sx={{
        borderRadius: Spaces.Size_5,
        background:
          "linear-gradient(156deg, rgba(255, 255, 255, 0.50) 10.28%, rgba(255, 255, 255, 0.00) 106.66%) !important",
        border: "1px solid rgba(255, 18, 246, 0.06)",
        boxShadow: "0px 4px 24px 0px rgba(0, 0, 0, 0.08)",
        backdropFilter: "blur(20px)",
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          padding: `${Spaces.Size_7} ${Spaces.Size_5} ${Spaces.Size_7} ${Spaces.Size_5}`,
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: Spaces.Size_1,
              alignItems: "center",
            }}
          >
            <Typography
            variant="h6" component="div"
              sx={{
                fontFamily: TextVariables.Caption_1,
              }}
            >
              {title}
            </Typography>
            {tooltext !== "" && (
              <TableToolTip title={tooltext}>
                  <span
                    style={{ fontSize: Spaces.Size_3 }}
                    className="material-symbols-outlined"
                  >
                    help
                  </span>
              </TableToolTip>
            )}
          </Box>
          <Typography
            variant="h5"
            component="div"
            sx={TextVariables.Body_3_bold}
          >
            {value}
          </Typography>
        </Box>
        <Box sx={{ margin: "10px 0 0 0", width: 32, height: 32 }}>
          <span className="material-symbols-outlined">{icon}</span>
        </Box>
      </CardContent>
    </Card>
  );
}

export default InfoCard;
