import * as TextVariables from "../../assets/Variables/TextVariables.js";
import Colors from "../../assets/Variables/ColorsVariables.js";
import {Spaces} from "../../assets/Variables/SpaceVariables.js";

export const TitleStyle = { 
  fontFamily: "Plus Jakarta Sans",
  fontSize: "17px",
  fontWeight: '600',
  fontStyle: 'normal',
  letterSpacing: '0.15px',
  color: '#15202A'
}

export const mainPaper = {

    display: "flex",
    flexDirection: "column",
    padding: Spaces.Size_5,
    width: "calc(100vw - 280px)",
    // maxHeight: "calc(100vh - 108px)",
    maxWidth: "1110px",
    borderRadius: "12px", 
    gap: Spaces.Size_5
};

export const DownloadBottom = {
  border: "none",
  background: "none",
  padding: "10%",
  minWidth: 0,
  width: "auto",
  height: "auto",
};

export const linkButton = {
  width: "125px",
  fontFamily: TextVariables.Caption_2,
  color: Colors.Secondary_800,
  textTransform: "none",
  display: "flex",
  padding: `${Spaces.Size_1} ${Spaces.Size_2}`,

  gap: "2px",
  borderRadius: Spaces.Size_2,
};

export const pendingButton = {
  display: "flex",
  alignItems: "center",
  fontFamily: TextVariables.Caption_2,
  color: Colors.Warning_900,
  textTransform: "none",
  padding: `${Spaces.Size_1} ${Spaces.Size_2}`,
  background: Colors.Warning_100,
  gap: "2px",
  borderRadius: Spaces.Size_2,
};

export const modalScrollContainer = {
  height: "75vh",
  padding: "0px 10px",
  overflowY: "scroll",
  "::-webkit-scrollbar": {
    width: "8px",
    height: "4px",
  },
  "::-webkit-scrollbar-thumb": {
    backgroundColor: "#888",
    borderRadius: "10px",
    "&:hover": {
      backgroundColor: "#555",
    },
  },
  "::-webkit-scrollbar-track": {
    background: "#f1f1f1",
  },
};

export const modalContainer = {
  display: "flex",
  height: "75vh",
  flexDirection: "column",
  gap: Spaces.Size_7,
  alignItems: "flex-start",
};

export const paperTreeDetails = {
  display: "flex",
  width: "100%",
  padding: `18px ${Spaces.Size_10}`,
  flexDirection: "column",
  alignItems: "flex-start",
  gap: Spaces.Size_3,
  background: Colors.Secondary_50,
};

export const labelContainerTreeDetails = {
  display: "flex",
  flexDirection: "row",
  gap: Spaces.Size_1,
  padding: `${Spaces.Size_1} 0px`,
};

export const labelTreeDetails = {
  fontFamily: TextVariables.Body_4,
};

export const valueTreeDetails = {
  fontFamily: TextVariables.Body_4_Bold,
};

export const modalView = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  padding: Spaces.Size_5,
  height: "auto",
  borderRadius: "15px",
};
