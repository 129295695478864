import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Typography, Paper, Box, Button } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import * as styles from "../BackofficeRequest/Styles.js";
import { Spaces } from "../../assets/Variables/SpaceVariables.js";
import * as TextVariables from "../../assets/Variables/TextVariables.js";
import Colors from "../../assets/Variables/ColorsVariables.js";
import TitledInput from "../../components/Inputs/TitledInput.js";
import * as MasterData from "../../MasterData/MasterData.js";
import TextInputWithCounter from "../../components/Inputs/TitledBigInput.js";
import TitledSelectorGeneral from "../../components/Selectors/TitledSelectorGeneral.js";
import CryptowalletForm from "./WithdrawalsComponents/CryptowalletForm.js";
import BankWireTransferForm from "./WithdrawalsComponents/BankWireTransferForm.js";
import NetellerForm from "./WithdrawalsComponents/NetellerForm.js";
import SkrillForm from "./WithdrawalsComponents/SkrillForm.js";
import UpholdForm from "./WithdrawalsComponents/UpholdForm.js";
import BitpayForm from "./WithdrawalsComponents/BitpayForm.js";
import BitwalletForm from "./WithdrawalsComponents/BitwalletForm.js";
import SticPayForm from "./WithdrawalsComponents/SticPayForm.js";
import AdvcashForm from "./WithdrawalsComponents/AdvcashForm.js";
import InteracETransferForm from "./WithdrawalsComponents/InteracETransferForm.js";
import FasapayForm from "./WithdrawalsComponents/FasapayForm.js";
import JPayForm from "./WithdrawalsComponents/JPayForm.js";
import UnionPayForm from "./WithdrawalsComponents/UnionPayForm.js";
import WalletRequest from "../../Endpoints/WalletRequest.js";
import WithdrawalRequest from "../../Endpoints/WithdrawalRequest.js";
import NotificationModal from "../../components/Notifications/NotificationModal.js";

function WithdrawalRequestPage(props) {
  const navigate = useNavigate();
  const [t] = useTranslation("global");
  const [availableAmount, setAvailableAmount] = useState(0);
  const [showNotification, setShowNotification] = useState(false);
  const [modalProps, setModalProps] = useState({
    iconType: "",
    title: "",
    message: "",
    onAcceptLabel: undefined,
    onRefuseLabel: undefined,
    onAccept: undefined,
    onRefuse: undefined,
  });
  const [errors, setErrors] = useState({
    //General fields
    amount: false,
    comment: false,
    withdrawalMethod: false,
    //Digital wallet fields
    beneficiaryBankName: false,
    branchName: false,
    province: false,
    city: false,
    beneficiaryName: false,
    //Bank wire transfer fields and jpay
    bankName: false,
    swiftCode: false,
    abaNumber: false,
    sortCode: false,
    referenceNumber: false,
    intermediaryBank: false,
    intermediaryBankName: false,
    intermediaryBankAddress: false,
    swiftCodeIntermediaryBank: false,
    ibanNumberIntermediaryBank: false,
    abaNumberIntermediaryBank: false,
    sortCodeIntermediaryBank: false,
    //CriptoWallet
    network: false,
    //Multi use fields
    beneficiaryAddress: false,
    wallet: false,
    accountNumber: false,
    associatedEmail: false,
  });
  const [formValues, setFormValues] = useState({
    //General fields
    amount: "",
    comment: "",
    withdrawalMethod: "",
    //Digital wallet fields
    beneficiaryBankName: "",
    branchName: "",
    province: "",
    city: "",
    beneficiaryName: "",
    //Bank wire transfer fields and jpay
    bankName: "",
    swiftCode: "",
    abaNumber: "",
    sortCode: "",
    referenceNumber: "",
    intermediaryBank: "",
    intermediaryBankName: "",
    intermediaryBankAddress: "",
    swiftCodeIntermediaryBank: "",
    ibanNumberIntermediaryBank: "",
    abaNumberIntermediaryBank: "",
    sortCodeIntermediaryBank: "",
    //CriptoWallet
    network: "",
    //Multi use fields
    beneficiaryAddress: "",
    wallet: "",
    accountNumber: "",
    associatedEmail: "",
  });

  let requiredFields = [];
  const handleChange = (event) => {
    const { name, value } = event.target;
    // Actualiza los valores del formulario
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: value,
    }));
  };

  useEffect(() => {
    RequestWalletData();
  }, []);

  const RequestWalletData = async () => {
    try {
      const response = await WalletRequest();
      if (response.data != null) {
        setAvailableAmount(parseFloat(response.data.data.wallet.balance).toFixed(2));
      } else {
        setAvailableAmount(0);
      }
    } catch (error) {
      setAvailableAmount(0);
      console.log(error);
    }
  };

  const RequestWithdrawalData = async (params) => {
    try {
      const response = await WithdrawalRequest(params);
      if (response && response.data != null) {
        setModalProps({
          iconType: "success",
          title: "Your withdrawal request was sent successfully",
          message: (
            <>
              Our team will review your request and notify you of the resolution
              shortly.
              <br />
              <br />
              You can also check the status of your request by clicking 'Check
              request status' in the 'Requests' tab.
            </>
          ),
          onAcceptLabel: "Continue",
          onAccept: () => handleOnAccept(true),
        });
        setShowNotification(true);
      } else {
        setModalProps({
          iconType: "warning",
          title: "Error in request",
          message: "Please try later",
          onAcceptLabel: "Try again",
          onRefuseLabel: "Close",
          onAccept: () => handleOnAccept(),
          onRefuse: () => handleOnRefuse(),
        });
        setShowNotification(true);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          console.log("error 400:" + error.response.data.message);
          setModalProps({
            iconType: "warning",
            title: "Problem with request",
            message: error.response.data.message,
            onAcceptLabel: "Try again",
            onAccept: () => handleOnAccept(),
          });
          setShowNotification(true);
        } else {
          setModalProps({
            iconType: "error",
            title: "Problem with service",
            message:
              "The following error has been caused:" +
              error.response.data.message +
              ". please contact an administrator",
            onAcceptLabel: "Try again",
            onAccept: () => handleOnAccept(),
          });
          setShowNotification(true);
        }
      } else {
        setModalProps({
          iconType: "error",
          title: "Problem with service",
          message: "Please contact an administrator - " + error.message,
          onAcceptLabel: "Accept",
          onAccept: () => handleOnAccept(),
        });
        setShowNotification(true);
      }
    }
  };

  const handleSelectMethodChange = (event) => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      withdrawalMethod: event.target.value,
    }));
  };

  const handleCloseNotification = () => {
    setShowNotification(false);
  };

  const renderComponent = () => {
    let withdrawalMethodObject = [];

    switch (formValues.withdrawalMethod) {
      case "Cryptowallet":
        withdrawalMethodObject = MasterData.withdrawalMethods.find(
          (method) => method.label === formValues.withdrawalMethod
        );
        requiredFields = withdrawalMethodObject.requiredFields;
        return (
          <CryptowalletForm
            formValues={formValues}
            errors={errors}
            handleChange={handleChange}
          />
        );
      case "Bank wire transfer":
        withdrawalMethodObject = MasterData.withdrawalMethods.find(
          (method) => method.label === formValues.withdrawalMethod
        );
        requiredFields = withdrawalMethodObject.requiredFields;
        return (
          <BankWireTransferForm
            formValues={formValues}
            errors={errors}
            handleChange={handleChange}
          />
        );
      case "Neteller":
        withdrawalMethodObject = MasterData.withdrawalMethods.find(
          (method) => method.label === formValues.withdrawalMethod
        );
        requiredFields = withdrawalMethodObject.requiredFields;
        return (
          <NetellerForm
            formValues={formValues}
            errors={errors}
            handleChange={handleChange}
          ></NetellerForm>
        );
      case "Skrill":
        withdrawalMethodObject = MasterData.withdrawalMethods.find(
          (method) => method.label === formValues.withdrawalMethod
        );
        requiredFields = withdrawalMethodObject.requiredFields;
        return (
          <SkrillForm
            formValues={formValues}
            errors={errors}
            handleChange={handleChange}
          ></SkrillForm>
        );
      case "Uphold":
        withdrawalMethodObject = MasterData.withdrawalMethods.find(
          (method) => method.label === formValues.withdrawalMethod
        );
        requiredFields = withdrawalMethodObject.requiredFields;
        return (
          <UpholdForm
            formValues={formValues}
            errors={errors}
            handleChange={handleChange}
          ></UpholdForm>
        );
      case "BitPay":
        withdrawalMethodObject = MasterData.withdrawalMethods.find(
          (method) => method.label === formValues.withdrawalMethod
        );
        requiredFields = withdrawalMethodObject.requiredFields;
        return (
          <BitpayForm
            formValues={formValues}
            errors={errors}
            handleChange={handleChange}
          ></BitpayForm>
        );
      case "Bitwallet":
        withdrawalMethodObject = MasterData.withdrawalMethods.find(
          (method) => method.label === formValues.withdrawalMethod
        );
        requiredFields = withdrawalMethodObject.requiredFields;
        return (
          <BitwalletForm
            formValues={formValues}
            errors={errors}
            handleChange={handleChange}
          ></BitwalletForm>
        );
      case "SticPay":
        withdrawalMethodObject = MasterData.withdrawalMethods.find(
          (method) => method.label === formValues.withdrawalMethod
        );
        requiredFields = withdrawalMethodObject.requiredFields;
        return (
          <SticPayForm
            formValues={formValues}
            errors={errors}
            handleChange={handleChange}
          ></SticPayForm>
        );
      case "Advcash":
        withdrawalMethodObject = MasterData.withdrawalMethods.find(
          (method) => method.label === formValues.withdrawalMethod
        );
        requiredFields = withdrawalMethodObject.requiredFields;
        return (
          <AdvcashForm
            formValues={formValues}
            errors={errors}
            handleChange={handleChange}
          ></AdvcashForm>
        );
      case "Interac e-Transfer":
        withdrawalMethodObject = MasterData.withdrawalMethods.find(
          (method) => method.label === formValues.withdrawalMethod
        );
        requiredFields = withdrawalMethodObject.requiredFields;
        return (
          <InteracETransferForm
            formValues={formValues}
            errors={errors}
            handleChange={handleChange}
          ></InteracETransferForm>
        );
      case "Fasapay":
        withdrawalMethodObject = MasterData.withdrawalMethods.find(
          (method) => method.label === formValues.withdrawalMethod
        );
        requiredFields = withdrawalMethodObject.requiredFields;
        return (
          <FasapayForm
            formValues={formValues}
            errors={errors}
            handleChange={handleChange}
          ></FasapayForm>
        );
      case "JPay":
        withdrawalMethodObject = MasterData.withdrawalMethods.find(
          (method) => method.label === formValues.withdrawalMethod
        );
        requiredFields = withdrawalMethodObject.requiredFields;
        return (
          <JPayForm
            formValues={formValues}
            errors={errors}
            handleChange={handleChange}
          ></JPayForm>
        );
      case "UnionPay":
        withdrawalMethodObject = MasterData.withdrawalMethods.find(
          (method) => method.label === formValues.withdrawalMethod
        );
        requiredFields = withdrawalMethodObject.requiredFields;
        return (
          <UnionPayForm
            formValues={formValues}
            errors={errors}
            handleChange={handleChange}
          ></UnionPayForm>
        );
      case "JPConnect":
        withdrawalMethodObject = MasterData.withdrawalMethods.find(
          (method) => method.label === formValues.withdrawalMethod
        );
        requiredFields = withdrawalMethodObject.requiredFields;
        return <></>;
      default:
        return <></>;
    }
  };

  const handleSubmit = () => {
    let valid = true;
    let newErrors = {};

    if (formValues.withdrawalMethod === "") {
      requiredFields = ["amount", "withdrawalMethod"];
    }

    // Verificar cada campo
    for (const [key, value] of Object.entries(formValues)) {
      if ((value === "" || value === false) && requiredFields.includes(key)) {
        if (
          key === "intermediaryBankName" ||
          key === "intermediaryBankAddress" ||
          key === "swiftCodeIntermediaryBank" ||
          key === "ibanNumberIntermediaryBank" ||
          key === "abaNumberIntermediaryBank" ||
          key === "sortCodeIntermediaryBank"
        ) {
          if (formValues.intermediaryBank === "Yes") {
            newErrors[key] = true;
            valid = false;
          }
        } else {
          newErrors[key] = true;
          valid = false;
        }
      } else {
        newErrors[key] = false;
      }
    }

    if (formValues.amount > availableAmount) {
      setModalProps({
        iconType: "warning",
        title: "Amount not allowed",
        message:
          "The amount entered is not greater than the allowed value, please review and try again",
        onAcceptLabel: "Try again",
        onAccept: () => handleOnAccept(),
      });
      setShowNotification(true);
      return;
    }

    setErrors(newErrors);

    if (valid) {
      RequestWithdrawalData(formValues);
    } else {
      setModalProps({
        iconType: "warning",
        title: "Unsuccesful request",
        message:
          "We encountered an issue while processing your withdrawal request. Please verify the information and try again. If the problem continues, contact us.",
        onAcceptLabel: "Try again",
        onRefuseLabel: "Close",
        onAccept: () => handleOnAccept(),
        onRefuse: () => handleOnRefuse(),
      });
      setShowNotification(true);
    }
  };

  const handleOnAccept = (optionalParam) => {
    if (optionalParam) {
      handleClickToBackPage();
    } else {
    }
  };

  const handleOnRefuse = () => {};

  const handleClickToBackPage = () => {
    navigate("/requests");
  };

  return (
    <Box sx={styles.mainBox}>
      <NotificationModal
        open={showNotification}
        onClose={handleCloseNotification}
        iconType={modalProps.iconType}
        title={modalProps.title}
        message={modalProps.message}
        onAcceptLabel={modalProps.onAcceptLabel}
        onRefuseLabel={modalProps.onRefuseLabel}
        onAccept={modalProps.onAccept}
        onRefuse={modalProps.onRefuse}
      />
      <Box sx={styles.secondBox}>
        <Box sx={styles.backButtonContainer}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button
              onClick={handleClickToBackPage}
              startIcon={<ArrowBackIosIcon style={{ fontSize: 10 }} />}
              sx={{ textTransform: "none", color: "black" }}
            >
              Go back
            </Button>
          </Box>
        </Box>
        <Paper elevation={2} sx={styles.mainPaper}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: Spaces.Size_8,
            }}
          >
            <Typography sx={{ fontFamily: TextVariables.Body_3_Bold }}>
              Withdrawal Request
            </Typography>
            <Box sx={styles.withdrawlAmountBox}>
              <Typography sx={styles.avalaibleAmountText}>
                <span
                  style={{ fontSize: "16px" }}
                  className="material-symbols-outlined"
                >
                  output_circle
                </span>{" "}
                Withdrawable amount
              </Typography>
              <Box sx={styles.AmountAssetContainer}>
                <Typography
                  sx={{
                    fontFamily: TextVariables.Body_3_Bold,
                    lineHeight: "1.4",
                  }}
                >
                  {availableAmount}{" "}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: TextVariables.Caption_2,
                    lineHeight: "1.4",
                  }}
                >
                  USD
                </Typography>
              </Box>
            </Box>
            <TitledInput
              name="amount"
              title="Amount"
              placeholder="Please, enter the amount to withdraw"
              value={formValues.amount}
              handleChange={handleChange}
              type="number"
              error={errors.amount}
              helperText={
                errors.amount ? (
                  <Box sx={styles.emptyFieldLabel}>
                    <span
                      className="material-symbols-outlined"
                      style={{ fontSize: Spaces.Size_3, color: "red" }}
                    >
                      error
                    </span>
                    <Typography
                      sx={{
                        fontFamily: TextVariables.Caption_1,
                        color: Colors.Error_700,
                      }}
                    >
                      Please, enter the amount to withdraw
                    </Typography>
                  </Box>
                ) : (
                  ""
                )
              }
            ></TitledInput>
            <TextInputWithCounter
              name="comment"
              title="Comment"
              placeholder="Enter any additional details for the withdrawal"
              value={formValues.comment}
              handleChange={handleChange}
              type="number"
              maxChars={100}
            ></TextInputWithCounter>
            <TitledSelectorGeneral
              name="withdrawalMethod"
              title="Withdrawal Method"
              placeholder={"Please, select a method"}
              value={formValues.withdrawalMethod}
              handleChange={handleSelectMethodChange}
              options={MasterData.withdrawalMethods}
              error={errors.withdrawalMethod}
              helperText={
                errors.withdrawalMethod ? (
                  <Box sx={styles.emptyFieldLabel}>
                    <span
                      className="material-symbols-outlined"
                      style={{ fontSize: Spaces.Size_3, color: "red" }}
                    >
                      error
                    </span>
                    <Typography
                      sx={{
                        fontFamily: TextVariables.Caption_1,
                        color: Colors.Error_700,
                      }}
                    >
                      Please, select a method
                    </Typography>
                  </Box>
                ) : (
                  ""
                )
              }
            ></TitledSelectorGeneral>
            {renderComponent()}
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              sx={{
                borderRadius: "10px",
                borderColor: "#EB0000",
                color: "#fff",
                padding: "10px",
                backgroundColor: "#EB0000",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#EB0000", // Cambia el color al hacer hover
                  opacity: 0.8, // Aplica opacidad al hacer hover
                },
              }}
              onClick={handleSubmit}
            >
              Submit request
            </Button>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}

export default WithdrawalRequestPage;
