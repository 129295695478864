import * as TextVariables from "../../assets/Variables/TextVariables.js";
import Colors from "../../assets/Variables/ColorsVariables.js";
import { Spaces } from "../../assets/Variables/SpaceVariables.js";
import { BorderBottom, Height, Padding } from "@mui/icons-material";
import { borderColor, borderRadius, lineHeight, textTransform } from "@mui/system";
import { alignProperty } from "@mui/material/styles/cssUtils.js";

export const mainBox = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  padding: "20px",
  justifyContent: "space-around"
};

export const secondBox = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: "calc(100vw - 280px)",
  maxWidth: "1310px",
  justifyContent: "space-around"
};

export const mainPaper = {
  display: "flex",
  flexDirection: "column",
  width: "70%",
  maxWidth: "1200px",
  borderRadius: "12px",
  padding: Spaces.Size_5,
  gap: Spaces.Size_7,
};

export const backButtonContainer = {
  display: "flex",
  flexDirection: "column",
  height: "100%",
  alignItems: "flex-start",
  paddingTop: Spaces.Size_2
};

export const linkButton = {
  width: "118px",
  fontFamily: TextVariables.Caption_2,
  color: Colors.Secondary_800,
  textTransform: "none",
  display: "flex",
  padding: `${Spaces.Size_1} ${Spaces.Size_2}`,

  gap: "2px",
  borderRadius: Spaces.Size_2,
};

export const ChipAprovedStyle = {
  fontFamily: "Plus Jakarta Sans",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: "400",
  color: Colors.Success_900,
  borderRadius: "8px",
  textTransform: "none",
  background: Colors.Success_50,
  height: "23px",
  width: "auto",
};
export const ChipRejectedStyle = {
  fontFamily: "Plus Jakarta Sans",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: "400",
  color: Colors.Error_900,
  borderRadius: "8px",
  textTransform: "none",
  background: Colors.Error_50,
  height: "23px",
};

export const ChipPendingStyle = {
  fontFamily: "Plus Jakarta Sans",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: "400",
  color: Colors.Warning_900,
  borderRadius: "8px",
  textTransform: "none",
  background: Colors.Warning_100,
  height: "23px",
};

export const chatMainContainer = {
  height: "100%",
  display: "flex",
  alignItems: "center",
  padding: `${Spaces.Size_3} 0 `,
};

export const chatButtonContainer = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  alignSelf: "stretch",
  textTransform: "none",
  padding: `${Spaces.Size_3} `,
  color: "black",
  "&:hover": {
    backgroundColor: "rgba(236, 241, 247, 1)", // Cambia el color al hacer hover
    opacity: 0.8, // Aplica opacidad al hacer hover
    borderRadius: Spaces.Size_2,
    border: `1px solid ${Colors.Secondary_300}`,
  },
};

export const noReadIcon = {
  height: "6px",
  width: "6px",
  padding: "0",
  backgroundColor: Colors.Info_600,
  borderRadius: "50%",
};

export const chatMainBox = {
  height: "100vh",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

export const chatSecondBox = {
  position: "relative",
  width: "1133px",
  height: "559px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};

export const chatTitle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  padding: `${Spaces.Size_8} ${Spaces.Size_7} ${Spaces.Size_5} ${Spaces.Size_7}`,
  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
};

export const dateTypeBox = {
  display: "flex",
  flexDirection: "row",
  gap: "8px",
};

export const chatContainer = {
  display: "flex",
  flexDirection: "column",
  padding: `${Spaces.Size_1} ${Spaces.Size_8}`,
  justifyContent: "flex-start",
  gap: Spaces.Size_5,
  height: "384px",
  overflowY: "scroll",
  scrollbarWidth: "none", // Firefox
  "&::-webkit-scrollbar": {
    display: "none", // Chrome, Safari y Edge
  },
};

export const inputText = {
  width: "100%",
  marginTop: "22px",
  padding: `0 ${Spaces.Size_8}`,
};

export const inputContent = {
  width: "100%",

  '& .MuiOutlinedInput-root': {
          borderRadius: '12px', // Cambia el borderRadius aquí
          fontFamily: TextVariables.Caption_1,  
        }
};

export const formWithdrwawal = {
  display: "flex",
  flexDirection: "column",
  gap: Spaces.Size_6,
  flexGrow: 1,
};

export const emptyFieldLabel = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: Spaces.Size_1,
  marginTop: "3px"
};

export const submitButton = {
    display: "flex",
    padding: Spaces.Size_4,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: Spaces.Size_2,
    background: Colors.Gray_200,
    textTransform: "none",
    fontFamily: TextVariables.Body_4,
    color: Colors.Gray_700,
    borderColor: "transparent",
    lineHeight: "1.4",
    "&:hover": {
        backgroundColor: Colors.Gray_200, // Cambia el color al hacer hover
        opacity: 0.8, // Aplica opacidad al hacer hover
      },
    
};

export const withdrawlAmountBox = {
  display: "flex",
  flexDirection: "row",
  padding: `${Spaces.Size_5} ${Spaces.Size_4}`,
  justifyContent: "space-between",
  borderRadius: Spaces.Size_2,
  background: Colors.Success_50,
};

export const avalaibleAmountText = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: Spaces.Size_1,
  fontFamily: TextVariables.Caption_1 
};

export const AmountAssetContainer = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: Spaces.Size_1,
  
};

export const modalView = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  padding: Spaces.Size_5,
  height: "auto",
  borderRadius: "15px",
};

export const modalScrollContainer = {
  padding: "0px 10px",
  overflowY: "scroll",
  "::-webkit-scrollbar": {
    width: "8px",
    height: "4px",
  },
  "::-webkit-scrollbar-thumb": {
    backgroundColor: "#888",
    borderRadius: "10px",
    "&:hover": {
      backgroundColor: "#555",
    },
  },
  "::-webkit-scrollbar-track": {
    background: "#f1f1f1",
  },
};

export const modalContainer = {
  display: "flex",
  maxHeight: "60vh",
  flexDirection: "column",
  gap: Spaces.Size_7,
  alignItems: "flex-start",
};

export const paperTreeDetails = {
  display: "flex",
  width: "100%",
  padding: `18px ${Spaces.Size_10}`,
  flexDirection: "column",
  alignItems: "flex-start",
  gap: Spaces.Size_3,
  background: Colors.Secondary_50,
};

export const labelContainerTreeDetails = {
  display: "flex",
  flexDirection: "row",
  gap: Spaces.Size_1,
  padding: `${Spaces.Size_1} 0px`,
};

export const labelTreeDetails = {
  fontFamily: TextVariables.Body_4,
};

export const valueTreeDetails = {
  fontFamily: TextVariables.Body_4_Bold,
};