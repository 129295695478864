import React, { useLayoutEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Typography,
  Paper,
  Box,
  Button,
  Card,
  CardContent,
  CardActions,
  TextField,
  FormControl,
  InputLabel,
  IconButton,
  InputAdornment,
} from "@mui/material";
import * as styles from "./Styles";
import * as TextVariables from "../../../assets/Variables/TextVariables.js";
import { Spaces, mainBox } from "../../../assets/Variables/SpaceVariables.js";
import Colors from "../../../assets/Variables/ColorsVariables.js";
import TitledInput from "../../../components/Inputs/TitledInput.js";
import * as MasterData from "../../../MasterData/MasterData.js";
import TextInputWithCounter from "../../../components/Inputs/TitledBigInput.js";
import TitledSelectorGeneral from "../../../components/Selectors/TitledSelectorGeneral.js";

function CryptowalletForm({ formValues, errors, handleChange }) {
  return (
    <Box sx={styles.formBox}>
      <TitledSelectorGeneral
        name="network"
        title="Network"
        placeholder={"Please, select a network"}
        value={formValues.network}
        handleChange={handleChange}
        options={MasterData.cryptoWalletNetworks}
        error={errors.network}
        helperText={
          errors.network ? (
            <Typography sx={styles.emptyFieldLabel}>
              <span
                className="material-symbols-outlined"
                style={{ fontSize: Spaces.Size_3, color: "red" }}
              >
                error
              </span>
              Please, select a network
            </Typography>
          ) : (
            ""
          )
        }
      ></TitledSelectorGeneral>
      <TitledInput
        name="wallet"
        title="Wallet"
        placeholder="Please, enter a wallet number"
        value={formValues.wallet}
        handleChange={handleChange}
        error={errors.wallet}
        helperText={
          errors.wallet ? (
            <Box sx={styles.emptyFieldLabel}>
              <span
                className="material-symbols-outlined"
                style={{ fontSize: Spaces.Size_3, color: "red" }}
              >
                error
              </span>
              <Typography
                sx={{
                  fontFamily: TextVariables.Caption_1,
                  color: Colors.Error_700,
                }}
              >
                Please, enter a wallet number
              </Typography>
            </Box>
          ) : (
            ""
          )
        }
      ></TitledInput>
    </Box>
  );
}

export default CryptowalletForm;
