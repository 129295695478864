import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Typography,
  Paper,
  Button,
  IconButton,
  Box,
  Modal,
} from "@mui/material";
import * as styles from "../Trees/Styles.js";
import * as TextVariables from "../../assets/Variables/TextVariables.js";
import { Spaces, mainBox } from "../../assets/Variables/SpaceVariables.js";
import Colors from "../../assets/Variables/ColorsVariables.js";
import SearchBoxComponent from "../../components/SearchBox/SearchBoxComponent";
import TableComponent from "../../components/Table/TableComponent.js";
import actionLogo from "../../assets/Icons/open_in_new.svg";
import ApplyButton from "../../components/Buttons/ApplyButton.js";
import copy from "clipboard-copy";
import TreeListRequest from "../../Endpoints/TreeListRequest.js";
import { RemoveDashboardData } from "../../Tools/LocalStorage.js";
import { useCookies } from "../../Tools/CookieChecker.js";
import TradingGroupsRequest from "../../Endpoints/TradingGroupsRequest.js";

const treeDetailsCardDefault = [
  { nameLabel: "Tree name:", value: "" },
  { nameLabel: "Main owner:", value: "" },
  { nameLabel: "MCSO:", value: "" },
  { nameLabel: "Leverage:", value: "" },
];

const registerUrlBase = "https://cabinet.tvmarkets.com/en/register/1/live-account";

function TreesIndexPage(props) {
  const { user, type, token, id } = useCookies();
  const navigate = useNavigate();
  const [t] = useTranslation("global");
  const [treeDetails, setTreeDetails] = useState(treeDetailsCardDefault);
  const [treeViewModal, setTreeViewModal] = useState(false);
  const [rowsBrokerInfo, setRowsBrokerInfo] = useState([]);
  const [filteredRows, setFilteredRows] = useState(rowsBrokerInfo);
  const [rowsCoOwnerSplit, setRowsCoOwnerSplit] = useState([]);
  const [rowsSplitSetting, setRowsSplitSetting] = useState([]);
  const [modalInfo, setModalInfo] = useState([]);
  const [groups, setGroups] = useState([]);
  const [copiedId, setCopiedId] = useState(null);
  const modalInfoRef = useRef(modalInfo);
  const groupsRef = useRef(groups);

  const handleCreateNewTreeButton = () => {
    navigate("/my-trees/create", { state: { UrlToBackPage: "/my-trees" } });
  };

  let handleSelectorChange = (identifier) => {
    let treeDetailInfo = modalInfoRef.current[identifier];
    if (treeDetailInfo) {
      let specificGroupInfo = groupsRef.current.find(
        (group) => group.group === treeDetailInfo.group
      );
      let mcsc_value = "";
      let leverage_value = "";
      if (specificGroupInfo) {
        mcsc_value = specificGroupInfo.mcso;
        leverage_value = "1:" + specificGroupInfo.leverage;
      }
      setTreeDetails([
        { nameLabel: "Tree name:", value: treeDetailInfo.description },
        { nameLabel: "Main owner:", value: treeDetailInfo.broker_owner },
        { nameLabel: "MCSO:", value: mcsc_value },
        { nameLabel: "Leverage:", value: leverage_value },
      ]);

      let actualOwners = treeDetailInfo.brokers_owners.map((owner, index) => {
        let row = {
          id: index,
          sub_owner: owner.broker_name,
          ownership: owner.broker_ownership + "%",
        };
        return row;
      });
      setRowsCoOwnerSplit(actualOwners);

      let actualSplits = treeDetailInfo.splits.map((split, index) => {
        let row = {
          id: index,
          ib: split.ib_name,
          amount: split.split_amount + "%",
        };
        return row;
      });
      setRowsSplitSetting(actualSplits);

      setTreeViewModal(true);
    }
  };

  const RequestTreeListData = async () => {
    try {
      const responseGroups = await TradingGroupsRequest();
      if (responseGroups.data != null) {
        let storedGroups = responseGroups.data.data.groups;
        if (
          storedGroups &&
          Array.isArray(storedGroups) &&
          storedGroups.length > 0
        ) {
          let actualGroups = storedGroups.map((groups, index) => {
            let row = {
              id: index,
              group: groups.group,
              mcso: groups.mcso,
              leverage: groups.leverage,
            };
            return row;
          });
          setGroups(actualGroups);
        }
      }
      const response = await TreeListRequest();
      if (response.data != null) {
        let storedTrees = response.data.data.trees;
        if (
          storedTrees &&
          Array.isArray(storedTrees) &&
          storedTrees.length > 0
        ) {
          let actualModal = storedTrees.map((modal, index) => {
            if(response.data.data.role === "IB"){
              const regex = /\(([^)]+)\)/; 
              const coincidence = modal.description.match(regex);
              modal.description = coincidence ? coincidence[1] : modal.description
            }
            
            let row = {
              id: index,
              tree_id: modal.id,
              group: modal.group,
              description: modal.description,
              broker_owner: modal.broker_owner,
              brokers_owners: modal.brokers_owners,
              splits: modal.splits,
            };
            return row;
          });
          setModalInfo(actualModal);

          let actualRows = storedTrees.map((tree, index) => {
            if (tree.enable === false) tree.link = "pending";
            else {
              tree.link = "Lincito-" + index;
            }

            let row = {
              id: index,
              tree_id: tree.id,
              broker_owner_email: tree.broker_owner_email,
              broker_owner: tree.broker_owner,
              description: tree.description,
              group: tree.group,
              currency: tree.currency,
              server: tree.server,
              splits: tree.splits.length,
              language: tree.language,
              referral_link: tree.link,
              action: "",
            };
            return row;
          });
          setRowsBrokerInfo(actualRows);
          setFilteredRows(actualRows);
        }
      }
    } catch (error) {
      console.log(error);
      RemoveDashboardData();
    }
  };

  useEffect(() => {
    RequestTreeListData();
  }, []);

  const handleFilteredRows = (rowsevent) => {
    setFilteredRows(rowsevent);
  };

  useEffect(() => {
    modalInfoRef.current = modalInfo;
  }, [modalInfo]);

  useEffect(() => {
    groupsRef.current = groups;
  }, [groups]);

  const columnsBrokerInfo = [
    {
      field: "broker_owner",
      headerName: "Tree owner",
      headerClassName: "header_table",
    },
    {
      field: "description",
      headerName: "Tree name",
      headerClassName: "header_table",
    },
    { field: "group", headerName: "Group", headerClassName: "header_table" },
    {
      field: "currency",
      headerName: "Currency",
      headerClassName: "header_table",
    },
    {
      field: "server",
      headerName: "Platform",
      headerClassName: "header_table",
    },
    {
      field: "splits",
      headerName: "Splits",
      headerClassName: "header_table",
    },
    {
      field: "language",
      headerName: "Language",
      headerClassName: "header_table",
    },
    {
      field: "referral_link",
      headerName: "Referral link",
      headerClassName: "header_table",
      sortable: false,
      filterable: false,
      hideable: false,
      resizable: false,
      disableColumnMenu: true,
      hideable: false,
      redimensionable: false,
      renderCell: (params) => {
        const handleCopy = () => {
          if(process.env.REACT_APP_WEB_API_URL == "https://api-crm-portal.tvmarkets.com/api"){
            copy(`${registerUrlBase}?ib=${id}&ib_tree=${params.row.tree_id}&lang=${params.row.language}`);
          }
          else{
            const domain = window.location.origin;
            copy(`${domain}/register?ib=${id}&ib_tree=${params.row.tree_id}&lang=${params.row.language}`);
          }
          setTimeout(() => {
            setCopiedId(params.id);
          }, 0);
          setTimeout(() => {
            setCopiedId(null);
          }, 2000);
        };
        if (params.row.referral_link === "pending") {
          return (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "100%",
                width: "100%",
              }}
            >
              <Typography sx={styles.pendingButton}>
                <span
                  className="material-symbols-outlined"
                  style={{ fontSize: "12px", marginRight: "2px" }}
                >
                  content_copy
                </span>
                Pending approval
              </Typography>
            </Box>
          );
        } else {
          return (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "100%",
                width: "100%",
              }}
            >
              <Button
                sx={{
                  ...styles.linkButton, // Incorporar los estilos de styles.linkButton
                  backgroundColor:
                    copiedId === params.id
                      ? Colors.Secondary_800
                      : "rgba(164, 176, 189, 0.25)",
                  color: copiedId === params.id ? "white" : "black",
                  "&:hover": {
                    backgroundColor:
                      copiedId === params.id
                        ? Colors.Secondary_800
                        : Colors.Secondary_300,
                    color: "white", // Asegúrate de que el texto sea legible en el hover
                  },
                  transition: "background-color 0.6s ease, color 0.6s ease", // Transiciones suaves
                }}
                onClick={handleCopy}
              >
                <span
                  className="material-symbols-outlined"
                  style={{ fontSize: "12px", marginRight: "2px" }}
                >
                  content_copy
                </span>
                {copiedId === params.id ? "Copied" : "Copy referral link"}
              </Button>
            </Box>
          );
        }
      },
    },
    {
      field: "action",
      headerName: "",
      headerClassName: "header_table",
      sortable: false,
      filterable: false,
      hideable: false,
      resizable: false,
      disableColumnMenu: true,
      hideable: false,
      redimensionable: false,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <IconButton onClick={(e) => handleSelectorChange(params.row.id)}>
              <img
                src={actionLogo}
                alt="Logo"
                style={{ width: 16, height: 16 }}
              />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const columnsSplitSetting = [
    {
      field: "ib",
      headerName: "IB",
      headerClassName: "header_table",
    },
    {
      field: "amount",
      headerName: "Amount %",
      headerClassName: "header_table",
    },
  ];

  const columnsSplitCoOwners = [
    {
      field: "sub_owner",
      headerName: "Owner",
      headerClassName: "header_table",
    },
    {
      field: "ownership",
      headerName: "ownership (%)",
      headerClassName: "header_table",
    },
  ];
  return (
    <Box sx={mainBox}>
      <Paper elevation={2} sx={styles.mainPaper}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
          }}
        >
          <Typography sx={styles.TitleStyle}>Trees</Typography>
          <Typography sx={TextVariables.Body_4}>
            Below you’ll find a list of the payout structures you’re linked to.
            Check out their details.
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <SearchBoxComponent
            rows={rowsBrokerInfo}
            onFilteredRowsChange={handleFilteredRows}
            placeholder="Search by tree owner or group"
            filterFields={["broker_owner", "group"]}
          ></SearchBoxComponent>
          <ApplyButton HandleOnClick={handleCreateNewTreeButton} disable={true}>
            Create new tree
          </ApplyButton>
        </Box>
        <div style={{ height: 600, width: "100%" }}>
          <TableComponent rows={filteredRows} columns={columnsBrokerInfo} />
        </div>
        <Modal
          open={treeViewModal}
          onClose={() => {
            setTreeViewModal(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styles.modalView}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: Spaces.Size_7,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    padding: "0px 0 0 10px",
                    fontFamily: TextVariables.Subtitle_4,
                  }}
                >
                  Tree details
                </Typography>
                <Button
                  onClick={() => {
                    setTreeViewModal(false);
                  }}
                  style={{
                    backgroundColor: "transparent",
                    color: "#000",
                    fontSize: 20,
                    fontWeight: "bold",
                    padding: "0px",
                    minWidth: "0px",
                  }}
                >
                  <span className="material-symbols-outlined" fontSize="24px">
                    close
                  </span>
                </Button>
              </Box>

              <Box sx={styles.modalScrollContainer}>
                <Box sx={styles.modalContainer}>
                  <Paper sx={styles.paperTreeDetails}>
                    {treeDetails.map((item, index) => (
                      <Box sx={styles.labelContainerTreeDetails} key={index}>
                        <Typography sx={styles.labelTreeDetails}>
                          {item.nameLabel}
                        </Typography>
                        <Typography sx={styles.valueTreeDetails}>
                          {item.value}
                        </Typography>
                      </Box>
                    ))}
                  </Paper>
                  <Box sx={{ height: "190px", width: "100%" }}>
                    <TableComponent
                      rows={rowsCoOwnerSplit}
                      columns={columnsSplitCoOwners}
                    />
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    <TableComponent
                      rows={rowsSplitSetting}
                      columns={columnsSplitSetting}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>
      </Paper>
    </Box>
  );
}

export default TreesIndexPage;
