import React, { useState, memo } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Typography, Paper, Box, Button, TextField, OutlinedInput, InputAdornment, InputLabel, List, ListItem, ListItemText, InputBase, Grid } from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { WidthFull } from '@mui/icons-material';
import SteeperComponent from "../../../components/Steeper/SteeperComponent";
import { styled } from '@mui/system';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const steps = ['Tree set-up', 'Group selection', 'Splits'];
const users = [
  { name: 'Hiroshige Kobayashi', email: 'Hiroshige@Kobayashi.com' },
  { name: 'Jose Benitez', email: 'Jose@Benitez.com' },
  { name: 'Miguel Espinosa', email: 'Miguel@Espinosa.com' },
  { name: 'Carlos Ladino', email: 'Carlos@Ladino.com' },
  { name: 'Marcela Aguillón', email: 'Marcela@Aguillon.com' },
  { name: 'Usuario test1', email: 'Usuario@test1.com' },
  { name: 'Usuario test2', email: 'Usuario@test2.com' },
  { name: 'Usuario test3', email: 'Usuario@test3.com' },
  { name: 'Usuario test4', email: 'Usuario@test4.com' },
  { name: 'Usuario test5', email: 'Usuario@test5.com' },
];
  const CustomScrollbarBox = styled(Box)({
    height: 400, 
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#555',
    },
  });
  
function TreeCreateStepOneOptionalPage() {
  const navigate = useNavigate();
  const [t] = useTranslation("global");
  const [activeStep, setActiveStep] = useState(1);
  const [mainOwner, setMainOwner] = useState('María Dias');
  const [ownership, setOwnership] = useState("100");
  const [textContinueButton, setTextContinueButton] = useState('Continue without sub-owners');
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredUsers, setFilteredUsers] = useState(users);
  const [addsubOwnerState, setAddsubOwnerState] = useState(false);
  const [addedSubs, setAddedSubs] = useState([]);
  const [continueFlag, setContinueFlag] = useState(true);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    const filtered = users.filter(user =>
      user.name.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setFilteredUsers(filtered);
  };
  const handleNextStep = () => {
    navigate('/my-trees/create/step-two');
  }
  const handleClickToBackPage = () => {
    navigate('/my-trees/create/step-one');
  };
  const handleClickToshowSubOwners = () => {
    setAddsubOwnerState(true);
    setTextContinueButton("Continue")
  };
  const handlePercentageChange = (email, newPercentage) => {
    setAddedSubs(prevAddedSubs => {
      const updatedSubs = prevAddedSubs.map(sub =>
        sub.email === email ? { ...sub, percentage: newPercentage } : sub
      );

      let subsSummation = 100;
      let somethingIsNull = false;
      updatedSubs.forEach(sub => {
        subsSummation -= sub.percentage;
        if(!sub.percentage || sub.percentage <= 0){
          somethingIsNull = true;
        }
      });

      if(subsSummation < 0)
        return prevAddedSubs;
  
      setContinueFlag(!somethingIsNull);
      setOwnership(parseFloat(subsSummation.toFixed(2)));
      return updatedSubs;
    });
  };
  const handleUseraddRemove = (user) => {
    const existingUser = addedSubs.find(us => us.email === user.email);
    if (!existingUser) {
      setAddedSubs([...addedSubs, { name: user.name, email:user.email, percentage: 0 }]);
      setContinueFlag(false);
    } else {
      setAddedSubs(prevAddedSubs => {
        const updatedSubs = prevAddedSubs.filter(us => us.email !== user.email)
        
        let subsSummation = 100;
        let somethingIsNull = false;
        updatedSubs.forEach(sub => {
          subsSummation -= sub.percentage;
          if(!sub.percentage || sub.percentage <= 0){
            somethingIsNull = true;
          }
        });

        setContinueFlag(!somethingIsNull);
        setOwnership(parseFloat(subsSummation.toFixed(2)));

        return updatedSubs;
      });
    }    
  };

  return (
    <Paper elevation={0} sx={{ 
      padding: 4, 
      maxWidth: 'calc(100vw - 280px)',
      minHeight: 'calc(100vh - 100px)',
      marginTop: '0px',
      marginLeft: '10px',
      borderRadius: '12px',
      backgroundColor: "#F9F9F9"
    }}>
      <Box sx={{ width: "100%", display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px' }}>
        <Button onClick={handleClickToBackPage} startIcon={<ArrowBackIosIcon style={{ fontSize: 10 }} />} sx={{ textTransform: 'none', color: 'black', fontWeight:'bold'}}>
          Go back
        </Button>
      </Box>
      <Box>
        <Box sx={{ width: '70%', margin:"0 auto"}}>
          <SteeperComponent
            stepList={steps}
            stepNumber={activeStep}
          />
        </Box>
      </Box>
      <Box style={{ display: 'flex', gap: '16px', marginTop: '20px', WidthFull }}></Box>
      <Paper elevation={2} sx={{ 
        padding: 4, 
        maxWidth: '70%',
        minHeight: '300px',
        margin: '0 auto',
        borderRadius: '12px',
        }}>
            <Typography variant="h6" sx={{fontWeight:"bold"}}>Sub-owner addition (optional)</Typography>    
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
                marginTop: '16px',
                border: '1px solid #BBB',
                padding:'10px 10px',
                borderRadius:'15px'
                
            }}>
                <Box sx={{width:'100%', marginRight:'10px'}}>
                    <InputLabel>Main Owner</InputLabel>
                    <TextField
                        variant="outlined" 
                        value={mainOwner} 
                        disabled={true}
                        sx={{width:'100%', fontWeight:'bold'}}
                    />
                </Box>
                <Box>
                    <InputLabel>Ownership %</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-weight"
                        endAdornment={<InputAdornment position="end">%</InputAdornment>}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                        'aria-label': 'weight',
                        }}
                        value={ownership}
                        disabled={true}
                        sx={{fontWeight:'bold'}}
                    />
                </Box>
            </Box>
            <Box
                sx={{
                    display:'flex',
                    justifyContent:'center',
                    marginTop:'15px'
                }}
            >
                <Button
                    variant="outlined"
                    onClick={handleClickToshowSubOwners}
                    sx={{
                        borderColor:'#AAA',
                        borderRadius:'10px',
                        fontWeight:'bold',
                        color:'#000',
                        display: addsubOwnerState ? 'none' : 'block'
                    }}
                >
                    + Add a sub-owner
                </Button>
            </Box>
            <Box sx={{
                display: addsubOwnerState ? 'flex' : 'none',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
                marginTop: '16px',
            }}>
                <Box sx={{ width: '100%', marginRight: '10px', border: '1px solid #BBB', borderRadius: '15px' }}>
                    <InputLabel sx={{ padding: '10px 10px', fontWeight:'bold' }}>Add sub-owners</InputLabel>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '5px', marginBottom:'5px' }}>
                        <Paper elevation={1} sx={{ width: '100%', display: 'flex', alignItems: 'center', boxShadow: 'none', border: '1px solid #BBB', borderRadius: '15px' }}>
                            <IconButton sx={{ p: '10px' }} aria-label="Search by name">
                                <SearchIcon />
                            </IconButton>
                            <InputBase
                                placeholder="Search by name"
                                sx={{ flex: '1', padding: '0px', border: 'none', outline: 'none' }} // Ajustar el padding y eliminar border y outline
                                value={searchTerm}
                                onChange={handleSearchChange}
                            />
                        </Paper>
                    </Box>
                    <Box sx={{
                        width: '95%',
                        marginTop: '16px',
                        border:'1px solid black',
                        borderRadius:'15px',
                        margin:'0 auto',
                        borderColor:'#AAA',
                        marginBottom:'10px'
                    }}>
                        <CustomScrollbarBox sx={{ height: 270, overflow: 'auto' }}>
                            <List>
                                {filteredUsers.map((user, index) => (
                                    <ListItem key={index} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '45px', borderBottom: '1px solid #ddd' }}>
                                        <ListItemText primary={user.name}  sx={{ lineHeight: '1', flex: '1' }} />
                                        <Button
                                            variant="contained"
                                            key={user.email}
                                            onClick={() => handleUseraddRemove(user)}
                                            sx={{
                                                background: !addedSubs.some(addedUser => addedUser.email === user.email) ? '#E8EFFF' : '#D6D6D6',
                                                color: !addedSubs.some(addedUser => addedUser.email === user.email) ? '#002266' : '#525252',
                                                fontWeight: 'bold',
                                                minWidth: '45px',
                                                minHeight: '30px',
                                                padding: '4px 8px',
                                                textTransform: 'none',
                                                boxShadow: 'none',
                                                borderRadius: '10px'
                                            }}
                                        >
                                            {!addedSubs.some(addedUser => addedUser.email === user.email) ? 'Add' : <DeleteOutlineIcon/>}
                                        </Button>
                                    </ListItem>
                                ))}
                            </List>
                        </CustomScrollbarBox>
                        </Box>
                </Box>
                <Box sx={{ width: '100%', marginRight: '10px', border: '1px solid #BBB', borderRadius: '15px' }}>
                    <InputLabel sx={{ padding: '10px 10px', fontWeight:'bold' }}>Define ownership allocation</InputLabel>
                    {(addedSubs.length <= 0) ?
                      <Box sx={{
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center',
                        height: '80%'
                      }}>
                          <InputLabel sx={{ padding: '10px 10px', textAlign: 'center' }}>Your tree dowsn't have any sub-owners yet.</InputLabel>
                      </Box>
                    :
                    <Box>
                      {addedSubs.map((sub, index) => (
                        <Box key={index} sx={{
                          display:'flex',
                          justifyContent:'space-between',
                          alignItems: 'center',
                          margin:'5px',
                          width:'100%',
                          marginTop:'10px',
                        }}
                        >
                          <TextField
                            variant="outlined"
                            value={sub.name}
                            disabled={true}
                            sx={{
                              '& .MuiInputBase-input': {
                                height: '10px', // Ajusta la altura según lo necesites
                              },
                              '& .MuiOutlinedInput-root': {
                                borderRadius: '12px', // Ejemplo adicional: ajusta el radio de borde
                              },
                              marginRight:'5px',
                              flex: 1
                            }}
                          />
                          <TextField
                            variant="outlined"
                            value={sub.percentage}
                            onChange={(e) => {
                              const regex = /^(?:\d{1,2}(?:\.\d{0,2})?)?$/;
                              if (regex.test(e.target.value) && Number(e.target.value) <= 99) {
                                handlePercentageChange(sub.email, e.target.value)}
                              }
                            }
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">%</InputAdornment>
                              ),
                            }}
                            sx={{
                              '& .MuiInputBase-input': {
                                height: '10px', // Ajusta la altura según lo necesites
                              },
                              '& .MuiOutlinedInput-root': {
                                borderRadius: '12px', // Ejemplo adicional: ajusta el radio de borde
                              },
                              maxWidth:'100px'
                            }}
                          />
                          <IconButton 
                            onClick={ () => handleUseraddRemove(sub) }
                          >
                            <DeleteOutlineIcon />
                          </IconButton>
                        </Box>
                        ))}
                    </Box>
                    }
                </Box>
            </Box>
            <Box>
              <Typography sx={{
                color:'#EB0000',
                fontWeight:'bold',
                display: 'flex',
                justifyContent:'end',
                fontSize:'14px',
                marginTop:'10px'
              }}
              visibility={continueFlag ? 'hidden' : 'display'}
              >
                Please, enter the ownership percentage for the sub-owner(s)
              </Typography>
            </Box>
            <Box sx={{ marginTop: '10px', display: 'flex', justifyContent: 'flex-end' }}> 
                <Button 
                    variant="outlined" 
                    onClick={handleClickToBackPage}
                    sx={{
                        marginTop: '16px',
                        color: '#000',
                        borderRadius:'7px',
                        marginRight:'10px',
                        borderColor:'#AAA'
                      }}
                    >
                    Back
                </Button>
                <Button 
                    variant="contained" 
                    onClick={handleNextStep}
                    disabled={!continueFlag}
                    sx={{
                        marginTop: '16px',
                        backgroundColor: '#EB0000',
                        color: '#FFF',
                        borderRadius:'7px',
                        '&:disabled': {
                            color: '#000',
                        },
                      }}
                    >
                    {textContinueButton}
                </Button>
            </Box>

    </Paper>
      <Box sx={{ marginBottom: "100px" }}></Box>
    </Paper>
  );
}

export default TreeCreateStepOneOptionalPage;
