import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Typography, Paper, Box, Button } from "@mui/material";
import TableComponent from "../../components/Table/TableComponent.js";
import TableToolTip from "../../components/ToolTips/TableToolTip.js";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import * as styles from "../Wallet/Styles.js";
import IconComponent from "../../components/Icons/IconComponent.js";
import TitledSelector from "../../components/Selectors/TitledSelector.js";
import DateSelector from "../../components/Selectors/DateSelector.js";
import ApplyButton from "../../components/Buttons/ApplyButton.js";
import { mainBox} from "../../assets/Variables/SpaceVariables.js";
import { getLocalTimeZoneOffsetInSeconds, getTimestamps } from "../../Tools/DateTimeConvertions";
import { RemoveDashboardData } from "../../Tools/LocalStorage.js";
import WalletRequest from "../../Endpoints/WalletRequest.js";
import WalletTransactionsRequest from "../../Endpoints/WalletTransactionsRequest.js";
import LoadingSpinner from "../../components/Loaders/SpinLoader.js";
import { useCookies } from "../../Tools/CookieChecker.js";

const dateOptions = [
  { label: "Today", value: "Today" },
  { label: "This week", value: "This week" },
  { label: "This month", value: "This month" },
  { label: "This year", value: "This year" },
  { label: "Custom", value: "Custom" },
];

function ClientIndexPage() {
  const { user, name, type, token } = useCookies();
  const [dateSelect, setDateSelect] = useState("Today");
  const [showDataPickers, setShowDataPickers] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [totalAmount, SetTotalAmount] = useState(0);
  const [t] = useTranslation("global");
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState(rows);
  const [wallet, setWallet] = useState({});
  const [applyDisable, setApplyDisable] = useState(false);
  const [loading, setLoading] = useState(false);

  const RequestWalletData = async () => {
    try{
      const response = await WalletRequest();
      if (response.data != null) {
        setWallet(response.data.data.wallet)
      }
      else{
        setWallet({id: "", currency:"", balance:""})
      }
    }
    catch(error){
      setWallet({id: "", currency:"", balance:""})
      console.log(error);
    }
  }

  useEffect(() => {
    setRows([]);
    setFilteredRows([]);

    RequestWalletData();
    let datetime = getTimestamps("Today");
    RequestTransactionWalletData({
      from: String(datetime.from),
      to: String(datetime.to),
    });
  }, []);

  useEffect(() => {
    let accumulator = 0;
    filteredRows.forEach((transaction) => {
      const amount = parseFloat(transaction.amount);
      if (transaction.type.toLowerCase() === "withdraw") {
        accumulator -= amount;
      } 
      else if (transaction.type.toLowerCase() === "deposit" || transaction.type.toLowerCase() === "payout") {
        accumulator += amount;
      }

      return accumulator;
    });
    
    SetTotalAmount(parseFloat(accumulator).toFixed(2));
  }, [filteredRows])

  const RequestTransactionWalletData = async (params) => {
    try {
      setApplyDisable(true);
      setLoading(true);
      const response = await WalletTransactionsRequest(params);
      if (response.data != null) {
        const storedTransactions = response.data.data.transactions;
        if (
          storedTransactions &&
          Array.isArray(storedTransactions) &&
          storedTransactions.length > 0
        ) {
          let actualRows = storedTransactions.map((transaction, index) => {
            if (transaction.transaction_type !== null) {
              transaction.transaction_type =
                transaction.transaction_type.charAt(0).toUpperCase() +
                transaction.transaction_type.slice(1).toLowerCase();
            }

            let row = {
              id: index + 1,
              date: transaction.date,
              type: transaction.transaction_type,
              amount: (transaction.amount || 0.0).toFixed(2),
              comment: transaction.comment,
              ticket: transaction.trade_ticket,
              login: transaction.trade_login,
              platform: transaction.trading_server,
            };
            return row;
          });
          setRows(actualRows);
          setFilteredRows(actualRows);         
        }
      }
    } catch (error) {
      console.log(error);
      RemoveDashboardData();
    }
    setLoading(false);
    setApplyDisable(false);
  };

  const handleDownload = (dataArray) => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(dataArray);
    XLSX.utils.book_append_sheet(wb, ws, "ClientData");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const fecha = new Date();
    const year = fecha.getFullYear();
    const month = String(fecha.getMonth() + 1).padStart(2, '0');
    const day = String(fecha.getDate()).padStart(2, '0'); 
    saveAs(blob, `${name}_wallet_${year}_${month}_${day}.xlsx`);
  };

  const handleDateSelector = (event) => {
    setDateSelect(event.target.value);
    if (event.target.value === "Custom") {
      setShowDataPickers(true);
    } else {
      setShowDataPickers(false);
    }
  };

  const handleFromChange = (date) => {
    setFromDate(date);
  };

  const handleToChange = (date) => {
    setToDate(date);
  };

  const handleOnClick = () => {
    if (dateSelect != "Custom") {
      setRows([]);
      setFilteredRows([]);
      let datetime = getTimestamps(dateSelect);
      RequestTransactionWalletData({
        from: String(datetime.from),
        to: String(datetime.to),
      });
    } else {
      if (fromDate != null || toDate != null) {
        setRows([]);
        setFilteredRows([]);
        RequestTransactionWalletData({
          from: String(fromDate / 1000 + getLocalTimeZoneOffsetInSeconds()),
          to: String(toDate / 1000 + getLocalTimeZoneOffsetInSeconds() + 86400),
        });
      }
    }
  };

  const columns = [
    {
      field: "id",
      headerClassName: "header_table",
      renderHeader: () => (
        <TableToolTip title="The date of creation of the account">
          <Box>{"ID "}</Box>
        </TableToolTip>
      ),
    },
    {
      field: "date",
      headerClassName: "header_table",
      renderHeader: () => <>{"Date"}</>,
    },
    {
      field: "type",
      headerClassName: "header_table",
      renderHeader: () => <>{"Type"}</>,
    },
    {
      field: "amount",
      headerClassName: "header_table",
      renderHeader: () => <>{"Amount"}</>,
    },
    {
      field: "comment",
      headerClassName: "header_table",
      renderHeader: () => <>{"Comment"}</>,
    },
    {
      field: "ticket",
      headerClassName: "header_table",
      renderHeader: () => (
        <TableToolTip title="The payout structure established for this account.">
          <Box>
            {"Ticket "}
            <IconComponent icon="help" size="12px" />
          </Box>
        </TableToolTip>
      ),
    },
    {
      field: "login",
      headerClassName: "header_table",
      renderHeader: () => <>{"Login"}</>,
    },
    {
      field: "platform",
      headerClassName: "header_table",
      renderHeader: () => <>{"Platform"}</>,
    },
  ];

  return (
    <Box sx={mainBox}>
      {loading ? <LoadingSpinner /> : <></>}
      <Paper elevation={2} sx={styles.MainPaper}>
        <Box sx={styles.TitleBox}>
          <Typography
            variant="h5"
            component="h2"
            gutterBottom
            sx={styles.Title}
          >
            Wallet
          </Typography>
          <Button
            variant="outlined"
            sx={styles.ExportButton}
            onClick={() => handleDownload(filteredRows)}
            disabled={filteredRows.length == 0}
          >
            <IconComponent icon="download" size="12px" />
            &nbsp;Export
          </Button>
        </Box>
        <Box sx={styles.WalletInfoContainer}>
          <Typography sx={styles.WalletLabel1}>Balance:</Typography>
          <Typography sx={styles.WalletLabel2}>
            ${(wallet.balance || 0.0).toFixed(2)} {wallet.currency || "USD"}
          </Typography>
        </Box>
        <Box>
          <Box sx={{display: "flex", flexDirection: "row", alignItems: "flex-end"}}>
            <TitledSelector
              title="Period of time"
              value={dateSelect}
              handleChange={handleDateSelector}
              options={dateOptions}
            />
            {showDataPickers && (
              <>
                <DateSelector
                  title="From"
                  value={fromDate}
                  handleChange={handleFromChange}
                />
                <DateSelector
                  title="To"
                  value={toDate}
                  handleChange={handleToChange}
                />
              </>
            )}
            <ApplyButton HandleOnClick={handleOnClick} disable={applyDisable}>Apply</ApplyButton>
          </Box>
        </Box>
        <Box style={{ height: 600, width: "100%" }}>
          <TableComponent
            rows={filteredRows}
            TotalAmount={totalAmount !== 0 ? totalAmount : null}
            columns={columns}
          />
        </Box>
      </Paper>
    </Box>
  );
}

export default ClientIndexPage;
