import AxiosInstance from "./Axios";

const RegisterRequest = async(registerForm) => {
  try{
    const res= await AxiosInstance.post('/register', registerForm, {
      headers: {
        'x-auth-token-crm': `6ee898b2-ca59-494a-8a81-cfc00a126ef1`
      }
    });
    return res;
  }catch (error) {
    console.error('error in request:', error);
    throw error;
  }          
};

export default RegisterRequest;