import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  Typography,
  TextField,
} from "@mui/material";
import * as TextVariables from "../../assets/Variables/TextVariables.js";
import Colors from "../../assets/Variables/ColorsVariables.js";
import { Spaces } from "../../assets/Variables/SpaceVariables.js";

const TitledInput = ({
  name,
  title,
  value,
  handleChange,
  type = "text",
  placeholder,
  error,
  helperText,
  disabled = false,
}) => {
  const inputStyle = {
    fontFamily: TextVariables.Caption_1,
    border: error
      ? `1px solid ${Colors.Error_700}`
      : disabled
      ? `1px solid ${Colors.Gray_200}`
      : `1px solid ${Colors.Secondary_700}`,
    borderRadius: Spaces.Size_2,
    width: "100%",
    padding: "0px",
    "& .MuiInputBase-input": {
      fontFamily: TextVariables.Caption_1,
      padding: Spaces.Size_4,
    },
    "& .MuiOutlinedInput-notchedOutline": {},
  };
  return (
    <div>
      <Typography
        sx={{
          marginRight: "10px",
          marginBottom: "5px",
          fontFamily: TextVariables.Body_4,
          color: disabled ? Colors.Gray_500 : Colors.Secondary_700,
        }}
      >
        {title}
      </Typography>
      <TextField
        name={name}
        value={value}
        onChange={handleChange}
        type={type}
        required
        disabled={disabled}
        placeholder={placeholder}
        sx={inputStyle}
        InputProps={{
          sx: {
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none", // Elimina el borde
            },
          },
        }}
      />
      {error && helperText}
    </div>
  );
};

TitledInput.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default TitledInput;
