import AxiosInstance from "./Axios";
import Cookies from "universal-cookie";

const TreeListRequest = async() => {
  try {
    const cookies = new Cookies();
    const token = cookies.get('token');
        
    const res = await AxiosInstance.get('/tree-list', {
      headers: {
        'Authorization': `Bearer ${token}`
        }
    });

    return res;
  } catch (error) {
    console.error('error in request:', error);
    throw error;
  }     
};

export default TreeListRequest;