import { Typography, Card, CardContent, Box, Avatar } from "@mui/material";
import * as TextVariables from "../../assets/Variables/TextVariables.js";
import { Spaces } from "../../assets/Variables/SpaceVariables.js";
import Colors from "../../assets/Variables/ColorsVariables.js";
import * as masterData from "../../MasterData/MasterData.js";

function MoneyCard({ asset, title, value }) {
  const flag = masterData.flags.find((assetFlag) => assetFlag.value === asset);
  return (
    <Card
      sx={{
        borderRadius: Spaces.Size_5,
        background: "rgba(236, 241, 247, 0.50)",
        border: `1px solid ${Colors.Secondary_50}`,
        maxHeight: "200px",
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          padding: `${Spaces.Size_11} ${Spaces.Size_8} !important`,
          gap: Spaces.Size_6,
        }}
      >
        <Box>
          <Typography sx={TextVariables.Body_4}>{title}</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: Spaces.Size_3,
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: Spaces.Size_1,
            }}
          >
            <Typography sx={{ fontFamily: TextVariables.Subtitle_2 }}>
              ${value}
            </Typography>
            <Typography
              sx={{
                fontFamily: TextVariables.Body_4,
                color: Colors.Secondary_700,
                padding: "15px 0 0 0",
              }}
            >
              {asset}
            </Typography>
          </Box>

          <Box sx={{ width: "35px", borderRadius: "35px" }}>
            <Avatar
              alt="asset flag"
              src={flag.flag}
              sx={{ width: 35, height: 35 }}
            ></Avatar>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default MoneyCard;
