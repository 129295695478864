import AxiosInstance from "./Axios";

const LoginRequest = async(credentials) => {
  try{
    const res= await AxiosInstance.post('/login', credentials);
    return res; 
  }
  catch (error) {
    console.error('error in request:', error);
    throw error;
  }      
};

export default LoginRequest;