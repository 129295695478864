import React, { useState } from "react";
import { Typography, Box, Button } from "@mui/material";
import * as styles from "./Styles.js";
import * as TextVariables from "../../../assets/Variables/TextVariables.js";
import { Spaces } from "../../../assets/Variables/SpaceVariables.js";
import Colors from "../../../assets/Variables/ColorsVariables.js";
import TitledInput from "../../../components/Inputs/TitledInput.js";
import * as MasterData from "../../../MasterData/MasterData.js";
import TextInputWithCounter from "../../../components/Inputs/TitledBigInput.js";
import TitledSelectorGeneral from "../../../components/Selectors/TitledSelectorGeneral.js";

function UnionPayForm({ formValues, errors, handleChange }) {
  return (
    <Box sx={styles.formBox}>
      <Typography sx={{ fontFamily: TextVariables.Body_3_Bold }}>
      UnionPay account details
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "row", gap: Spaces.Size_7 }}>
        <Box flex={1}>
          <TitledInput
            name="accountNumber"
            title="Account number (IBAN if applicable)"
            placeholder="Please, enter your account number/IBAN number"
            value={formValues.accountNumber}
            handleChange={handleChange}
            error={errors.accountNumber}
            helperText={
              errors.accountNumber ? (
                <Box sx={styles.emptyFieldLabel}>
                  <span
                    className="material-symbols-outlined"
                    style={{ fontSize: Spaces.Size_3, color: "red" }}
                  >
                    error
                  </span>
                  <Typography
                    sx={{
                      fontFamily: TextVariables.Caption_1,
                      color: Colors.Error_700,
                    }}
                  >
                    Please, enter your account number/IBAN number
                  </Typography>
                </Box>
              ) : (
                ""
              )
            }
          ></TitledInput>
        </Box>

        <Box flex={1}>
          <TitledSelectorGeneral
            name="beneficiaryBankName"
            title="Beneficiary bank name"
            placeholder="Select a bank"
            value={formValues.beneficiaryBankName}
            handleChange={handleChange}
            error={errors.beneficiaryBankName}
            options={MasterData.beneficiaryBanks}
            helperText={
              errors.beneficiaryBankName ? (
                <Box sx={styles.emptyFieldLabel}>
                  <span
                    className="material-symbols-outlined"
                    style={{ fontSize: Spaces.Size_3, color: "red" }}
                  >
                    error
                  </span>
                  <Typography
                    sx={{
                      fontFamily: TextVariables.Caption_1,
                      color: Colors.Error_700,
                    }}
                  >
                    Please, enter your beneficiary bank name
                  </Typography>
                </Box>
              ) : (
                ""
              )
            }
          ></TitledSelectorGeneral>
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", gap: Spaces.Size_7 }}>
        <Box flex={1}>
          <TitledInput
            name="branchName"
            title="Branch name"
            placeholder="Please, enter your branch name"
            value={formValues.branchName}
            handleChange={handleChange}
            error={errors.branchName}
            helperText={
              errors.branchName ? (
                <Box sx={styles.emptyFieldLabel}>
                  <span
                    className="material-symbols-outlined"
                    style={{ fontSize: Spaces.Size_3, color: "red" }}
                  >
                    error
                  </span>
                  <Typography
                    sx={{
                      fontFamily: TextVariables.Caption_1,
                      color: Colors.Error_700,
                    }}
                  >
                    Please, enter your branch name
                  </Typography>
                </Box>
              ) : (
                ""
              )
            }
          ></TitledInput>
        </Box>

        <Box flex={1}>
          <TitledInput
            name="province"
            title="Province"
            placeholder="Please, enter your province"
            value={formValues.province}
            handleChange={handleChange}
            error={errors.province}
            helperText={
              errors.province ? (
                <Box sx={styles.emptyFieldLabel}>
                  <span
                    className="material-symbols-outlined"
                    style={{ fontSize: Spaces.Size_3, color: "red" }}
                  >
                    error
                  </span>
                  <Typography
                    sx={{
                      fontFamily: TextVariables.Caption_1,
                      color: Colors.Error_700,
                    }}
                  >
                    Please, enter your province
                  </Typography>
                </Box>
              ) : (
                ""
              )
            }
          ></TitledInput>
        </Box>
      </Box>
      <TitledInput
            name="city"
            title="City"
            placeholder="Please, enter your city"
            value={formValues.city}
            handleChange={handleChange}
            error={errors.city}
            helperText={
              errors.city ? (
                <Box sx={styles.emptyFieldLabel}>
                  <span
                    className="material-symbols-outlined"
                    style={{ fontSize: Spaces.Size_3, color: "red" }}
                  >
                    error
                  </span>
                  <Typography
                    sx={{
                      fontFamily: TextVariables.Caption_1,
                      color: Colors.Error_700,
                    }}
                  >
                    Please, enter your city
                  </Typography>
                </Box>
              ) : (
                ""
              )
            }
          ></TitledInput>
    </Box>
  );
}

export default UnionPayForm;
