import * as TextVariables from "../../assets/Variables/TextVariables.js";
import Colors from "../../assets/Variables/ColorsVariables.js";
import {Spaces} from "../../assets/Variables/SpaceVariables.js";
import { BorderBottom } from "@mui/icons-material";
import { alignProperty } from "@mui/material/styles/cssUtils.js";

export const sideBar = {
  width: Spaces.Size_25,
  backgroundColor: "#15202A",
  color: "#fff",
  display: "flex",
  flexDirection: "column",
  paddingTop: Spaces.Size_5,
  boxShadow: "2px 0 5px rgba(0, 0, 0, 0.4)",
  height: "calc(100vh - 50px)",
  position: "fixed",
  top: "68px",
  left: "0",
};

export const nav = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

export const tab = {
  color: "rgba(255, 255, 255, 1)",
  width: "100%",
  textDecoration: "none",
  padding: `${Spaces.Size_4} ${Spaces.Size_7}`,
  width: "100%",
  textAlign: "left",
  display: "flex",
  alignItems: "center",
  transition: "background 0.3s ease",
  fontFamily: TextVariables.Body_4,
  gap: Spaces.Size_2,
  "&:hover": {
    backgroundColor: "#ecf1f771",
    fontFamily: TextVariables.Body_4,
    color: "#15202A",
  },
};

export const navbar = {
  zIndex: "100",
  top: "0",
  left: "0",
  width: "100%",
  display: "flex",
  flexDirection: "row",
  padding: `${Spaces.Size_2} ${Spaces.Size_7}`,
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: "rgba(255, 255, 255)",
  boxShadow: "0px 18px 32px 0px rgba(21, 32, 42, 0.08)",
  backdropFilter: "blur(10px)",
};

export const contactIdiomContainer = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: Spaces.Size_4,
};

export const selectStyle = {
  fontWeight: "400",
  fontSize: "12px",
  border: "none",
  borderRadius: "8px",
  fontFamily: "Plus Jakarta Sans",
  height: "33px",
  width: "172px",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none", // Elimina el borde
    },
    "&:hover fieldset": {
      border: "none", // Elimina el borde en hover
    },
    "&.Mui-focused fieldset": {
      border: "none", // Elimina el borde cuando está enfocado
    },
  },
};

export const content = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  height: "calc(100vh - 68px)",
  width: "calc(100vw - 240px)",
  marginLeft: "240px",
  overflow: "auto",
};

export const perfilContainer = {
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  gap: Spaces.Size_4,
};

export const notificationButton = {
  display: 'none',
  "&:hover": {
    backgroundColor: Colors.Secondary_100,
  },
  "&:active": {
    transform: "scale(1)", // Evita el efecto de escalado
    boxShadow: "none", // Quita la sombra
  },
};

export const notificationMainContainer = {
  display: "flex",
  flexDirection: "column",
  padding: `${Spaces.Size_5} ${Spaces.Size_5} ${Spaces.Size_6} ${Spaces.Size_5}`,
  gap: Spaces.Size_4,
};

export const notificationTitleContainer = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
};

export const notificationFiltersContainer = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
};

export const filterButtonsContainer = {
  display: "flex",
  flexDirection: "row",
  gap: Spaces.Size_4,
};

export const notificationTitleFilterContainer = {
  display: "flex",
  flexDirection: "column",
  gap: Spaces.Size_4,
};

export const filterNotificationButton = {
  padding: 0,
  minWidth: 0,
  textTransform: "none",
  fontFamily: TextVariables.Caption_1,
};

export const notificationList = {
  display: "flex",
  flexDirection: "column",
  padding: "0",
  fontFamily: TextVariables.Caption_1,
  height: "216px",
  overflowY: "scroll",
  "::-webkit-scrollbar": {
    width: "8px",
    height: "4px",
  },
  "::-webkit-scrollbar-thumb": {
    backgroundColor: "#888",
    borderRadius: "10px",
    "&:hover": {
      backgroundColor: "#555",
    },
  },
  "::-webkit-scrollbar-track": {
    background: "#f1f1f1",
  },
};

export const selectItemStyle = {
  width: "90%",
  borderBottom: `1px solid ${Colors.Gray_200}`,
  display: "flex",
  flexDirection: "column",
  padding: "16px 0px 16px 0px",
};

export const selectItemContent = {
  display: "flex",
  flexDirection: "column",
  gap: Spaces.Size_2
};

export const notificationBody = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between"
};

export const noReadIcon = {
  height: "6px",
  width: "6px",
  padding: 0,
  backgroundColor: Colors.Info_600,
  borderRadius: "50%",
};

export const ChipBuyStyle = {
  fontFamily:TextVariables.Caption_1,
  color: Colors.Info_900,
  borderRadius: '8px',
  textTransform: 'none',
  background: Colors.Info_200,
  height:'20px'
} 

export const profileBox ={
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  gap: Spaces.Size_2,
  textTransform: "none",
  color: "Black"
}

export const nameBox = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start"
}

export const modalProfile = {
  display: "flex",
  flexDirection: "column",
};

export const modalProfileTitle = {
  display: "flex",
  flexDirection: "row",
  gap: Spaces.Size_2,
  borderBottom: `1px solid ${Colors.Gray_200}`,
  padding: Spaces.Size_4,
};

export const profileSelector = {
  fontFamily:TextVariables.Caption_1,
  textTransform: 'none',
  textAlign: "left",
  width: "100%",
  padding: Spaces.Size_4,
  color: "black",
  gap: Spaces.Size_2,
  "&:hover": {
      borderRadius: "0",
    },
};

export const contentLogin = {
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  overflow: "auto",
  height: "calc(100vh - 68px)",
};