import { TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Table } from "reactstrap";
import * as styles from "./Styles.js";

function FixedTablecomponent(props) {

    return(
      <TableContainer sx={styles.FixedTableHeader.container}>
          <Table sx={styles.FixedTableHeader.table}>
            <TableHead sx={styles.FixedTableHeader.tableHead}>
                <TableRow sx={styles.FixedTableHeader.tableRow}>
                  {props.columns.map((item, index) => (
                    <TableCell key={`header-${index}`} sx={styles.FixedTableHeader.tableCell}>{item}</TableCell>
                  ))}
                </TableRow>
            </TableHead>
            <TableBody sx={styles.FixedTableHeader.tableBody}>
                <TableRow sx={styles.FixedTableHeader.tableRow}>
                  {props.rows.map((item, index) => (
                    <TableCell key={`cell-${index}`} sx={styles.FixedTableHeader.tableCellRow}>{item}</TableCell>
                  ))}
                </TableRow>
            </TableBody>
          </Table>
      </TableContainer>
    )
}

export default FixedTablecomponent;