import AxiosInstance from "./Axios";
import Cookies from "universal-cookie";

const TransferRequest = async(params) => {
  try {
    const cookies = new Cookies();
    const token = cookies.get('token');
        
    const res = await AxiosInstance.put('/request-transfer', params, {
      headers: {
        'Authorization': `Bearer ${token}`
        }
    });

    return res;
  } catch (error) {
    console.error('error in request:', error);
    throw error; 
  }     
};

export default TransferRequest;