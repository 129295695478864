import { Line } from "react-chartjs-2";
import { Box, Typography, Checkbox  } from "@mui/material";
import React, { useState, useEffect } from "react";
import {
  Chart as ChartJs,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import Colors from "../../assets/Variables/ColorsVariables.js";
import {Spaces} from "../../assets/Variables/SpaceVariables.js";
import * as TextVariables from "../../assets/Variables/TextVariables";

ChartJs.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);


function LinesChart({ dataChart }) {
  const [data, setData] = useState({
    labels: ["Week 1", "Week 2", "Week 3", "Week 4"],
    datasets: [
      {
        label: "Volume",
        data: [65, 59, 80, 81],
        borderColor: Colors.Primary_400,
        backgroundColor: Colors.Primary_400,
        yAxisID: "y-axis-volumen",
        tension: 0.4,
      },
      {
        label: "Payouts",
        data: [28, 48, 40, 19],
        borderColor: Colors.Secondary_600,
        backgroundColor: Colors.Secondary_600,
        yAxisID: "y-axis-dinero",
        tension: 0.4,
      },
      {
        label: "Deposits",
        data: [35, 29, 50, 71],
        borderColor: Colors.Info_600,
        backgroundColor: Colors.Info_600,
        yAxisID: "y-axis-dinero",
        tension: 0.4,
      },
    ],
  });

  useEffect(() => {
    if (dataChart) {
      setData(dataChart);
    }
  }, [dataChart]);

  const OptionsLine = {
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    stacked: false,
    plugins: {
      legend: {
        display: false, // Oculta la leyenda predeterminada
      },
      title: {
        display: true,
      },
    },
    scales: {
      x: {
        display: true,
        title: {
          display: false,
          text: "Meses",
        },
        grid: {
          display: false, // Oculta las líneas de la cuadrícula horizontal
        },
      },
      "y-axis-volumen": {
        type: "linear",
        display: true,
        position: "left",
        title: {
          display: true,
          text: "Lots",
        },
      },
      "y-axis-dinero": {
        type: "linear",
        display: true,
        position: "right",
        title: {
          display: true,
          text: "Price ($)",
        },
        grid: {
          drawOnChartArea: false, // Esto evita que las líneas de la cuadrícula se muestren en el área del gráfico
        },
      },
    },
  };

  const toggleDataset = (datasetIndex) => {
    const updatedData = {
      ...data,
      datasets: data.datasets.map((dataset, index) => {
        if (index === datasetIndex) {
          return {
            ...dataset,
            hidden: !dataset.hidden,
          };
        }
        return dataset;
      }),
    };
    setData(updatedData);
  };

  const CustomLegend = ({ datasets, toggleDataset }) => {
    return (
      <Box
        sx={{
          display: "inline-flex",
          alignItems: "flex-start",
          gap: Spaces.Size_6,
        }}
      >
        {datasets.map((dataset, index) => (
          <Box
            onClick={() => toggleDataset(index)}
            key={index}
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              padding: "10px 16px",
              background: Colors.Secondary_50,
              borderRadius: Spaces.Size_2,
              gap: "10px"
            }}
          >
            <Checkbox
              checked={!dataset.hidden}
              onChange={() => toggleDataset(index)}
              inputProps={{ 'aria-label': dataset.label }}
              sx={{height: "14px", width: "14px", '&.Mui-checked': {
                  color: Colors.Success_800,
                }}}
            />
            <span className="legend-label">{dataset.label}</span>
            <Typography
              className="legend-marker"
              style={{
                display: "inline-block",
                width: Spaces.Size_4,
                height: Spaces.Size_1,
                backgroundColor: dataset.borderColor,
                textAlign: "center",
                borderRadius: Spaces.Size_1
              }}
            ></Typography>
          </Box>
        ))}
      </Box>
    );
  };

  return (
    <Box sx={{height: "100%",
        width: "100%",}}>
      {data.datasets && data.datasets.length > 0 && (
        <CustomLegend datasets={data.datasets} toggleDataset={toggleDataset} />
      )}
      <Line data={data} options={OptionsLine} />
    </Box>
  );
}

export default LinesChart;
