export const TapStyle = {
  container: {
      borderTop: 1,
      borderBottom: 1,
      borderColor: 'divider',
      display: 'flex',
      justifyContent: 'center',
      marginTop: '32px',
      marginBottom: '10px',
  },
  tap: {
      fontFamily: "Plus Jakarta Sans",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "600",
      color: "#15202A",
  },
  tapRoot: {
      '& .MuiTabs-indicator': {
          backgroundColor: '#15202A',
          borderRadius: '40px ',
          height: '3px',
      },
      '& .MuiTab-root': {
          fontFamily: "Plus Jakarta Sans",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: "400",
          color: "#15202A",
          textTransform: 'none'
      },
      '& .Mui-selected': {
          fontFamily: "Plus Jakarta Sans",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: "600",
          color: "#15202A !important",
      },
  }
};
