import React, { useLayoutEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Typography, Box } from "@mui/material";
import * as styles from "./Styles.js";
import * as TextVariables from "../../../assets/Variables/TextVariables.js";
import { Spaces, mainBox } from "../../../assets/Variables/SpaceVariables.js";
import Colors from "../../../assets/Variables/ColorsVariables.js";
import TitledInput from "../../../components/Inputs/TitledInput.js";
import * as MasterData from "../../../MasterData/MasterData.js";
import TextInputWithCounter from "../../../components/Inputs/TitledBigInput.js";
import TitledSelectorGeneral from "../../../components/Selectors/TitledSelectorGeneral.js";

function NetellerForm({ formValues, errors, handleChange }) {
  return (
    <Box sx={styles.formBox}>
      <Typography sx={{ fontFamily: TextVariables.Overline }}>
        We would like to inform you that due to increasing costs associated with
        Neteller, we will charge a fee of 1% on all deposits and withdrawals
        made via Neteller. Fees can be waived in certain circumstances after a
        review of your account with your Tradeview representative.
      </Typography>
      <Typography sx={{ fontFamily: TextVariables.Body_3_Bold }}>
        Neteller account details
      </Typography>
      <TitledInput
        name="associatedEmail"
        title="Associated email"
        placeholder="Please, enter your email"
        value={formValues.associatedEmail}
        handleChange={handleChange}
        error={errors.associatedEmail}
        helperText={
          errors.associatedEmail ? (
            <Box sx={styles.emptyFieldLabel}>
              <span
                className="material-symbols-outlined"
                style={{ fontSize: Spaces.Size_3, color: "red" }}
              >
                error
              </span>
              <Typography
                sx={{
                  fontFamily: TextVariables.Caption_1,
                  color: Colors.Error_700,
                }}
              >
                Please, enter your email
              </Typography>
            </Box>
          ) : (
            ""
          )
        }
      ></TitledInput>
    </Box>
  );
}

export default NetellerForm;
