import axios from "axios";

const GetIpRequest = async () => {
  const userLocalization = await axios.get('https://one.one.one.one/cdn-cgi/trace');
  const ipMatchV4 = userLocalization.data.match(/ip=([0-9]+\.[0-9]+\.[0-9]+\.[0-9]+)/);
  const ipMatchV6 = userLocalization.data.match(/ip=([a-fA-F0-9:]+)/);

  if (ipMatchV4) {
    const ipV4 = ipMatchV4[1];
    const response = { data: ipV4 };
    return response;
  }
  else if (ipMatchV6) {
    const ipV6 = ipMatchV6[1];
    const response = { data: ipV6 };
    return response;
  }
  else{
    const ipvx = await axios.get('https://api64.ipify.org?format=json');
    const response = {data: ipvx.data.ip};
    return response; 
  }
};

export default GetIpRequest;