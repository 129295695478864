import { Height } from "@mui/icons-material";
import * as TextVariables from "../../assets/Variables/TextVariables.js";
import Colors from "../../assets/Variables/ColorsVariables.js";
import {Spaces} from "../../assets/Variables/SpaceVariables.js";

export const header ={
    
}

export const tableStyles = {
    '& .header_table': {
        background: "#F9F9F9",
        padding:'12px',
        color: '#15202A',
        fontFamily: TextVariables.Caption_1_Bold,
    },
    '& .MuiDataGrid-footerContainer': {
        display: 'flex',
        justifyContent: 'center',
        fontFamily: TextVariables.Caption_1,
    },
    '& .MuiTablePagination-displayedRows': {
        display: 'none !important'
    },
    '& .MuiTablePagination-selectLabel': {
        display: 'none !important'
    },
    '& .MuiTablePagination-input': {
        display: 'none !important'
    },
    '& .MuiTablePagination-toolbar': {
        padding: 0
    },
    '& .MuiTablePagination-actions': {
        margin: "0px !important" ,
        padding: 0
    },
    '& .MuiDataGrid-row': {
        fontFamily: "Plus Jakarta Sans",
        fontSize: "11px",
        fontStyle: "normal",
        fontWeight: "400",
    },
    
};
export const paginationStyle = {
    '& .MuiPaginationItem-root': {
        background: "transparent",
        padding:'12px',
        color: '#43586B',
        fontFamily: "Plus Jakarta Sans",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "400",
    },
    '& .MuiPaginationItem-root.Mui-selected': {
        background: "#ECF1F7",
    },
};

export const FixedTableHeader = {
    container:{
        marginTop:"32px", 
        overflow: 'hidden',
        borderRadius: '12px',
        border:'1px solid #EEE',
        height:'74px'
    },
    table:{
        minWidth: 650 ,
    },
    tableHead:{
        backgroundColor:"#F9F9F9",
    },
    tableRow:{

    },
    tableCell:{
        fontFamily: "Plus Jakarta Sans",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "700",
        color:"#344555", 
    },
    tableCellRow:{
        fontFamily: "Plus Jakarta Sans",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "400",
        color:"#687B8E", 
        background:'#FFF'
    },
    tableBody:{
        backgroundColor:"#FFF"
    }
}

export const FixedTableRows = {

}

export const Label1FooterTable = {
    fontFamily: TextVariables.Caption_2, 
    color: Colors.Secondary_400,
    marginRight: "5px"
}

export const Label2FooterTable = {
    fontFamily: TextVariables.Caption_2_Bold, 
    color: Colors.Secondary_900,
}
