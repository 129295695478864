import { Line, Doughnut } from "react-chartjs-2";
import { Bar } from "react-chartjs-2";
import React, { useState, useEffect } from "react";
import {Spaces} from "../../assets/Variables/SpaceVariables.js";
import * as TextVariables from "../../assets/Variables/TextVariables";
import Colors from "../../assets/Variables/ColorsVariables.js";
import {
  Chart as ChartJs,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  ArcElement,
  BarController,
  BarElement,
} from "chart.js";

ChartJs.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  ArcElement,
  BarController,
  BarElement
);


function BarChart({ dataChart }) {
  //const [data, setData] = useState({dataChart});
  const [data, setData] = useState({
    labels: [
        "Active accounts (0)",
        "Inactive accounts (0)",
        "Unfunded accounts (0)",
      ],
    datasets: [
      {
        axis: "y",
        label: ["My First Dataset"],
        data: [0, 0, 0],
        fill: false,
        backgroundColor: [
          Colors.Secondary_900,
          Colors.Info_500,
          Colors.Primary_400,
        ],
        borderColor: [
          Colors.Secondary_900,
          Colors.Info_500,
          Colors.Primary_400,
        ],
        borderWidth: 1,
      },
    ],
  });

  useEffect(() => {
    if (dataChart) {
      setData(dataChart);
    }
  }, [dataChart]);

  // Opciones para el gráfico
  const barOptions = {
    indexAxis: "y",
    barThickness: 5,
    maintainAspectRatio: false,
    categorySpacing: 10,
    plugins: {
      legend: {
        position: "bottom",
        onClick: null,
        labels: {
          generateLabels: (chart) => {
            return chart.data.labels.map((label, index) => {
              return {
                text: label,
                fillStyle: chart.data.datasets[0].backgroundColor[index],
                borderRadius: 2,
                strokeStyle: "transparent", // Evitar bordes en las leyendas
                lineWidth: 0, // Evitar bordes en las leyendas
              };
            });
          },
          boxWidth: 16, // Ajusta el ancho de las cajas de color de la leyenda
          boxHeight: 4, // Ajusta la altura de las cajas de color de la leyenda
          borders: "none",
          font: TextVariables.Caption_1,
        },
      },
      tooltip: {
        borderColor: "rgba(0, 0, 0, 0.8)", // Color del borde del tooltip
        borderWidth: 1, // Ancho del borde del tooltipXF

        callbacks: {
          title: function (tooltipItem) {
            return ""; // Mostrar el nombre del mes en el tooltip
          },
          label: function (tooltipItem) {
            
            const dataIndex = tooltipItem.dataIndex;
            return data.labels[dataIndex]; // Mostrar el nombre del mes en el tooltip
          },
        },
      },
    },
    scales: {
      y: {
        display: false, // Ocultar etiquetas del eje y
        grid: {
          display: false, // Opcional: Ocultar líneas de la cuadrícula del eje y
        },
      },
      x: {
        display: true, // Mostrar etiquetas del eje x si es necesario
        grid: {
          display: true, // Opcional: Mostrar líneas de la cuadrícula del eje x
          color: "rgba(160, 175, 188, 1)", // Cambia el color de las barras verticales del grid
          borderWidth: 0, // Sin bordes en las líneas del grid en el eje x
        },
      },
    },
    layout: {
      padding: {
        bottom: -12, // Ajusta el espacio en la parte inferior para evitar que las líneas del grid se extiendan demasiado
      },
    },
  };

  return <Bar data={data} options={barOptions} />;
}

export default BarChart;
