const countries = [
    { label: 'Argentina', value: 'Argentina' },
    { label: 'Australia', value: 'Australia' },
    { label: 'Belgium', value: 'Belgium' },
    { label: 'Brazil', value: 'Brazil' },
    { label: 'Canada', value: 'Canada' },
    { label: 'China', value: 'China' },
    { label: 'Colombia', value: 'Colombia' },
    { label: 'France', value: 'France' },
    { label: 'Germany', value: 'Germany' },
    { label: 'Japan', value: 'Japan' },
    { label: 'Mexico', value: 'Mexico' },
    { label: 'Netherlands', value: 'Netherlands' },
    { label: 'Nigeria', value: 'Nigeria' },
    { label: 'Norway', value: 'Norway' },
    { label: 'Russia', value: 'Russia' },
    { label: 'Saudi Arabia', value: 'Saudi Arabia' },
    { label: 'South Korea', value: 'South Korea' },
    { label: 'Spain', value: 'Spain' },
    { label: 'Sweden', value: 'Sweden' },
    { label: 'Switzerland', value: 'Switzerland' },
    { label: 'Turkey', value: 'Turkey' },
    { label: 'United Kingdom', value: 'United Kingdom' },
    { label: 'United States', value: 'United States' }
  ];

  export default countries;