import React, { useState } from "react";
import { Box, TextField, Typography } from "@mui/material";
import * as TextVariables from "../../assets/Variables/TextVariables.js";
import Colors from "../../assets/Variables/ColorsVariables.js";
import { Spaces } from "../../assets/Variables/SpaceVariables.js";

const TextInputWithCounter = ({
  name,
  title,
  value,
  type = "text",
  handleChange,
  placeholder,
  maxChars,
}) => {
  const inputStyle = {
    fontFamily: TextVariables.Body_4,
    borderRadius: Spaces.Size_2,
    width: "100%",
    padding: "0px",
    "& .MuiOutlinedInput-root": {
      borderRadius: Spaces.Size_2, // Ajusta el valor según tus necesidades
      border: `1px solid ${Colors.Secondary_700}`,
      padding: `${Spaces.Size_4} ${Spaces.Size_4} ${Spaces.Size_8} ${Spaces.Size_4}`,
    },
    "& .MuiInputBase-input": {
      fontFamily: TextVariables.Caption_1,
      lineHeight: "1.4",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderStyle: "none",
    },
  };

  return (
    <Box sx={{ position: "relative", width: "100%" }}>
      <Typography

        sx={{
          marginRight: "10px",
          marginBottom: "5px",
          fontWeight: "400",
          fontSize: "12px",
          fontFamily: TextVariables.Body_4
        }}
      >
        {title}
      </Typography>
      <TextField
        multiline
        rows={2}
        name={name}
        value={value}
        onChange={handleChange}
        type={type}
        required
        sx={inputStyle}
        fullWidth
        placeholder={placeholder}
        inputProps={{ maxLength: maxChars }}
      />
      <Typography
        sx={{
          fontFamily: TextVariables.Caption_2,
          color: Colors.Gray_500,
          position: "absolute",
          bottom: 8,
          right: 8,
        }}
      >
        {`${value.length} of ${maxChars}`}
      </Typography>
    </Box>
  );
};

export default TextInputWithCounter;
