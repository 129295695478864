import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  TextField,
  InputAdornment,
  ListSubheader,
  Box,
} from "@mui/material";
import { Spaces } from "../../assets/Variables/SpaceVariables.js";
import Colors from "../../assets/Variables/ColorsVariables.js";
import * as TextVariables from "../../assets/Variables/TextVariables";
import TableToolTip from "../../components/ToolTips/TableToolTip";

const TitledSelector = ({
  title,
  value,
  handleChange,
  options,
  tooltext = "",
}) => {
  const selectStyle = {
    borderRadius: "8px",
    height: "33px",
    width: "172px",
    fontFamily: TextVariables.Overline,
  };

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const stopImmediatePropagation = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <div>
      <Box
        sx={{
          marginRight: "10px",
          marginBottom: "5px",
          display: "flex",
          flexDirection: "row",
          gap: Spaces.Size_1,
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontFamily: TextVariables.Caption_1,
          }}
        >
          {title}
        </Typography>
        {tooltext !== "" && (
          <TableToolTip title={tooltext}>
            <Box>
              <span
                style={{ fontSize: Spaces.Size_3 }}
                className="material-symbols-outlined"
              >
                help
              </span>
            </Box>
          </TableToolTip>
        )}
      </Box>

      <FormControl variant="outlined" style={{ marginRight: "15px" }}>
        <Select value={value} onChange={handleChange} sx={selectStyle}>
          <ListSubheader sx={{ display: "flex", padding: Spaces.Size_3 }}>
            <TextField
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: `1px solid ${Colors.Secondary_400}`,
                borderRadius: Spaces.Size_2,
                fontFamily: TextVariables.Caption_1,
                padding: "0px",
                "& .MuiInputBase-input": {
                  fontFamily: TextVariables.Caption_1,
                  padding: Spaces.Size_4,
                },
              }}
              onClickCapture={stopImmediatePropagation}
              onKeyDown={(e) => e.stopPropagation()}
              fullWidth
              placeholder="Search"
              onChange={handleSearchChange}
              value={searchTerm}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <span className="material-symbols-outlined">search</span>
                  </InputAdornment>
                ),
                sx: {
                  "& .MuiInputBase-input::placeholder": {
                    fontFamily: TextVariables.Caption_1,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none", // Elimina el borde
                  },
                },
                autoComplete: "off",
              }}
            />
          </ListSubheader>
          {filteredOptions.map((option, index) => (
            <MenuItem
              key={index}
              value={option.value}
              sx={{ fontFamily: TextVariables.Caption_1 }}
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

// TitledSelector.propTypes = {
//   title: PropTypes.string.isRequired,
//   value: PropTypes.string.isRequired,
//   handleChange: PropTypes.func.isRequired,
//   options: PropTypes.arrayOf(
//     PropTypes.shape({
//       value: PropTypes.string.isRequired,
//       label: PropTypes.string.isRequired
//     })
//   ).isRequired,
// };

export default TitledSelector;
