import React, { useState } from "react";
import { Typography, Box, Button } from "@mui/material";
import * as styles from "./Styles.js";
import * as TextVariables from "../../../assets/Variables/TextVariables.js";
import { Spaces } from "../../../assets/Variables/SpaceVariables.js";
import Colors from "../../../assets/Variables/ColorsVariables.js";
import TitledInput from "../../../components/Inputs/TitledInput.js";
import * as MasterData from "../../../MasterData/MasterData.js";
import TextInputWithCounter from "../../../components/Inputs/TitledBigInput.js";
import TitledSelectorGeneral from "../../../components/Selectors/TitledSelectorGeneral.js";

function AdvcashForm({ formValues, errors, handleChange }) {
  return (
    <Box sx={styles.formBox}>
      <Typography sx={{ fontFamily: TextVariables.Body_3_Bold }}>
      Advcash account details
      </Typography>
      <TitledInput
        name="associatedEmail"
        title="Associated email"
        placeholder="Please, enter your email"
        value={formValues.associatedEmail}
        handleChange={handleChange}
        error={errors.associatedEmail}
        helperText={
          errors.associatedEmail ? (
            <Box sx={styles.emptyFieldLabel}>
              <span
                className="material-symbols-outlined"
                style={{ fontSize: Spaces.Size_3, color: "red" }}
              >
                error
              </span>
              <Typography
                sx={{
                  fontFamily: TextVariables.Caption_1,
                  color: Colors.Error_700,
                }}
              >
                Please, enter your email
              </Typography>
            </Box>
          ) : (
            ""
          )
        }
      ></TitledInput>
    </Box>
  );
}

export default AdvcashForm;
