import React, { useState, useEffect } from "react";
import {
  Button,
  Container,
  Typography,
  Grid,
  Box,
  Paper,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import registerRequest from "../../Endpoints/RegisterRequest";
import GetIpRequest from "../../Endpoints/GetIpRequest";
import { useNavigate } from "react-router-dom";
import countries from "../../Tools/Countries";
import * as styles from "../Login/Styles.js";
import * as TextVariables from "../../assets/Variables/TextVariables.js";
import { Spaces, mainBox } from "../../assets/Variables/SpaceVariables.js";
import Colors from "../../assets/Variables/ColorsVariables.js";
import TitledSelectorGeneral from "../../components/Selectors/TitledSelectorGeneral";
import TitledInput from "../../components/Inputs/TitledInput.js";
import NotificationModal from "../../components/Notifications/NotificationModal.js";
import LoadingSpinner from "../../components/Loaders/SpinLoader.js";

const ReferralForm = () => {
  const minLength = /^.{8,}$/; // Al menos 8 caracteres
  const hasUpperCase = /[A-Z]/; // Al menos una mayúscula
  const hasNumber = /[0-9]/; // Al menos un número
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/; // Al menos un carácter especial

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const ib = searchParams.get("ib");
  const ib_tree = searchParams.get("ib_tree");
  const lang = searchParams.get("lang");
  const prefix = searchParams.get("prefix");

  const [ip, setIp] = useState("");
  const [loading, setLoading] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [modalProps, setModalProps] = useState({
    iconType: "",
    title: "",
    message: "",
    onAcceptLabel: undefined,
    onRefuseLabel: undefined,
    onAccept: undefined,
    onRefuse: undefined,
  });
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({
    email: "",
    firstName: "",
    lastName: "",
    country: "",
    city: "",
    address: "",
    phone: "",
    password: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({
    email: false,
    firstName: false,
    lastName: false,
    country: false,
    city: false,
    address: false,
    phone: false,
    password: false,
    confirmPassword: false,
  });

  if (!ib) {
    return <p>Damaged or incomplete url.</p>;
  }

  const getIp = async () => {
    setLoading(true);
    try {
      const response = await GetIpRequest();
      setLoading(false);
      if (response !== null) {
        setIp(response.data);
      } else {
        getIp();
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      getIp();
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    // Actualiza los valores del formulario
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    let valid = true;
    let newErrors = {};

    // Verificar cada campo
    for (const [key, value] of Object.entries(formValues)) {
      if (value === "" || value === false) {
        newErrors[key] = true;
        valid = false;
      } else {
        if (key === "email") {
          if (formValues.email.includes("@")) {
            newErrors[key] = false;
          } else {
            newErrors[key] = true;
            valid = false;
          }
        } else if (key === "password") {
          if (
            minLength.test(formValues.password) &&
            hasUpperCase.test(formValues.password) &&
            hasNumber.test(formValues.password) &&
            hasSpecialChar.test(formValues.password)
          ) {
            newErrors[key] = false;
          } else {
            newErrors[key] = true;
            valid = false;
          }
        } else if (key === "confirmPassword") {
          if (formValues.password === formValues.confirmPassword) {
            newErrors[key] = false;
          } else {
            newErrors[key] = true;
            valid = false;
          }
        } else {
          newErrors[key] = false;
        }
      }
    }

    setErrors(newErrors);

    if (valid) {
      setLoading(true);

      try {
        const response = await registerRequest({
          ib: ib ? ib : "",
          ib_tree: ib_tree ? ib_tree : "",
          language: lang ? lang : "",
          login_prefix: prefix ? prefix : "",
          read_only: true,
          email: formValues.email,
          first_name: formValues.firstName,
          last_name: formValues.lastName,
          country: formValues.country,
          city: formValues.city,
          address: formValues.address,
          phone: formValues.phone,
          password: formValues.password,
          ip: ip,
        });
        if (response.data != null) {
          setModalProps({
            iconType: "success",
            title: "Account created successfully!",
            message: `Login: ${response.data.data.login} - Server: ${response.data.data.server_name}`,
            onAcceptLabel: "Continue",
            onAccept: () => handleOnAccept(),
          });
          setShowNotification(true);
        } else {
          setModalProps({
            iconType: "server error",
            title: "Bad request",
            message: "Please contact an administrator",
            onAcceptLabel: "Continue",
            onAccept: () => handleOnAccept(),
          });
          setShowNotification(true);
        }
      } catch (error) {
        console.log(error);
        if (error.response && error.response.status === 400) {
          setModalProps({
            iconType: "error",
            title: "Bad request",
            message: error.response.data.message,
            onAcceptLabel: "Continue",
            onAccept: () => handleOnAccept(),
          });
          setShowNotification(true);
        } else {
          setModalProps({
            iconType: "error",
            title: "Bad request, please check your account information",
            onAcceptLabel: "Continue",
            onAccept: () => handleOnAccept(),
          });
          setShowNotification(true);
        }
      }
    }

    setLoading(false);
  };

  const handleOnAccept = () => {
    
  };

  const handleOnRefuse = () => {
    
  };

  const handleCloseNotification = () => {
    setShowNotification(false);
  };

  useEffect(() => {
    getIp();
  }, []);

  return (
    <Box sx={mainBox}>
      {loading ? <LoadingSpinner /> : <></>}
      <NotificationModal
        open={showNotification}
        onClose={handleCloseNotification}
        iconType={modalProps.iconType}
        title={modalProps.title}
        message={modalProps.message}
        onAcceptLabel={modalProps.onAcceptLabel}
        onRefuseLabel={modalProps.onRefuseLabel}
        onAccept={modalProps.onAccept}
        onRefuse={modalProps.onRefuse}
      />
      <Paper
        elevation={2}
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: Spaces.Size_5,
          width: "55%",
          maxWidth: "1110px",
          borderRadius: "12px",
        }}
      >
        <Container>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: Spaces.Size_6,
            }}
          >
            <Typography sx={{ fontFamily: TextVariables.Subtitle_4 }}>
              Create Account
            </Typography>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TitledInput
                    name="email"
                    title="Email"
                    value={formValues.email}
                    handleChange={handleChange}
                    placeholder={"Please, enter a valid email"}
                    error={errors.email}
                    helperText={
                      errors.email ? (
                        <Box sx={styles.emptyFieldLabel}>
                          <span
                            className="material-symbols-outlined"
                            style={{ fontSize: Spaces.Size_3, color: "red" }}
                          >
                            error
                          </span>
                          <Typography
                            sx={{
                              fontFamily: TextVariables.Caption_1,
                              color: Colors.Error_700,
                            }}
                          >
                            Please, enter a valid email
                          </Typography>
                        </Box>
                      ) : (
                        ""
                      )
                    }
                  ></TitledInput>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TitledInput
                    name="firstName"
                    title="First Name"
                    placeholder={"Please, enter an account name"}
                    value={formValues.firstName}
                    handleChange={handleChange}
                    error={errors.firstName}
                    helperText={
                      errors.firstName ? (
                        <Box sx={styles.emptyFieldLabel}>
                          <span
                            className="material-symbols-outlined"
                            style={{ fontSize: Spaces.Size_3, color: "red" }}
                          >
                            error
                          </span>
                          <Typography
                            sx={{
                              fontFamily: TextVariables.Caption_1,
                              color: Colors.Error_700,
                            }}
                          >
                            Please, Enter an account name
                          </Typography>
                        </Box>
                      ) : (
                        ""
                      )
                    }
                  ></TitledInput>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TitledInput
                    name="lastName"
                    title="Last Name"
                    placeholder={"Please, enter a last name"}
                    value={formValues.lastName}
                    handleChange={handleChange}
                    error={errors.lastName}
                    helperText={
                      errors.lastName ? (
                        <Box sx={styles.emptyFieldLabel}>
                          <span
                            className="material-symbols-outlined"
                            style={{ fontSize: Spaces.Size_3, color: "red" }}
                          >
                            error
                          </span>
                          <Typography
                            sx={{
                              fontFamily: TextVariables.Caption_1,
                              color: Colors.Error_700,
                            }}
                          >
                            Please, enter a last name
                          </Typography>
                        </Box>
                      ) : (
                        ""
                      )
                    }
                  ></TitledInput>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TitledSelectorGeneral
                    name="country"
                    title="Country"
                    placeholder={"Please, select a country"}
                    value={formValues.country}
                    handleChange={handleChange}
                    options={countries}
                    error={errors.country}
                    helperText={
                      errors.country ? (
                        <Box sx={styles.emptyFieldLabel}>
                          <span
                            className="material-symbols-outlined"
                            style={{ fontSize: Spaces.Size_3, color: "red" }}
                          >
                            error
                          </span>
                          <Typography
                            sx={{
                              fontFamily: TextVariables.Caption_1,
                              color: Colors.Error_700,
                            }}
                          >
                            Please, select a country
                          </Typography>
                        </Box>
                      ) : (
                        ""
                      )
                    }
                  ></TitledSelectorGeneral>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TitledInput
                    name="city"
                    title="City"
                    placeholder={"Please, enter a city"}
                    value={formValues.city}
                    handleChange={handleChange}
                    error={errors.city}
                    helperText={
                      errors.city ? (
                        <Box sx={styles.emptyFieldLabel}>
                          <span
                            className="material-symbols-outlined"
                            style={{ fontSize: Spaces.Size_3, color: "red" }}
                          >
                            error
                          </span>
                          <Typography
                            sx={{
                              fontFamily: TextVariables.Caption_1,
                              color: Colors.Error_700,
                            }}
                          >
                            Please, enter a city
                          </Typography>
                        </Box>
                      ) : (
                        ""
                      )
                    }
                  ></TitledInput>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TitledInput
                    name="address"
                    title="Address"
                    placeholder={"Please, enter an address"}
                    value={formValues.address}
                    handleChange={handleChange}
                    error={errors.address}
                    helperText={
                      errors.address ? (
                        <Box sx={styles.emptyFieldLabel}>
                          <span
                            className="material-symbols-outlined"
                            style={{ fontSize: Spaces.Size_3, color: "red" }}
                          >
                            error
                          </span>
                          <Typography
                            sx={{
                              fontFamily: TextVariables.Caption_1,
                              color: Colors.Error_700,
                            }}
                          >
                            Please, enter an address
                          </Typography>
                        </Box>
                      ) : (
                        ""
                      )
                    }
                  ></TitledInput>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TitledInput
                    name="phone"
                    title="Phone"
                    placeholder={"Please, enter a phone number"}
                    value={formValues.phone}
                    handleChange={handleChange}
                    error={errors.phone}
                    helperText={
                      errors.phone ? (
                        <Box sx={styles.emptyFieldLabel}>
                          <span
                            className="material-symbols-outlined"
                            style={{ fontSize: Spaces.Size_3, color: "red" }}
                          >
                            error
                          </span>
                          <Typography
                            sx={{
                              fontFamily: TextVariables.Caption_1,
                              color: Colors.Error_700,
                            }}
                          >
                            Please, enter a phone number
                          </Typography>
                        </Box>
                      ) : (
                        ""
                      )
                    }
                  ></TitledInput>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TitledInput
                    name="password"
                    title="Password"
                    type="password"
                    placeholder={"Please, enter a valid password"}
                    value={formValues.password}
                    handleChange={handleChange}
                    error={errors.password}
                    helperText={
                      errors.password ? (
                        <Box sx={styles.emptyFieldLabel}>
                          <span
                            className="material-symbols-outlined"
                            style={{ fontSize: Spaces.Size_3, color: "red" }}
                          >
                            error
                          </span>
                          <Typography
                            sx={{
                              fontFamily: TextVariables.Caption_1,
                              color: Colors.Error_700,
                            }}
                          >
                            Min. 8 characters, uppercase, number, symbol.
                          </Typography>
                        </Box>
                      ) : (
                        ""
                      )
                    }
                  ></TitledInput>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TitledInput
                    name="confirmPassword"
                    title="Confirm Password"
                    type="password"
                    placeholder={"Please, confirm your password"}
                    value={formValues.confirmPassword}
                    handleChange={handleChange}
                    error={errors.confirmPassword}
                    helperText={
                      errors.confirmPassword ? (
                        <Box sx={styles.emptyFieldLabel}>
                          <span
                            className="material-symbols-outlined"
                            style={{ fontSize: Spaces.Size_3, color: "red" }}
                          >
                            error
                          </span>
                          <Typography
                            sx={{
                              fontFamily: TextVariables.Caption_1,
                              color: Colors.Error_700,
                            }}
                          >
                            Please, confirm your password
                          </Typography>
                        </Box>
                      ) : (
                        ""
                      )
                    }
                  ></TitledInput>
                </Grid>
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="right" mt={2}>
                    <Button
                      onClick={handleSubmit}
                      variant="contained"
                      sx={{
                        height: Spaces.Size_12,
                        margin: "25px 0 0 0",
                        backgroundColor: Colors.Primary_700,
                        fontFamily: TextVariables.Caption_1_Bold,
                        color: "white",
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: "#FF0000", // Cambia el color al hacer hover
                          opacity: 0.8, // Aplica opacidad al hacer hover
                        },
                      }}
                    >
                      Continue
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Container>
      </Paper>
    </Box>
  );
};

export default ReferralForm;
