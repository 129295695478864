import * as TextVariables from "../../../assets/Variables/TextVariables.js";
import Colors from "../../../assets/Variables/ColorsVariables.js";
import { Spaces } from "../../../assets/Variables/SpaceVariables.js";

export const formBox = {
    display: "flex",
    flexDirection: "column",
    gap: Spaces.Size_7
}

export const emptyFieldLabel = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: Spaces.Size_1,
  };