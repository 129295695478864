import { styled } from '@mui/material/styles';
import { Typography, Paper, Box, Button, Stepper, Step, StepLabel, StepConnector, stepConnectorClasses } from "@mui/material";

const ColorlibConnector = styled(StepConnector)(({ theme, activeStep, nextStep }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 17,
      left: 'calc(-50% + 12px)',
      right: 'calc(50% + 12px)',
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderRadius: 1,
    },
    [`&.${stepConnectorClasses.active} .${stepConnectorClasses.line}`]: {
      backgroundImage: 'linear-gradient(to right, #EB0000 50%, #eaeaf0 50%)',
    },
    [`&.${stepConnectorClasses.completed} .${stepConnectorClasses.line}`]: {
      backgroundColor: '#EB0000',
    },
    [`&.nextStep .${stepConnectorClasses.line}`]: {
      backgroundColor: '#000',
    },
  }));
  
  const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    zIndex: 1,
    color: '#fff',
    width: 35,
    height: 35,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.completed && {
      backgroundColor: '#EB0000',
      boxShadow: '0 10px 20px 0 rgba(0,0,0,.25)',
    }),
  }));
  
  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;
  
    return (
      <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
        {props.icon}
      </ColorlibStepIconRoot>
    );
  }

function SteeperComponent({stepList,stepNumber}) {
    return(
        <Stepper alternativeLabel activeStep={stepNumber} connector={<ColorlibConnector />}>
            {stepList.map((label, index) => (
            <Step key={label} className={index === stepNumber + 1 ? 'nextStep' : ''}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>
                {label}
                </StepLabel>
            </Step>
            ))}
        </Stepper>
    );
}

export default SteeperComponent;

