export const TitleBox ={
    width:'100%',
    display: 'flex', 
    marginBottom: '25px' ,
    justifyContent: "space-between",
    alignItems: "center",
}

export const Title ={
    fontWeight: '600', 
    fontFamily:"Plus Jakarta Sans",
    fontSize: "17px",
}

export const Description ={
    fontWeight: '400', 
    fontFamily:"Plus Jakarta Sans",
    fontSize: "14px",
}

export const DownloadBottom ={
    border: 'none',
    background: 'none',
    padding: 0,
    minWidth: 0,
    width: 'auto',
    height: 'auto',
}
export const ExportButton = {
    fontFamily:"Plus Jakarta Sans",
    fontSize: "12px",
    fontStyle:"normal",
    fontWeight:"700",
    color: '#15202A',
    border: '1px solid #15202A',
    borderRadius: '8px',
    textTransform: 'none',
}
export const ChipBuyStyle = {
    fontFamily:"Plus Jakarta Sans",
    fontSize: "12px",
    fontStyle:"normal",
    fontWeight:"400",
    color: '#075C24',
    borderRadius: '8px',
    textTransform: 'none',
    background:'#E6F5EA',
    height:'20px'
} 
export const ChipSellStyle = {
    fontFamily:"Plus Jakarta Sans",
    fontSize: "12px",
    fontStyle:"normal",
    fontWeight:"400",
    color: '#8D0000',
    borderRadius: '8px',
    textTransform: 'none',
    background:'#FFE5E5',
    height:'20px'
} 
export const ProfitStyles = {
    fontFamily:"Plus Jakarta Sans",
    fontSize: "12px",
    fontStyle:"normal",
    fontWeight:"400",
    color: '#1C7B3E',
}
export const LossStyles = {
    fontFamily:"Plus Jakarta Sans",
    fontSize: "12px",
    fontStyle:"normal",
    fontWeight:"400",
    color: '#BC0000',
}
export const ApplyButton = {
    height: 33,
    width: 59,
    margin: "25px 0 0 0",
    backgroundColor: "#EB0000",
    fontWeight: "700",
    fontFamily: "Plus Jakarta Sans",
    fontSize: "12px",
    color: "white",
    textTransform: "none",
    borderRadius: "8px",
    padding: "12px",
    '&:hover': {
        backgroundColor: "#FF0000",
        opacity: 0.8,
    }
}
