import React, { createContext, useContext, useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';

const CookieContext = createContext(null);

export const useCookies = () => {
    return useContext(CookieContext);
};

const CookieChecker = ({ children }) => {
    const [user, setUser] = useState(null);
    const [type, setType] = useState(null);
    const [token, setToken] = useState(null);
    const [name, setName] = useState(null);
    const [id, setId] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const cookies = new Cookies();
        const userCookie = cookies.get("user");
        const nameCookie = cookies.get("name");
        const typeCookie = cookies.get("type");
        const tokenCookie = cookies.get("token");
        const idCookie = cookies.get("id");

        if (userCookie == null || typeCookie == null || tokenCookie == null || nameCookie == null) {
            navigate("/login");
        }

        setUser(userCookie);
        setName(nameCookie);
        setType(typeCookie);
        setToken(tokenCookie);
        setId(idCookie);
    }, [navigate]);

    return (
        <CookieContext.Provider value={{ user, name, type, token, id }}>
            {children}
        </CookieContext.Provider>
    );
};

export default CookieChecker;
