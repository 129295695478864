import React, { useState } from "react";
import { Typography, Box, Paper } from "@mui/material";
import * as styles from "../Styles";

function BankwireModal({ requestInfo, formattedDate }) {
  return (
    <Paper sx={styles.paperTreeDetails}>
    <Box sx={styles.labelContainerTreeDetails}>
      <Typography sx={styles.labelTreeDetails}>ID:</Typography>
      <Typography sx={styles.valueTreeDetails}>
        {requestInfo.id}
      </Typography>
    </Box>
    <Box sx={styles.labelContainerTreeDetails}>
      <Typography sx={styles.labelTreeDetails}>
        Request time:
      </Typography>
      <Typography sx={styles.valueTreeDetails}>
        {formattedDate}
      </Typography>
    </Box>
    <Box sx={styles.labelContainerTreeDetails}>
      <Typography sx={styles.labelTreeDetails}>
        Request type:
      </Typography>
      <Typography sx={styles.valueTreeDetails}>
        {requestInfo.request_type.toLowerCase().replace("_", " ")}
      </Typography>
    </Box>
    <Box sx={styles.labelContainerTreeDetails}>
      <Typography sx={styles.labelTreeDetails}>State:</Typography>
      <Typography sx={styles.valueTreeDetails}>
        {requestInfo.state.toLowerCase()}
      </Typography>
    </Box>
    <Box sx={styles.labelContainerTreeDetails}>
      <Typography sx={styles.labelTreeDetails}>Amount:</Typography>
      <Typography sx={styles.valueTreeDetails}>
        {requestInfo.request.amount}
      </Typography>
    </Box>
    <Box sx={styles.labelContainerTreeDetails}>
      <Typography sx={styles.labelTreeDetails}>
        Withdrawal method:
      </Typography>
      <Typography sx={styles.valueTreeDetails}>
        {requestInfo.request.withdrawalMethod}
      </Typography>
    </Box>
    <Box sx={styles.labelContainerTreeDetails}>
      <Typography sx={styles.labelTreeDetails}>Beneficiary name:</Typography>
      <Typography sx={styles.valueTreeDetails}>
        {requestInfo.request.beneficiaryName}
      </Typography>
    </Box>
    <Box sx={styles.labelContainerTreeDetails}>
      <Typography sx={styles.labelTreeDetails}>Beneficiary address:</Typography>
      <Typography sx={styles.valueTreeDetails}>
        {requestInfo.request.beneficiaryAddress}
      </Typography>
    </Box>
    <Box sx={styles.labelContainerTreeDetails}>
      <Typography sx={styles.labelTreeDetails}>Account number:</Typography>
      <Typography sx={styles.valueTreeDetails}>
        {requestInfo.request.accountNumber}
      </Typography>
    </Box>
    <Box sx={styles.labelContainerTreeDetails}>
      <Typography sx={styles.labelTreeDetails}>Bank name:</Typography>
      <Typography sx={styles.valueTreeDetails}>
        {requestInfo.request.bankName}
      </Typography>
    </Box>
    <Box sx={styles.labelContainerTreeDetails}>
      <Typography sx={styles.labelTreeDetails}>Swift code:</Typography>
      <Typography sx={styles.valueTreeDetails}>
        {requestInfo.request.swiftCode}
      </Typography>
    </Box>
    <Box sx={styles.labelContainerTreeDetails}>
      <Typography sx={styles.labelTreeDetails}>ABA number:</Typography>
      <Typography sx={styles.valueTreeDetails}>
        {requestInfo.request.abaNumber}
      </Typography>
    </Box>
    <Box sx={styles.labelContainerTreeDetails}>
      <Typography sx={styles.labelTreeDetails}>Sort code:</Typography>
      <Typography sx={styles.valueTreeDetails}>
        {requestInfo.request.sortCode}
      </Typography>
    </Box>
    <Box sx={styles.labelContainerTreeDetails}>
      <Typography sx={styles.labelTreeDetails}>Reference number:</Typography>
      <Typography sx={styles.valueTreeDetails}>
        {requestInfo.request.referenceNumber}
      </Typography>
    </Box>
    <Box sx={styles.labelContainerTreeDetails}>
      <Typography sx={styles.labelTreeDetails}>Associated email:</Typography>
      <Typography sx={styles.valueTreeDetails}>
        {requestInfo.request.associatedEmail}
      </Typography>
    </Box>
    <Box sx={styles.labelContainerTreeDetails}>
      <Typography sx={styles.labelTreeDetails}>Intermediary bank:</Typography>
      <Typography sx={styles.valueTreeDetails}>
        {requestInfo.request.intermediaryBank === "true" ? "Yes" : "No"}
      </Typography>
    </Box>
    <Box sx={styles.labelContainerTreeDetails}>
      <Typography sx={styles.labelTreeDetails}>Intermediary bank name:</Typography>
      <Typography sx={styles.valueTreeDetails}>
        {requestInfo.request.intermediaryBankName}
      </Typography>
    </Box>
    <Box sx={styles.labelContainerTreeDetails}>
      <Typography sx={styles.labelTreeDetails}>Intermediary bank address:</Typography>
      <Typography sx={styles.valueTreeDetails}>
        {requestInfo.request.intermediaryBankAddress}
      </Typography>
    </Box>
    <Box sx={styles.labelContainerTreeDetails}>
      <Typography sx={styles.labelTreeDetails}>Intermediary bank swift code:</Typography>
      <Typography sx={styles.valueTreeDetails}>
        {requestInfo.request.swiftCodeIntermediaryBank}
      </Typography>
    </Box>
    <Box sx={styles.labelContainerTreeDetails}>
      <Typography sx={styles.labelTreeDetails}>Intermediary bank IBAN number:</Typography>
      <Typography sx={styles.valueTreeDetails}>
        {requestInfo.request.ibanNumberIntermediaryBank}
      </Typography>
    </Box>
    <Box sx={styles.labelContainerTreeDetails}>
      <Typography sx={styles.labelTreeDetails}>Intermediary bank ABA number:</Typography>
      <Typography sx={styles.valueTreeDetails}>
        {requestInfo.request.abaNumberIntermediaryBank}
      </Typography>
    </Box>
    <Box sx={styles.labelContainerTreeDetails}>
      <Typography sx={styles.labelTreeDetails}>Intermediary bank sort code:</Typography>
      <Typography sx={styles.valueTreeDetails}>
        {requestInfo.request.sortCodeIntermediaryBank}
      </Typography>
    </Box>
  </Paper>
  );
}

export default BankwireModal;
