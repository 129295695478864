import * as TextVariables from "../../assets/Variables/TextVariables.js";
import Colors from "../../assets/Variables/ColorsVariables.js";
import {Spaces} from "../../assets/Variables/SpaceVariables.js";


export const MainPaper ={
    padding: Spaces.Size_5,
    width: "calc(100vw - 280px)",
    maxWidth: "1600px",
    borderRadius: "12px",
    display: "flex",
    flexDirection: "column",
    gap: Spaces.Size_3
}

export const TitleBox ={
    width:'100%',
    display: 'flex', 
    justifyContent: "space-between",
    alignItems: "center",
}

export const Title ={
    fontWeight: '600', 
    fontFamily:"Plus Jakarta Sans",
    fontSize: "17px",
}

export const Description ={
    fontWeight: '400', 
    fontFamily:"Plus Jakarta Sans",
    fontSize: "14px",
}

export const DownloadBottom ={
    border: 'none',
    background: 'none',
    padding: 0,
    minWidth: 0,
    width: 'auto',
    height: 'auto',
}

export const ChipBuyStyle = {
    fontFamily:"Plus Jakarta Sans",
    fontSize: "12px",
    fontStyle:"normal",
    fontWeight:"400",
    color: '#075C24',
    borderRadius: '8px',
    textTransform: 'none',
    background:'#E6F5EA',
    height:'20px'
} 
export const ChipSellStyle = {
    fontFamily:"Plus Jakarta Sans",
    fontSize: "12px",
    fontStyle:"normal",
    fontWeight:"400",
    color: '#8D0000',
    borderRadius: '8px',
    textTransform: 'none',
    background:'#FFE5E5',
    height:'20px'
} 
export const ProfitStyles = {
    fontFamily:"Plus Jakarta Sans",
    fontSize: "12px",
    fontStyle:"normal",
    fontWeight:"400",
    color: '#1C7B3E',
}
export const LossStyles = {
    fontFamily:"Plus Jakarta Sans",
    fontSize: "12px",
    fontStyle:"normal",
    fontWeight:"400",
    color: '#BC0000',
}
export const ApplyButton = {
    height: 33,
    width: 59,
    margin: "25px 0 0 0",
    backgroundColor: "#EB0000",
    fontWeight: "700",
    fontFamily: "Plus Jakarta Sans",
    fontSize: "12px",
    color: "white",
    textTransform: "none",
    borderRadius: "8px",
    padding: "12px",
    '&:hover': {
        backgroundColor: "#FF0000",
        opacity: 0.8,
    }
}

export const WalletInfoContainer = {
    display: "flex",
    flexDirection: "row"
}

export const WalletLabel1 = {
    fontFamily: TextVariables.Body_3_bold, 
    color: Colors.Secondary_400,
    marginRight: "5px"
}

export const WalletLabel2 = {
    fontFamily: TextVariables.Body_3_bold, 
    color: Colors.Secondary_900,
}

export const ExportButton = {
    fontFamily:"Plus Jakarta Sans",
    fontSize: "12px",
    fontStyle:"normal",
    fontWeight:"700",
    color: '#15202A',
    border: '1px solid #15202A',
    borderRadius: '8px',
    textTransform: 'none',
}