import { useEffect, useState, useCallback  } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";
import { Typography, Paper, Button, Box} from "@mui/material";
import { DataGrid, useGridApiRef  } from '@mui/x-data-grid';
import DownloadIcon from '@mui/icons-material/Download';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

function RequestStatusPage(props) {
    const [user, setUser] = useState("");
    const [type, setType] = useState("");
    const [token, setToken] = useState("");
    const [ip, setIp] = useState("");
    const navigate = useNavigate();
    const [t] = useTranslation("global");
    const apiRef = useGridApiRef();

    const autosizeOptions = {
        includeOutliers: true,
        includeHeaders: true,
        expand: true
    };

    const handleClickToBackPage = () => {
        navigate("/backoffice-request");
    };

    const handleResize = useCallback(() => {
        if (apiRef.current) {
            apiRef.current.autosizeColumns(autosizeOptions);
        }
    }, [apiRef]);

    useEffect(() => {
        const cookies = new Cookies();
        setUser(cookies.get("user"));
        setType(cookies.get("account type"));
        setToken(cookies.get("token"));
        setIp(cookies.get("ip"));
    }, []);

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [handleResize]);

    const handleDoubleRowClick = (params) => {
        navigate("/my-clients/" + params.row.id);
    };

    const columns = [
        { field: 'request_type', headerName: 'Request type', description: 'describeme :D', headerClassName: 'header_table'},
        { field: 'time_elapsed', headerName: 'Time Elapsed', headerClassName: 'header_table'},
        { field: 'status', headerName: 'Status', headerClassName: 'header_table', sortable:false, 
            filterable:false, hideable:false, resizable:false, disableColumnMenu: true, hideable:false,
            redimensionable:false, renderCell:(params)=>{
                let colorBackground = "#DEDEDE";
                let fontcolor = "#7E7E7E";
                if(params.row.status == "Pending"){colorBackground = "#E2E2E2"; fontcolor = "#7E7E7E";}
                if(params.row.status == "Approved"){colorBackground = "#E6F5EA"; fontcolor = "#075C24";}
                if(params.row.status == "Rejeted"){colorBackground = "#FFE5E5"; fontcolor = "#8D0000";}
                if(params.row.status == "On hold"){colorBackground = "#FEEFC7"; fontcolor = "#925230";}
                return <Box
                    style={{ width:"60%", backgroundColor: colorBackground, height: '30px', marginTop: '10px', borderRadius: '20px',
                        display: 'flex', justifyContent: 'center', alignItems: 'center', color: fontcolor, fontWeight: 'bold',
                    }}    
                    >
                    {params.row.status}
                </Box>
            }
        },
        { field: 'chat_history', headerName: 'Chat History', headerClassName: 'header_table', sortable:false, 
            filterable:false, hideable:false, resizable:false, disableColumnMenu: true, hideable:false,
            redimensionable:false, renderCell:(params)=>{
                return <Button
                    variant="outlined"
                    style={{
                        width:"100px",
                        height: '30px',
                        marginTop: '10px',
                        borderRadius: '10px',
                        borderColor: '#15202A',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: '#15202A',
                        fontWeight: 'bold',
                    }}    
                    >
                    View chat
                </Button>
            }
        }];

    const rows = [
        { id: 1, request_type: 'Broker_WITHDRAWAL', time_elapsed: '40 Minutes', status: 'Pending', chat_history: '/linkcito'},
        { id: 2, request_type: 'Broker_WITHDRAWAL', time_elapsed: '41 Minutes', status: 'Approved', chat_history: '/linkcito1'},
        { id: 3, request_type: 'Broker_WITHDRAWAL', time_elapsed: '42 Minutes', status: 'Rejeted', chat_history: '/linkcito2'},
        { id: 4, request_type: 'Broker_WITHDRAWAL', time_elapsed: '43 Minutes', status: 'On hold', chat_history: '/linkcito3'},
      ];

    return (
        <Paper elevation={2} sx={{ 
            padding: 4, 
            maxWidth: 'calc(100vw - 240px)',
            minHeight: 'calc(100vh - 100px)',
            marginTop: '25px',
            marginLeft: '25px',
            borderRadius: '12px'
        }}>
             <Box sx={{ width: "100%", display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px' }}>
                <Button onClick={handleClickToBackPage} startIcon={<ArrowBackIosIcon style={{ fontSize: 10 }} />} sx={{ textTransform: 'none', color: 'black' }}>
                    Go back
                </Button>
            </Box>
            <Typography variant="h5" component="h2" gutterBottom sx={{ fontWeight: 'bold' }}>
                Request Status
            </Typography>
            <div style={{ height: 600, width: '100%', marginTop:30}}>
                <DataGrid
                    apiRef={apiRef}
                    rows={rows}
                    columns={columns}
                    onRowDoubleClick={handleDoubleRowClick}
                    hideFooterSelectedRowCount
                    initialState={{ pagination: { paginationModel: { pageSize: 10 }, }, }}
                    pageSizeOptions={[10, 20, 50, 100]}
                    getRowHeight={() => '20px'}
                    rowHeight={70}
                    density="compact"
                    autosizeOptions={autosizeOptions}
                    onStateChange={handleResize}
                    sx={{
                        '& .header_table': {
                          backgroundColor: '#F9F9F9',
                          color: '#000',
                          fontWeight: 'bold',
                          fontSize:15,
                          fontStyle: 'bold'
                        },
                      }}
                />
            </div>
        </Paper>
    );
}

export default RequestStatusPage;