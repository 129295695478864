import '../../scss/Footer.scss';
import IconComponent from "../../components/Icons/IconComponent";

const Footer = (props) => {
  const currentYear = new Date().getFullYear();
  return (
    <div>
      <div className="helpIcon">
        <IconComponent icon="help" size="24px"/> 
      </div>
      <div className="footer">
        {currentYear} Tradeview® IB Portal V1.0.1 &nbsp;&nbsp;For more information visit &nbsp;<a href="https://www.tradeviewforex.com/" target="_blank">www.tradeviewforex.com</a>
      </div>
    </div>
  );
};

export default Footer;