import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Typography, Paper, Box, Button } from "@mui/material";
import { WidthFull } from "@mui/icons-material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import * as styles from "../BackofficeRequest/Styles.js";
import { Spaces } from "../../assets/Variables/SpaceVariables.js";
import * as TextVariables from "../../assets/Variables/TextVariables.js";
import MoneyCard from "../../components/Cards/MoneyCard.js";
import TitledInput from "../../components/Inputs/TitledInput.js";
import Colors from "../../assets/Variables/ColorsVariables.js";
import NotificationModal from "../../components/Notifications/NotificationModal.js";
import TitledSelectorGeneral from "../../components/Selectors/TitledSelectorGeneral.js";
import WalletRequest from "../../Endpoints/WalletRequest.js";
import TransferRequest from "../../Endpoints/TransferRequest.js";
import ServersRequest from "../../Endpoints/ServersRequest.js";
import { GetServersdata, RemoveServersData, SetServersdata } from "../../Tools/LocalStorage.js";

function TransferRequestPage(props) {
  const navigate = useNavigate();
  const [t] = useTranslation("global");
  const [showNotification, setShowNotification] = useState(false);
  const [modalProps, setModalProps] = useState({
    iconType: "",
    title: "",
    message: "",
    onAcceptLabel: undefined,
    onRefuseLabel: undefined,
    onAccept: undefined,
    onRefuse: undefined,
  });
  const [wallet, setWallet] = useState(0);
  const [servers, setServers] = useState([]);
  const [errors, setErrors] = useState({
    amount: false,
    account: false,
    server: false,
  });
  const [formValues, setFormValues] = useState({
    amount: "",
    account: "",
    server: "",
  });

  useEffect(() => {
    RequestWalletData();
    const serversData = GetServersdata();
    if(serversData.length > 0){
      setServers(serversData)
    }
    else{
      RequestServersData();
    }
  }, []);

  const RequestWalletData = async () => {
    try{
      const response = await WalletRequest();
      if (response.data.data != null) {
        setWallet(parseFloat(response.data.data.wallet.balance).toFixed(2))
      }
      else{
        setWallet(0)
      }
    }
    catch(error){
      setWallet(0)
      console.log(error);
    }
  }

  const RequestServersData = async () => {
    try{
      const response = await ServersRequest();
      if (response.data.data != null) {
        const servers = response.data.data.servers
        let actualRows = servers.map((server, index) => {
          let row = {
              label: server,
              value: server
          }
          return row;
        });
        setServers(actualRows)
        SetServersdata(actualRows)
      }
      else{
        setServers([])
        RemoveServersData();
      }
    }
    catch(error){
      setServers([])
      RemoveServersData();
      console.log(error);
    }
  }

  const RequestTransferData = async (params) => {
    try {
      const response = await TransferRequest(params);
      if (response && response.data != null) {
        setModalProps({
          iconType: "success",
          title: "Transfer successful",
          message: "The transfer has been carried out successfully",
          onAcceptLabel: "Continue",
          onAccept: () => handleClickToBackPage(),
        });
        setShowNotification(true);
      } else {
        setModalProps({
          iconType: "warning",
          title: "Error in request",
          message: "Please try later",
          onAcceptLabel: "Try again",
          onRefuseLabel: "Close",
          onAccept: () => handleOnAccept(),
          onRefuse: () => handleOnRefuse(),
        });
        setShowNotification(true);
      }
    } catch (error) {
      if (error.response) {
        if(error.response.status === 400){
          console.log("error 400:" + error.response.data.message)
          setModalProps({
            iconType: "warning",
            title: "Problem with request",
            message: error.response.data.message,
            onAcceptLabel: "Try again",
            onAccept: () => handleOnAccept()
          });
          setShowNotification(true);
        }
        else{
          setModalProps({
            iconType: "error",
            title: "Problem with service",
            message: "The following error has been caused:" + error.response.data.message + ". please contact an administrator",
            onAcceptLabel: "Try again",
            onAccept: () => handleOnAccept()
          });
          setShowNotification(true);
        }
      }
      else {
        setModalProps({
          iconType: "error",
          title: "Problem with service",
          message: "Please contact an administrator - " + error.message,
          onAcceptLabel: "Accept",
          onAccept: () => handleOnAccept()
        });
        setShowNotification(true);
      }
    }
  };

  const handleClickToBackPage = () => {
    navigate("/requests");
  };

  const handleChangeAmount = (event) => {
    const inputValue = event.target.value.replace(/[^0-9.]/g, "");
    if (inputValue.length <= 20) {
      setFormValues({
        ...formValues,
        amount: inputValue,
      });
    }
  };

  const handleChangeAccount = (event) => {
    const inputValue = event.target.value.replace(/[^0-9]/g, "");
    if (inputValue.length <= 10) {
      setFormValues({
        ...formValues,
        account: inputValue,
      });
    }
  };

  const handleChangeServer = (event) => {
    setFormValues({
      ...formValues,
      server: event.target.value,
    });
  };

  const handleOnAccept = () => {
    
  };

  const handleOnRefuse = () => {
    
  };

  const handleSubmit = () => {
    let valid = true;
    let newErrors = {};

    for (const [key, value] of Object.entries(formValues)) {
      if (value === "" || value === false) {
        newErrors[key] = true;
        valid = false;
      } else {
        newErrors[key] = false;
      }
    }

    setErrors(newErrors);

    if (valid) {
      RequestTransferData({
        server: formValues.server,
        login: formValues.account,
        source_currency: "USD",
        amount: formValues.amount
      });
    } else {
      setModalProps({
        iconType: "warning",
        title: "Incomplete fields",
        message: "Please fill in the missing fields",
        onAcceptLabel: "Try again",
        onRefuseLabel: "Close",
        onAccept: () => handleOnAccept(),
        onRefuse: () => handleOnRefuse(),
      });
      setShowNotification(true);
      
    }
  };

  const handleCloseNotification = () => {
    setShowNotification(false);
  };

  return (
    <Box sx={styles.mainBox}>
      {}
      <NotificationModal
        open={showNotification}
        onClose={handleCloseNotification}
        iconType={modalProps.iconType}
        title={modalProps.title}
        message={modalProps.message}
        onAcceptLabel={modalProps.onAcceptLabel}
        onRefuseLabel={modalProps.onRefuseLabel}
        onAccept={modalProps.onAccept}
        onRefuse={modalProps.onRefuse}
      />
      <Box sx={styles.secondBox}>
        <Box sx={styles.backButtonContainer}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button
              onClick={handleClickToBackPage}
              startIcon={<ArrowBackIosIcon style={{ fontSize: 10 }} />}
              sx={{ textTransform: "none", color: "black" }}
            >
              Go back
            </Button>
          </Box>
        </Box>
        <Paper elevation={2} sx={styles.mainPaper}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: Spaces.Size_8,
            }}
          >
            <Box sx={{}}>
              <Typography sx={{ fontFamily: TextVariables.Body_3_Bold }}>
                Transfer Request
              </Typography>
            </Box>
            <Box
              style={{
                display: "flex",
                gap: Spaces.Size_6,
                WidthFull,
                justifyContent: "space-between",
              }}
            >
              <MoneyCard
                title="Withdrawable amount"
                value={wallet || 0}
                asset={"USD"}
              ></MoneyCard>
              <Box sx={styles.formWithdrwawal}>
                <TitledInput
                  name="Amount"
                  title="Amount"
                  value={formValues.amount}
                  placeholder={"Enter the amount to transfer"}
                  handleChange={handleChangeAmount}
                  type="number"
                  error={errors.amount}
                  helperText={
                    errors.amount ? (
                      <Box sx={styles.emptyFieldLabel}>
                        <span
                          className="material-symbols-outlined"
                          style={{ fontSize: Spaces.Size_3, color: "red" }}
                        >
                          error
                        </span>
                        <Typography
                          sx={{
                            fontFamily: TextVariables.Caption_1,
                            color: Colors.Error_700,
                          }}
                        >
                          Please, enter an amount
                        </Typography>
                      </Box>
                    ) : (
                      ""
                    )
                  }
                ></TitledInput>
                <TitledSelectorGeneral
                  name="Server"
                  title="Server"
                  placeholder={"Select an option"}
                  value={formValues.server}
                  handleChange={handleChangeServer}
                  options={servers}
                  error={errors.server}
                  helperText={
                    errors.server ? (
                      <Box sx={styles.emptyFieldLabel}>
                        <span
                          className="material-symbols-outlined"
                          style={{ fontSize: Spaces.Size_3, color: "red" }}
                        >
                          error
                        </span>
                        <Typography
                          sx={{
                            fontFamily: TextVariables.Caption_1,
                            color: Colors.Error_700,
                          }}
                        >
                          Please, select a server
                        </Typography>
                      </Box>
                    ) : (
                      ""
                    )
                  }
                ></TitledSelectorGeneral>
                <TitledInput
                  name="Destination account"
                  title="Destination account"
                  value={formValues.account}
                  placeholder={"Enter the destination account number"}
                  handleChange={handleChangeAccount}
                  type="number"
                  error={errors.account}
                  helperText={
                    errors.account ? (
                      <Box sx={styles.emptyFieldLabel}>
                        <span
                          className="material-symbols-outlined"
                          style={{ fontSize: Spaces.Size_3, color: "red" }}
                        >
                          error
                        </span>
                        <Typography
                          sx={{
                            fontFamily: TextVariables.Caption_1,
                            color: Colors.Error_700,
                          }}
                        >
                          Please, enter an account
                        </Typography>
                      </Box>
                    ) : (
                      ""
                    )
                  }
                ></TitledInput>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button sx={styles.submitButton} onClick={handleSubmit}>
              Submit request
            </Button>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}

export default TransferRequestPage;
