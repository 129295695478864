import React, { useEffect, useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import * as styles from "./Styles.js";

function TapComponent(props) {
    const [tabstate, setTabstate] = useState(0);

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleTabStateChange = (event, value) => {
        setTabstate(value);
        props.onTabstate(value);
    };

    return (
        <Box sx={styles.TapStyle.container}>
            <Tabs
                value={tabstate}
                onChange={handleTabStateChange}
                aria-label="historical"
                sx={styles.TapStyle.tapRoot}
            >
                {props.tapsContent.map((item, index) => (
                    <Tab
                        key={`option-${index}`}
                        label={item}
                        {...a11yProps(index)}
                        sx={styles.TapStyle.tap}
                    />
                ))}
            </Tabs>
        </Box>
    );
}

export default TapComponent;
