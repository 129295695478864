import React from "react";
import PropTypes from "prop-types";
import {
  Typography,
  createTheme,
  ThemeProvider,
  Box
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from 'dayjs';

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-input": {
            fontWeight: "400",
            fontSize: "10px",
            fontFamily: "Plus Jakarta Sans",
            textTransform: "lowercase", // Cambia el texto del placeholder a minúsculas
          },
          "& .MuiOutlinedInput-root": {
            borderRadius: '8px', // Cambia el radio del borde
            height: "33px",
            width: "131px",
          },
          "& .MuiInputBase-input::placeholder": {
            textTransform: "lowercase", // Cambia el texto del placeholder a minúsculas
          },
          "& .MuiIconButton-root": {
            padding: "10px", // Ajusta el padding del icono
            fontSize: "5px"
          },
          "& .MuiSvgIcon-root": {
            height: "16px",
            width: "16px"
          },
        },
      },
    },
  },
});

const DateSelector = ({ title, value, handleChange }) => {
  return (
    <ThemeProvider theme={theme}>
      <Box margin="0 15px 0 0">
        <Typography
          variant="h6"
          style={{
            marginRight: "10px",
            marginBottom: "5px",
            fontWeight: "400",
            fontSize: "12px",
          }}
        >
          {title}
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            value={value}
            onChange={handleChange}
          />
        </LocalizationProvider>
      </Box>
    </ThemeProvider>
  );
};

DateSelector.propTypes = {
  title: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default DateSelector;
