export const BreakCrumStyle ={
    textTransform: 'none', 
    color: '#687B8E',
    fontFamily:"Plus Jakarta Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: '600', 
}
export const BreakCrumStyleName ={
    color: '#15202A',
}
export const BreakCrumStyleCountry ={
    fontWeight: '400', 
}