import React, { useState } from "react";
import { Paper, InputBase, Box } from "@mui/material";
import * as styles from "./Styles.js";
import IconComponent from "../Icons/IconComponent.js";

function SearchBoxComponent(props) {
    const [searchTerm, setSearchTerm] = useState('');

    const handleSearchChange = (event) => {
        const value = event.target.value;
        setSearchTerm(value);
        
        const filtered = props.rows.filter(row =>
          props.filterFields.some(field => 
            row[field]?.toString().toLowerCase().includes(value.toLowerCase())
          )
        );   
        
        props.onFilteredRowsChange(filtered);
    };
    
    return(
        <Paper elevation={1} sx={styles.PaperStyle}>
            <Box sx={styles.SearchIconStyles}>
                <IconComponent icon="search" size="20px"/>
            </Box>

            <InputBase
                placeholder={props.placeholder}
                sx={styles.InPutBaseStyle}
                value={searchTerm}
                onChange={handleSearchChange}
            />
        </Paper>
    );
}

export default SearchBoxComponent;
