export function SetDashboardData(response, subIB){
    localStorage.setItem(process.env.REACT_APP_WEB_PREFIX + 'dashboard', JSON.stringify(response.data.data.dashboard));
    localStorage.setItem(process.env.REACT_APP_WEB_PREFIX + 'clients', JSON.stringify(response.data.data.clients));
    localStorage.setItem(process.env.REACT_APP_WEB_PREFIX + 'splits', JSON.stringify(response.data.data.splits));
    if(subIB != null && subIB === false){
        localStorage.setItem(process.env.REACT_APP_WEB_PREFIX + 'ibs', JSON.stringify(response.data.data.ibs));
        localStorage.setItem(process.env.REACT_APP_WEB_PREFIX + 'role', response.data.data.role);
        localStorage.setItem(process.env.REACT_APP_WEB_PREFIX + 'email', response.data.data.email);
    }
}
export function SetDashboardSelectors(selector){
    localStorage.setItem(process.env.REACT_APP_WEB_PREFIX + 'dashboard_selectors_group', JSON.stringify(selector));
}
export function SetRequests(requests){
    localStorage.setItem(process.env.REACT_APP_WEB_PREFIX + 'requests', JSON.stringify(requests));
}
export function GetDashboardData(){
    const response = {
        dataExits:false,
        role: null,
        email:null,
        dashboard:{},
        clients:{},
        splits:{},
        ibs:{}
    }
    const dashboard = JSON.parse(localStorage.getItem(process.env.REACT_APP_WEB_PREFIX + 'dashboard')) || {};
    const clients = JSON.parse(localStorage.getItem(process.env.REACT_APP_WEB_PREFIX + 'clients')) || [];
    const splits = JSON.parse(localStorage.getItem(process.env.REACT_APP_WEB_PREFIX + 'splits')) || [];
    const ibs = JSON.parse(localStorage.getItem(process.env.REACT_APP_WEB_PREFIX + 'ibs')) || [];
    const email = localStorage.getItem(process.env.REACT_APP_WEB_PREFIX + 'email') || '';
    const role = localStorage.getItem(process.env.REACT_APP_WEB_PREFIX + 'role') || '';

    if(Object.keys(dashboard).length > 0){
        response.dataExits = true;
        response.role = role;
        response.email = email;
        response.dashboard = dashboard;
        response.clients = clients;
        response.splits = splits;
        response.ibs = ibs;
    }
    else{
        response.dataExits = false;
    }

    return response;
}
export function GetDashboardSelectors(){
    const selectors = JSON.parse(localStorage.getItem(process.env.REACT_APP_WEB_PREFIX + 'dashboard_selectors_group')) || {};    
    return selectors;
}
export function GetRequests(){
    const requests = JSON.parse(localStorage.getItem(process.env.REACT_APP_WEB_PREFIX + 'requests')) || {};    
    return requests;
}
export function SetServersdata(servers){
    localStorage.setItem(process.env.REACT_APP_WEB_PREFIX + 'servers', JSON.stringify(servers));
}
export function GetServersdata(){
    const servers = JSON.parse(localStorage.getItem(process.env.REACT_APP_WEB_PREFIX + 'servers')) || [];    
    return servers;
}
export function RemoveDashboardData(){
    localStorage.removeItem(process.env.REACT_APP_WEB_PREFIX + 'dashboard');
    localStorage.removeItem(process.env.REACT_APP_WEB_PREFIX + 'clients');
    localStorage.removeItem(process.env.REACT_APP_WEB_PREFIX + 'splits');
    localStorage.removeItem(process.env.REACT_APP_WEB_PREFIX + 'email');
    localStorage.removeItem(process.env.REACT_APP_WEB_PREFIX + 'role');
    localStorage.removeItem(process.env.REACT_APP_WEB_PREFIX + 'ibs');
}
export function RemoveDashboardSelectors(){
    localStorage.removeItem(process.env.REACT_APP_WEB_PREFIX + 'dashboard_selectors_group');
}
export function RemoveServersData(){
    localStorage.removeItem(process.env.REACT_APP_WEB_PREFIX + 'dashboard_selectors_group');
}
export function RemoveRequests(){
    localStorage.removeItem(process.env.REACT_APP_WEB_PREFIX + 'requests');
}
export function RemoveServers(){
    localStorage.removeItem(process.env.REACT_APP_WEB_PREFIX + 'servers');
}
//*****************************************************/
export function DeleteAllDataForLogin(){
    RemoveDashboardData();
    RemoveDashboardSelectors();
    RemoveServersData();
    RemoveRequests();
    RemoveServers();
}