
import {Spaces} from "../../assets/Variables/SpaceVariables.js";

export const TitleBox = {
  display: "flex",
  flexDirection: "column",
  alignItems: "left",
  marginBottom: "25px",
};

export const Title = {
  fontWeight: "600",
  fontFamily: "Plus Jakarta Sans",
  fontSize: "17px",
};

export const Description = {
  fontWeight: "400",
  fontFamily: "Plus Jakarta Sans",
  fontSize: "14px",
};

export const BalanceCardContentStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
  gap: Spaces.Size_4,
};

export const BalanceCardStyle = {
  height: "100%",
  borderRadius: Spaces.Size_5,
        background:
          "linear-gradient(156deg, rgba(255, 255, 255, 0.50) 10.28%, rgba(255, 255, 255, 0.00) 106.66%) !important",
        border: "1px solid rgba(255, 18, 246, 0.06)",
        boxShadow: "0px 4px 24px 0px rgba(0, 0, 0, 0.08)",
        backdropFilter: "blur(20px)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: `${Spaces.Size_5}`,
  gap: Spaces.Size_4,
};

export const PaperStyle = {
  width: "calc(100vw - 280px)",
  maxWidth: "1110px",
  maxHeight: "calc(100vh - 100px)",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: Spaces.Size_7,
  marginTop: "25px",
  marginLeft: "25px",
  backgroundColor: "transparent",
};

export const PaperChildrenStyle = {
  marginTop: "25px",
  borderRadius: "12px",
  backgroundColor: "transparent",
};

export const PaperPerformance = {
  display: "flex",
  padding: Spaces.Size_7,
  flexDirection: "column",
  alignItems: "flex-start",
  gap: Spaces.Size_7,
  alignSelf: "stretch",
  borderRadius: Spaces.Size_2,
  background: "linear-gradient(156deg, rgba(255, 255, 255, 0.50) 10.28%, rgba(255, 255, 255, 0.00) 106.66%)",
  boxShadow: "0px 4px 24px 0px rgba(0, 0, 0, 0.08)",
  backdropFilter: "blur(20px)"
};

export const AccountStatisticalBoxStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  height: "350px",
  transform: "scale(1)",
};

export const AccountsChartPaperStyle = {
  height: "245px",
  width: "753px",
  padding: Spaces.Size_7,
  gap: Spaces.Size_6,
  borderRadius: Spaces.Size_5,
  background:
    "linear-gradient(156deg, rgba(255, 255, 255, 0.50) 10.28%, rgba(255, 255, 255, 0.00) 106.66%) !important",
  border: "1px solid rgba(255, 18, 246, 0.06)",
  boxShadow: "0px 4px 24px 0px rgba(0, 0, 0, 0.08)",
};
