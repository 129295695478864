import React, { useState } from "react";
import { Typography, Box, Paper } from "@mui/material";
import * as styles from "../Styles";

function UnionpayModal({ requestInfo, formattedDate }) {
  return (
    <Paper sx={styles.paperTreeDetails}>
    <Box sx={styles.labelContainerTreeDetails}>
      <Typography sx={styles.labelTreeDetails}>ID:</Typography>
      <Typography sx={styles.valueTreeDetails}>
        {requestInfo.id}
      </Typography>
    </Box>
    <Box sx={styles.labelContainerTreeDetails}>
      <Typography sx={styles.labelTreeDetails}>
        Request time:
      </Typography>
      <Typography sx={styles.valueTreeDetails}>
        {formattedDate}
      </Typography>
    </Box>
    <Box sx={styles.labelContainerTreeDetails}>
      <Typography sx={styles.labelTreeDetails}>
        Request type:
      </Typography>
      <Typography sx={styles.valueTreeDetails}>
        {requestInfo.request_type.toLowerCase().replace("_", " ")}
      </Typography>
    </Box>
    <Box sx={styles.labelContainerTreeDetails}>
      <Typography sx={styles.labelTreeDetails}>State:</Typography>
      <Typography sx={styles.valueTreeDetails}>
        {requestInfo.state.toLowerCase()}
      </Typography>
    </Box>
    <Box sx={styles.labelContainerTreeDetails}>
      <Typography sx={styles.labelTreeDetails}>Amount:</Typography>
      <Typography sx={styles.valueTreeDetails}>
        {requestInfo.request.amount}
      </Typography>
    </Box>
    <Box sx={styles.labelContainerTreeDetails}>
      <Typography sx={styles.labelTreeDetails}>
        Withdrawal method:
      </Typography>
      <Typography sx={styles.valueTreeDetails}>
        {requestInfo.request.withdrawalMethod}
      </Typography>
    </Box>
    <Box sx={styles.labelContainerTreeDetails}>
      <Typography sx={styles.labelTreeDetails}>Account number:</Typography>
      <Typography sx={styles.valueTreeDetails}>
        {requestInfo.request.accountNumber}
      </Typography>
    </Box>
    <Box sx={styles.labelContainerTreeDetails}>
      <Typography sx={styles.labelTreeDetails}>Beneficiary bank name:</Typography>
      <Typography sx={styles.valueTreeDetails}>
        {requestInfo.request.beneficiaryBankName}
      </Typography>
    </Box>
    <Box sx={styles.labelContainerTreeDetails}>
      <Typography sx={styles.labelTreeDetails}>Branch name:</Typography>
      <Typography sx={styles.valueTreeDetails}>
        {requestInfo.request.branchName}
      </Typography>
    </Box>
    <Box sx={styles.labelContainerTreeDetails}>
      <Typography sx={styles.labelTreeDetails}>Province:</Typography>
      <Typography sx={styles.valueTreeDetails}>
        {requestInfo.request.province}
      </Typography>
    </Box>
    <Box sx={styles.labelContainerTreeDetails}>
      <Typography sx={styles.labelTreeDetails}>City:</Typography>
      <Typography sx={styles.valueTreeDetails}>
        {requestInfo.request.city}
      </Typography>
    </Box>
  </Paper>
  );
}

export default UnionpayModal;
