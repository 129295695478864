import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Typography,
  Paper,
  Box,
  Button,
  Grid,
  Chip,
  IconButton,
  Modal,
} from "@mui/material";
import CardBackOffice from "../../components/Cards/CardBackOffice";
import * as styles from "../BackofficeRequest/Styles.js";
import * as TextVariables from "../../assets/Variables/TextVariables.js";
import { Spaces, mainBox } from "../../assets/Variables/SpaceVariables.js";
import TableComponent from "../../components/Table/TableComponent.js";
import TableToolTip from "../../components/ToolTips/TableToolTip";
import IconComponent from "../../components/Icons/IconComponent";
import { useCookies } from "../../Tools/CookieChecker.js";
import RequestsRequest from "../../Endpoints/RequestsRequest";
import { RemoveDashboardData, SetRequests } from "../../Tools/LocalStorage";
import { GetRequests } from "../../Tools/LocalStorage.js";
import actionLogo from "../../assets/Icons/open_in_new.svg";
import CryptowalletModal from "./ModalsRequest/CrytowalletModal";
import BankwireModal from "./ModalsRequest/BankwireModal";
import VirtualWalletModal from "./ModalsRequest/VirtualWalletModal";
import BitPayModal from "./ModalsRequest/BitPayModal";
import FasaPayModal from "./ModalsRequest/FasaPayModal";
import UnionpayModal from "./ModalsRequest/UnionpayModal";
import JpConnectModal from "./ModalsRequest/JpConnectModal";
import TransferModal from "./ModalsRequest/TransferModal";

const dataGrid = [
  {
    title: "Withdrawal",
    linkTo: "/Requests/withdrawal",
    icon: "output_circle",
  },
  {
    title: "Transfer to a trading account",
    linkTo: "/Requests/transfer",
    icon: "swap_horiz",
  },
  { title: "New tree", linkTo: "/my-trees/create", icon: "account_tree" },
];

function BackofficePage(props) {
  const navigate = useNavigate();
  const [t] = useTranslation("global");
  const { user, type, token, id } = useCookies();
  const [idChat, setIdChat] = useState(null);
  const [rows, setRows] = useState([]);
  const [requestList, setRequestList] = useState([]);
  const [requestInfo, setRequestInfo] = useState({});
  const [modalInfo, setModalInfo] = useState([]);
  const [viewModal, setViewModal] = useState(false);
  const modalInfoRef = useRef(modalInfo);

  useEffect(() => {
    if (idChat !== null) {
      handleOpenChat(idChat, id);
    }
  }, [idChat]);

  useEffect(() => {
    RequestRequestsListData();
  }, []);

  const RequestRequestsListData = async () => {
    try {
      const response = await RequestsRequest();
      if (response.data != null) {
        let storedRequests = response.data.data.requests;
        setRequestList(response.data.data.requests);
        if (
          storedRequests &&
          Array.isArray(storedRequests) &&
          storedRequests.length > 0
        ) {
          let actualRows = storedRequests.map((request, index) => {
            let type = "none";
            if (request.comments.length > 0) {
              type = "view";
              if (
                request.comments[request.comments.length - 1].type !== "USER"
              ) {
                type = "pending";
              }
            } else {
              type = "none";
            }

            let row = {
              id: index,
              chat_link: request.id,
              request_type: request.request_type
                .toLowerCase()
                .replace(/_/g, " ")
                .replace(/\b\w/g, (char) => char.toUpperCase()),
              time_elapsed: request.requeted_ago,
              status: request.state,
              view: type,
              chat_history: "",
            };
            return row;
          });
          setRows(actualRows);
          SetRequests(storedRequests);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  let handleViewMore = (params) => {
    setRequestInfo(requestList.find((request) => request.id === params));
    setViewModal(true);
  };

  const renderComponent = () => {
    const date = requestInfo.request_time;

    const datePart = date.split("T")[0]; // "2024-08-20"
    const timePart = date.split("T")[1].split(".")[0]; // "03:03:27"

    // Reemplazar los guiones por barras en la fecha
    const formattedDatePart = datePart.replace(/-/g, "/");
    const formattedDate = `${formattedDatePart} ${timePart}`;
    console.log(requestInfo.request.withdrawalMethod);
    switch (requestInfo.request.withdrawalMethod) {
      case "Cryptowallet":
        return (
          <CryptowalletModal
            requestInfo={requestInfo}
            formattedDate={formattedDate}
          />
        );
      case "Neteller":
        return (
          <VirtualWalletModal
            requestInfo={requestInfo}
            formattedDate={formattedDate}
          />
        );
      case "Skrill":
        return (
          <VirtualWalletModal
            requestInfo={requestInfo}
            formattedDate={formattedDate}
          />
        );
      case "Uphold":
        return (
          <VirtualWalletModal
            requestInfo={requestInfo}
            formattedDate={formattedDate}
          />
        );
      case "Bitwallet":
        return (
          <VirtualWalletModal
            requestInfo={requestInfo}
            formattedDate={formattedDate}
          />
        );
      case "SticPay":
        return (
          <VirtualWalletModal
            requestInfo={requestInfo}
            formattedDate={formattedDate}
          />
        );
      case "Advcash":
        return (
          <VirtualWalletModal
            requestInfo={requestInfo}
            formattedDate={formattedDate}
          />
        );
      case "Interac e-Transfer":
        return (
          <VirtualWalletModal
            requestInfo={requestInfo}
            formattedDate={formattedDate}
          />
        );
      case "Bank wire transfer" || "JPay":
        return (
          <BankwireModal
            requestInfo={requestInfo}
            formattedDate={formattedDate}
          />
        );
      case "JPay":
        return (
          <BankwireModal
            requestInfo={requestInfo}
            formattedDate={formattedDate}
          />
        );
        case "BitPay":
        return (
          <BitPayModal
            requestInfo={requestInfo}
            formattedDate={formattedDate}
          />
        );
        case "Fasapay":
        return (
          <FasaPayModal
            requestInfo={requestInfo}
            formattedDate={formattedDate}
          />
        );
        case "UnionPay":
        return (
          <UnionpayModal
            requestInfo={requestInfo}
            formattedDate={formattedDate}
          />
        );
        case "JpConnect":
        return (
          <JpConnectModal
            requestInfo={requestInfo}
            formattedDate={formattedDate}
          />
        );
      default:
        return (
          <TransferModal
            requestInfo={requestInfo}
            formattedDate={formattedDate}
          />
        );
    }
  };

  const handleOpenChat = (idChat) => {
    navigate(`/requests/chat/${idChat}`);
  };

  const columns = [
    {
      field: "request_type",
      headerClassName: "header_table",
      renderHeader: () => (
        <TableToolTip title="Order identification number">
          <Box>
            {"Request Type"}
            <IconComponent icon="help" size="12px" />
          </Box>
        </TableToolTip>
      ),
    },
    {
      field: "time_elapsed",
      headerClassName: "header_table",
      renderHeader: () => <>{"Time elapsed"}</>,
    },
    {
      field: "status",
      headerClassName: "header_table",
      renderHeader: () => <>{"Status"}</>,
      renderCell: (params) => {
        if (params.row.status.toLowerCase().includes("aproved")) {
          return (
            <Chip label={params.row.status} sx={styles.ChipAprovedStyle} />
          );
        } else if (params.row.status.toLowerCase().includes("rejected")) {
          return (
            <Chip
              label={params.row.status.toLowerCase()}
              sx={styles.ChipRejectedStyle}
            />
          );
        } else {
          return (
            <Chip
              label={params.row.status.toLowerCase()}
              sx={styles.ChipPendingStyle}
            />
          );
        }
      },
    },
    {
      field: "chat_history",
      headerClassName: "header_table",
      renderHeader: () => <>&nbsp;&nbsp;&nbsp;&nbsp;Chat history</>,
      renderCell: (params) => {
        return (
          <Box sx={styles.chatMainContainer}>
            <Button
              sx={styles.chatButtonContainer}
              onClick={() => setIdChat(params.row.chat_link)}
            >
              <span
                className="material-symbols-outlined"
                style={{ fontSize: "12px", marginRight: "2px" }}
              >
                chat_paste_go
              </span>
              {params.row.view === "pending" && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <Typography sx={{ fontFamily: TextVariables.Caption_2_Bold }}>
                    View Chat
                  </Typography>
                  <Box>
                    <Box sx={styles.noReadIcon}></Box>
                  </Box>
                </Box>
              )}
              {params.row.view === "view" && (
                <Box>
                  <Typography sx={{ fontFamily: TextVariables.Caption_2_Bold }}>
                    View Chat
                  </Typography>
                </Box>
              )}
              {params.row.view === "none" && (
                <Box>
                  <Typography sx={{ fontFamily: TextVariables.Caption_2_Bold }}>
                    New Chat
                  </Typography>
                </Box>
              )}
            </Button>
          </Box>
        );
      },
    },
    {
      field: "action",
      headerClassName: "header_table",
      sortable: false,
      filterable: false,
      hideable: false,
      resizable: false,
      disableColumnMenu: true,
      hideable: false,
      redimensionable: false,
      renderHeader: () => <>{"View more"}</>,
      renderCell: (params) => {
        return (
          <Box
            variant="contained"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              height: "100%",
              padding: 0, // Elimina el padding del botón
              minWidth: "auto", // Asegura que el botón no tenga un ancho mínimo
              width: "auto", // Asegura que el ancho se ajuste al contenido
            }}
          >
            <IconButton onClick={(e) => handleViewMore(params.row.chat_link)}>
              <img
                src={actionLogo}
                alt="Logo"
                style={{ width: 16, height: 16 }}
              />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  return (
    <Box sx={mainBox}>
      <Modal
        open={viewModal}
        onClose={() => {
          setViewModal(false);
        }}
      >
        <Box sx={styles.modalView}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: Spaces.Size_7,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  padding: "0px 0 0 10px",
                  fontFamily: TextVariables.Subtitle_4,
                }}
              >
                Request details
              </Typography>
              <Button
                onClick={() => {
                  setViewModal(false);
                }}
                style={{
                  backgroundColor: "transparent",
                  color: "#000",
                  fontSize: 20,
                  fontWeight: "bold",
                  padding: "0px",
                  minWidth: "0px",
                }}
              >
                <span className="material-symbols-outlined" fontSize="24px">
                  close
                </span>
              </Button>
            </Box>

            <Box sx={styles.modalScrollContainer}>
              <Box sx={styles.modalContainer}>
                {viewModal && renderComponent()}
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Paper elevation={2} sx={styles.mainPaper}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
          }}
        >
          <Typography sx={TextVariables.Subtitle_4}>Requests</Typography>
          <Typography sx={TextVariables.Body_4}>
            Manage your request and keep yourself updated with their status.
          </Typography>
        </Box>
        <Box sx={{ width: "100%" }}>
          <Grid container spacing={2}>
            <Grid item container spacing={3}>
              {dataGrid.map((item, index) =>
                item.title === "New tree" && type === "IB" ? null : (
                  <Grid key={index} item xs={12} sm={6} md={4}>
                    <CardBackOffice
                      _icon={item.icon}
                      title={item.title}
                      linkTo={item.linkTo}
                    />
                  </Grid>
                )
              )}
            </Grid>
          </Grid>
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            height: 602,
            width: "100%",
            gap: Spaces.Size_2,
          }}
        >
          <Typography sx={TextVariables.Body_4_Bold}>
            Requests status
          </Typography>
          <TableComponent rows={rows} columns={columns} />
        </Box>
      </Paper>
    </Box>
  );
}

export default BackofficePage;
