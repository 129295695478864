import React, { useState } from "react";
import { Typography, Box, Button } from "@mui/material";
import * as styles from "./Styles.js";
import * as TextVariables from "../../../assets/Variables/TextVariables.js";
import { Spaces } from "../../../assets/Variables/SpaceVariables.js";
import Colors from "../../../assets/Variables/ColorsVariables.js";
import TitledInput from "../../../components/Inputs/TitledInput.js";
import * as MasterData from "../../../MasterData/MasterData.js";
import TitledSelectorGeneral from "../../../components/Selectors/TitledSelectorGeneral.js";

function BankWireTransferForm({ formValues, errors, handleChange }) {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Box sx={styles.formBox}>
      <Typography sx={{ fontFamily: TextVariables.Body_3_Bold }}>
        Beneficiary bank details
      </Typography>
      {open && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: Spaces.Size_4,
            gap: Spaces.Size_3,
            borderRadius: Spaces.Size_2,
            border: `1px solid ${Colors.Warning_500}`,
            background: Colors.Warning_50,
          }}
        >
          <Box
            sx={{ display: "flex", flexDirection: "row", gap: Spaces.Size_2 }}
          >
            <span
              style={{ color: Colors.Warning_600 }}
              className="material-symbols-outlined"
            >
              error
            </span>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: Spaces.Size_2,
                fontFamily: TextVariables.Body_4_Bold,
                lineHeight: "1",
              }}
            >
              <Typography
                sx={{
                  fontFamily: TextVariables.Body_4_Bold,
                  lineHeight: "1",
                }}
              >
                Commission
              </Typography>
              <Typography
                sx={{ fontFamily: TextVariables.Caption_1, lineHeight: "1" }}
              >
                $35 USD
              </Typography>
            </Box>
          </Box>
          <Button
            variant="text"
            onClick={handleClose}
            sx={{
              minWidth: "auto",
              padding: 0,
              borderRadius: "50%",
              color: "inherit", // Esto hará que la "X" herede el color del texto padre.
              backgroundColor: "transparent",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "transparent", // Mantiene el fondo transparente al hacer hover.
                boxShadow: "none",
              },
            }}
          >
            <span className="material-symbols-outlined">close</span>
          </Button>
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: Spaces.Size_6,
          width: "100%",
        }}
      >
        <Box flex={1}>
          <TitledInput
            name="beneficiaryName"
            title="Beneficiary name"
            value={formValues.beneficiaryName}
            handleChange={handleChange}
            type="text"
            error={errors.beneficiaryName}
            placeholder="Please, enter a beneficiary name"
            helperText={
              errors.beneficiaryName ? (
                <Box sx={styles.emptyFieldLabel}>
                  <span
                    className="material-symbols-outlined"
                    style={{ fontSize: Spaces.Size_3, color: "red" }}
                  >
                    error
                  </span>
                  <Typography
                    sx={{
                      fontFamily: TextVariables.Caption_1,
                      color: Colors.Error_700,
                    }}
                  >
                    Please, enter a beneficiary name
                  </Typography>
                </Box>
              ) : (
                ""
              )
            }
          ></TitledInput>
        </Box>
        <Box flex={1}>
          <TitledInput
            name="beneficiaryAddress"
            title="Beneficiary address"
            value={formValues.beneficiaryAddress}
            handleChange={handleChange}
            type="text"
            error={errors.beneficiaryAddress}
            disabled={formValues.beneficiaryName !== "" ? false : true}
            placeholder="Please, enter a beneficiary address"
            helperText={
              errors.beneficiaryName ? (
                <Box sx={styles.emptyFieldLabel}>
                  <span
                    className="material-symbols-outlined"
                    style={{ fontSize: Spaces.Size_3, color: "red" }}
                  >
                    error
                  </span>
                  <Typography
                    sx={{
                      fontFamily: TextVariables.Caption_1,
                      color: Colors.Error_700,
                    }}
                  >
                    Please, enter a beneficiary address
                  </Typography>
                </Box>
              ) : (
                ""
              )
            }
          ></TitledInput>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: Spaces.Size_6,
          width: "100%",
        }}
      >
        <Box flex={1}>
          <TitledInput
            name="accountNumber"
            title="Account number (IBAN)"
            value={formValues.accountNumber}
            handleChange={handleChange}
            type="text"
            error={errors.accountNumber}
            disabled={formValues.beneficiaryName !== "" ? false : true}
            placeholder="Please, enter an account number"
            helperText={
              errors.accountNumber ? (
                <Box sx={styles.emptyFieldLabel}>
                  <span
                    className="material-symbols-outlined"
                    style={{ fontSize: Spaces.Size_3, color: "red" }}
                  >
                    error
                  </span>
                  <Typography
                    sx={{
                      fontFamily: TextVariables.Caption_1,
                      color: Colors.Error_700,
                    }}
                  >
                    Please, enter an account number
                  </Typography>
                </Box>
              ) : (
                ""
              )
            }
          ></TitledInput>
        </Box>
        <Box flex={1}>
          <TitledInput
            name="bankName"
            title="Bank name"
            value={formValues.bankName}
            handleChange={handleChange}
            type="text"
            error={errors.bankName}
            disabled={formValues.beneficiaryName !== "" ? false : true}
            placeholder="Please, enter a beneficiary address"
            helperText={
              errors.bankName ? (
                <Box sx={styles.emptyFieldLabel}>
                  <span
                    className="material-symbols-outlined"
                    style={{ fontSize: Spaces.Size_3, color: "red" }}
                  >
                    error
                  </span>
                  <Typography
                    sx={{
                      fontFamily: TextVariables.Caption_1,
                      color: Colors.Error_700,
                    }}
                  >
                    Please, enter a beneficiary address
                  </Typography>
                </Box>
              ) : (
                ""
              )
            }
          ></TitledInput>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: Spaces.Size_6,
          width: "100%",
        }}
      >
        <Box flex={1}>
          <TitledInput
            name="swiftCode"
            title="Swift code"
            value={formValues.swiftCode}
            handleChange={handleChange}
            type="text"
            error={errors.swiftCode}
            disabled={formValues.beneficiaryName !== "" ? false : true}
            placeholder="Please, enter a swift code"
            helperText={
              errors.swiftCode ? (
                <Box sx={styles.emptyFieldLabel}>
                  <span
                    className="material-symbols-outlined"
                    style={{ fontSize: Spaces.Size_3, color: "red" }}
                  >
                    error
                  </span>
                  <Typography
                    sx={{
                      fontFamily: TextVariables.Caption_1,
                      color: Colors.Error_700,
                    }}
                  >
                    Please, enter a swift code
                  </Typography>
                </Box>
              ) : (
                ""
              )
            }
          ></TitledInput>
        </Box>
        <Box flex={1}>
          <TitledInput
            name="abaNumber"
            title="ABA number"
            value={formValues.abaNumber}
            handleChange={handleChange}
            type="text"
            error={errors.abaNumber}
            disabled={formValues.beneficiaryName !== "" ? false : true}
            placeholder="Please, enter an ABA number"
            helperText={
              errors.abaNumber ? (
                <Box sx={styles.emptyFieldLabel}>
                  <span
                    className="material-symbols-outlined"
                    style={{ fontSize: Spaces.Size_3, color: "red" }}
                  >
                    error
                  </span>
                  <Typography
                    sx={{
                      fontFamily: TextVariables.Caption_1,
                      color: Colors.Error_700,
                    }}
                  >
                    Please, enter an ABA number
                  </Typography>
                </Box>
              ) : (
                ""
              )
            }
          ></TitledInput>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: Spaces.Size_6,
          width: "100%",
        }}
      >
        <Box flex={1}>
          <TitledInput
            name="sortCode"
            title="Sort code"
            value={formValues.sortCode}
            handleChange={handleChange}
            type="text"
            error={errors.sortCode}
            disabled={formValues.beneficiaryName !== "" ? false : true}
            placeholder="Please, enter a sort code"
            helperText={
              errors.sortCode ? (
                <Box sx={styles.emptyFieldLabel}>
                  <span
                    className="material-symbols-outlined"
                    style={{ fontSize: Spaces.Size_3, color: "red" }}
                  >
                    error
                  </span>
                  <Typography
                    sx={{
                      fontFamily: TextVariables.Caption_1,
                      color: Colors.Error_700,
                    }}
                  >
                    Please, enter a sort code
                  </Typography>
                </Box>
              ) : (
                ""
              )
            }
          ></TitledInput>
        </Box>
        <Box flex={1}>
          <TitledInput
            name="referenceNumber"
            title="Reference number"
            value={formValues.referenceNumber}
            handleChange={handleChange}
            type="text"
            error={errors.referenceNumber}
            disabled={formValues.beneficiaryName !== "" ? false : true}
            placeholder="Please, enter a reference number"
            helperText={
              errors.referenceNumber ? (
                <Box sx={styles.emptyFieldLabel}>
                  <span
                    className="material-symbols-outlined"
                    style={{ fontSize: Spaces.Size_3, color: "red" }}
                  >
                    error
                  </span>
                  <Typography
                    sx={{
                      fontFamily: TextVariables.Caption_1,
                      color: Colors.Error_700,
                    }}
                  >
                    Please, enter a reference number
                  </Typography>
                </Box>
              ) : (
                ""
              )
            }
          ></TitledInput>
        </Box>
      </Box>
      <TitledInput
        name="associatedEmail"
        title="Associated email account"
        value={formValues.associatedEmail}
        handleChange={handleChange}
        type="text"
        error={errors.associatedEmail}
        disabled={formValues.beneficiaryName !== "" ? false : true}
        placeholder="Please, enter a associated email account"
        helperText={
          errors.associatedEmail ? (
            <Box sx={styles.emptyFieldLabel}>
              <span
                className="material-symbols-outlined"
                style={{ fontSize: Spaces.Size_3, color: "red" }}
              >
                error
              </span>
              <Typography
                sx={{
                  fontFamily: TextVariables.Caption_1,
                  color: Colors.Error_700,
                }}
              >
                Please, enter a associated email account
              </Typography>
            </Box>
          ) : (
            ""
          )
        }
      ></TitledInput>
      <TitledSelectorGeneral
        name="intermediaryBank"
        title="Do you need an intermediary bank"
        placeholder={"Please, select an option"}
        handleChange={handleChange}
        options={MasterData.yesNo}
        error={errors.intermediaryBank}
        value={formValues.intermediaryBank}
        disabled={formValues.beneficiaryName !== "" ? false : true}
        helperText={
          errors.intermediaryBank ? (
            <Box sx={styles.emptyFieldLabel}>
              <span
                className="material-symbols-outlined"
                style={{ fontSize: Spaces.Size_3, color: "red" }}
              >
                error
              </span>
              <Typography
                sx={{
                  fontFamily: TextVariables.Caption_1,
                  color: Colors.Error_700,
                }}
              >
                Please, select an option
              </Typography>
            </Box>
          ) : (
            ""
          )
        }
      ></TitledSelectorGeneral>
      {formValues.intermediaryBank === "Yes" && (
        <Box sx={styles.formBox}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: Spaces.Size_6,
              width: "100%",
            }}
          >
            <Box flex={1}>
              <TitledInput
                name="intermediaryBankName"
                title="Intermediary bank name"
                value={formValues.intermediaryBankName}
                handleChange={handleChange}
                type="text"
                error={errors.intermediaryBankName}
                disabled={formValues.beneficiaryName !== "" ? false : true}
                placeholder="Please, enter a intermediary bank name"
                helperText={
                  errors.intermediaryBankName ? (
                    <Box sx={styles.emptyFieldLabel}>
                      <span
                        className="material-symbols-outlined"
                        style={{ fontSize: Spaces.Size_3, color: "red" }}
                      >
                        error
                      </span>
                      <Typography
                        sx={{
                          fontFamily: TextVariables.Caption_1,
                          color: Colors.Error_700,
                        }}
                      >
                        Please, enter a intermediary bank name
                      </Typography>
                    </Box>
                  ) : (
                    ""
                  )
                }
              ></TitledInput>
            </Box>
            <Box flex={1}>
              <TitledInput
                name="intermediaryBankAddress"
                title="Intermediary bank address"
                value={formValues.intermediaryBankAddress}
                handleChange={handleChange}
                type="text"
                error={errors.intermediaryBankAddress}
                disabled={formValues.beneficiaryName !== "" ? false : true}
                placeholder="Please, enter an intermediary bank address"
                helperText={
                  errors.intermediaryBankAddress ? (
                    <Box sx={styles.emptyFieldLabel}>
                      <span
                        className="material-symbols-outlined"
                        style={{ fontSize: Spaces.Size_3, color: "red" }}
                      >
                        error
                      </span>
                      <Typography
                        sx={{
                          fontFamily: TextVariables.Caption_1,
                          color: Colors.Error_700,
                        }}
                      >
                        Please, enter an intermediary bank address
                      </Typography>
                    </Box>
                  ) : (
                    ""
                  )
                }
              ></TitledInput>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: Spaces.Size_6,
              width: "100%",
            }}
          >
            <Box flex={1}>
              <TitledInput
                name="swiftCodeIntermediaryBank"
                title="Swift code"
                value={formValues.swiftCodeIntermediaryBank}
                handleChange={handleChange}
                type="text"
                error={errors.swiftCodeIntermediaryBank}
                disabled={formValues.beneficiaryName !== "" ? false : true}
                placeholder="Please, enter an intermediary swift code"
                helperText={
                  errors.swiftCodeIntermediaryBank ? (
                    <Box sx={styles.emptyFieldLabel}>
                      <span
                        className="material-symbols-outlined"
                        style={{ fontSize: Spaces.Size_3, color: "red" }}
                      >
                        error
                      </span>
                      <Typography
                        sx={{
                          fontFamily: TextVariables.Caption_1,
                          color: Colors.Error_700,
                        }}
                      >
                        Please, enter an intermediary swift code
                      </Typography>
                    </Box>
                  ) : (
                    ""
                  )
                }
              ></TitledInput>
            </Box>
            <Box flex={1}>
              <TitledInput
                name="ibanNumberIntermediaryBank"
                title="Intermediary IBAN number"
                value={formValues.ibanNumberIntermediaryBank}
                handleChange={handleChange}
                type="text"
                error={errors.ibanNumberIntermediaryBank}
                disabled={formValues.beneficiaryName !== "" ? false : true}
                placeholder="Please, enter an intermediary IBAN number"
                helperText={
                  errors.ibanNumberIntermediaryBank ? (
                    <Box sx={styles.emptyFieldLabel}>
                      <span
                        className="material-symbols-outlined"
                        style={{ fontSize: Spaces.Size_3, color: "red" }}
                      >
                        error
                      </span>
                      <Typography
                        sx={{
                          fontFamily: TextVariables.Caption_1,
                          color: Colors.Error_700,
                        }}
                      >
                        Please, enter an intermediary IBAN number
                      </Typography>
                    </Box>
                  ) : (
                    ""
                  )
                }
              ></TitledInput>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: Spaces.Size_6,
              width: "100%",
            }}
          >
            <Box flex={1}>
              <TitledInput
                name="abaNumberIntermediaryBank"
                title="Intermediary ABA number"
                value={formValues.abaNumberIntermediaryBank}
                handleChange={handleChange}
                type="text"
                error={errors.abaNumberIntermediaryBank}
                disabled={formValues.beneficiaryName !== "" ? false : true}
                placeholder="Please, enter an intermediary ABA number"
                helperText={
                  errors.swiftCode ? (
                    <Box sx={styles.emptyFieldLabel}>
                      <span
                        className="material-symbols-outlined"
                        style={{ fontSize: Spaces.Size_3, color: "red" }}
                      >
                        error
                      </span>
                      <Typography
                        sx={{
                          fontFamily: TextVariables.Caption_1,
                          color: Colors.Error_700,
                        }}
                      >
                        Please, enter an intermediary ABA number
                      </Typography>
                    </Box>
                  ) : (
                    ""
                  )
                }
              ></TitledInput>
            </Box>
            <Box flex={1}>
              <TitledInput
                name="sortCodeIntermediaryBank"
                title="Intermediary sort code/CLABE "
                value={formValues.sortCodeIntermediaryBank}
                handleChange={handleChange}
                type="text"
                error={errors.sortCodeIntermediaryBank}
                disabled={formValues.beneficiaryName !== "" ? false : true}
                placeholder="Please, enter an intermediary sort code/CLABE"
                helperText={
                  errors.sortCodeIntermediaryBank ? (
                    <Box sx={styles.emptyFieldLabel}>
                      <span
                        className="material-symbols-outlined"
                        style={{ fontSize: Spaces.Size_3, color: "red" }}
                      >
                        error
                      </span>
                      <Typography
                        sx={{
                          fontFamily: TextVariables.Caption_1,
                          color: Colors.Error_700,
                        }}
                      >
                        Please, enter an intermediary sort code/CLABE
                      </Typography>
                    </Box>
                  ) : (
                    ""
                  )
                }
              ></TitledInput>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default BankWireTransferForm;
