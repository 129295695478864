import { useState, useEffect, useCallback } from "react";
import {
  DataGrid,
  gridPageCountSelector,
  GridPagination,
  useGridApiRef,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import { Box, Typography } from "@mui/material";
import MuiPagination from "@mui/material/Pagination";
import * as styles from "./Styles.js";

function TableComponent(props) {
  const [totalAmount, SetTotalAmount] = useState();
  const apiRef = useGridApiRef();
  const autosizeOptions = {
    includeOutliers: true,
    includeHeaders: true,
    expand: true,
  };

  const handleResize = useCallback(() => {
    if (apiRef.current) {
      apiRef.current.autosizeColumns(autosizeOptions);
    }
  }, [apiRef]);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  useEffect(() => {
    SetTotalAmount(props.TotalAmount);
  }, [props.TotalAmount]);

  function CustomPagination(props) {
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
    return (
      <>
        <Box
          sx={{
            position: "absolute",
            left: "12px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Typography sx={styles.Label1FooterTable}>Pages:</Typography>
          <Typography sx={styles.Label2FooterTable}>{pageCount}</Typography>
          {totalAmount && (
            <Box sx={{
              display: "flex",
              flexDirection: "row",
            }}>
              <Typography sx={{ margin: "0px 10px", fontSize: "12px" }}>•</Typography>
              <Typography sx={styles.Label1FooterTable}>Total Amount:</Typography>
              <Typography sx={styles.Label2FooterTable}>{totalAmount}</Typography>
            </Box>
          )}
        </Box>
        <GridPagination ActionsComponent={Pagination} {...props} />
      </>
    );
  }

  function Pagination({ page, onPageChange, className }) {
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingLeft: "16px",
        }}
        className={className}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexGrow: 1,
          }}
        >
          <MuiPagination
            shape="rounded"
            count={pageCount}
            page={page + 1}
            onChange={(event, newPage) => {
              onPageChange(event, newPage - 1);
            }}
            sx={styles.paginationStyle}
          />
        </Box>
      </Box>
    );
  }


  return (
    <DataGrid
      apiRef={apiRef}
      rows={props.rows}
      columns={props.columns}
      onRowDoubleClick={props.handleDoubleRowClick}
      hideFooterSelectedRowCount
      pagination
      pageSize= {10}
      pageSizeOptions={[5,10]}
      slots={{ pagination: CustomPagination }}
      initialState={{ pagination: { paginationModel: { pageSize: 10 } }, sorting:{sortModel: props.sortConfig} }}
      rowHeight={70}
      density="compact"
      autosizeOptions={autosizeOptions}
      onStateChange={handleResize}
      sx={styles.tableStyles}
    />
  );
}

export default TableComponent;
