
export const PaperStyle ={
    width: '283px', 
    display: 'flex', 
    alignItems: 'center', 
    boxShadow: 'none', 
    border: '1px solid #687B8E', 
    borderRadius: '8px'
}

export const SearchIconStyles = {
    paddingLeft:'12px',
    paddingTop:'5px',
    color: "#43586B"
}

export const InPutBaseStyle = {
    flex: '1', 
    padding: '0px', 
    border: 'none', 
    outline: 'none',
    fontFamily: "Plus Jakarta Sans",
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: "400",
    '& .MuiInputBase-input': {
        '&::placeholder': {
            color: '#000',
        },
    },
}