import React, { useLayoutEffect, useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Typography,
  Paper,
  Box,
  Button,
  Card,
  CardContent,
  CardActions,
  TextField,
  FormControl,
  InputLabel,
  IconButton,
  InputAdornment,
} from "@mui/material";
import * as styles from "../BackofficeRequest/Styles.js";
import * as TextVariables from "../../assets/Variables/TextVariables.js";
import { Spaces, mainBox } from "../../assets/Variables/SpaceVariables.js";
import Colors from "../../assets/Variables/ColorsVariables.js";
import CardChat from "../../components/Cards/CardChat.js";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { GetRequests } from "../../Tools/LocalStorage.js";
import { useCookies } from "../../Tools/CookieChecker.js";
import { ConvertUTCToDateEUFormat } from "../../Tools/DateTimeConvertions";
import ChatRequest from "../../Endpoints/ChatRequest.js";
import LoadingSpinner from "../../components/Loaders/SpinLoader.js";

function Chat(props) {
  const { user, name, type, token } = useCookies();
  const [inputValue, setInputValue] = useState("");
  const chatContainerRef = useRef(null);
  const [registerDate, setRegisterDate] = useState(null);
  const [requestType, setRequestType] = useState(null);
  const [chat, setChat] = useState([]);  
  const [identifier, setIdentifier] = useState("");  
  const navigate = useNavigate();
  const [t] = useTranslation("global");
  const [showNotification, setShowNotification] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalProps, setModalProps] = useState({
    iconType: "",
    title: "",
    message: "",
    onAcceptLabel: undefined,
    onRefuseLabel: undefined,
    onAccept: undefined,
    onRefuse: undefined,
  });

  const handleClickToBackPage = () => {
    navigate("/requests");
  };

  useEffect(() => {
    const path = window.location.pathname;
    const uuid = path.split('/').pop();
    setIdentifier(uuid);
    const requestData = GetRequests()
    const foundItem = requestData.find(item => item.id === uuid);
    
    if(foundItem !== null){
      const date = foundItem.request_time;
      const month = date.slice(5, 7);
      const day = date.slice(8, 10);
      const year = date.slice(2, 4);
      const formattedDate = `${month}/${day}/${year}`;
      setRegisterDate(formattedDate);
      const requestType = foundItem.request_type.toLowerCase().replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
      setRequestType(requestType);

      let storedChats = foundItem.comments;   
      if (storedChats && Array.isArray(storedChats) && storedChats.length > 0) { 
        let actualRows = storedChats.map((chat, index) => {
          let sender = name;
          if(chat.type === 'USER')
            sender = name;
          else
            sender = "Support";

          let row = {
              id: index,
              value: chat.content,
              from: sender,
              date: ConvertUTCToDateEUFormat(chat.time_stamp_create)
          }
          return row;
        });
        
        setChat(actualRows);
      }
    }
  }, [])

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const RequestChatData = async (params, newMessage) => {
    try {
      setLoading(true);
      const response = await ChatRequest(params);
      if (response && response.data != null) {
        setChat([...chat, newMessage]);
      } else {
        setModalProps({
          iconType: "warning",
          title: "Error in request",
          message: "Please try later",
          onAcceptLabel: "Try again",
          onRefuseLabel: "Close",
          onAccept: () => handleOnAccept(),
          onRefuse: () => handleOnRefuse(),
        });
        setShowNotification(true);
      }
    } catch (error) {
      if (error.response) {
        if(error.response.status === 400){
          console.log("error 400:" + error.response.data.message)
          setModalProps({
            iconType: "warning",
            title: "Problem with request",
            message: error.response.data.message,
            onAcceptLabel: "Try again",
            onAccept: () => handleOnAccept()
          });
          setShowNotification(true);
        }
        else{
          setModalProps({
            iconType: "error",
            title: "Problem with service",
            message: "The following error has been caused:" + error.response.data.message + ". please contact an administrator",
            onAcceptLabel: "Try again",
            onAccept: () => handleOnAccept()
          });
          setShowNotification(true);
        }
      }
      else {
        setModalProps({
          iconType: "error",
          title: "Problem with service",
          message: "Please contact an administrator - " + error.message,
          onAcceptLabel: "Accept",
          onAccept: () => handleOnAccept()
        });
        setShowNotification(true);
      }
    }

    setLoading(false);
  };

  const handleButtonClick = () => {
    if (inputValue.trim()) {     
      const request = {
        id: identifier,
        comment: inputValue.trim()
      }
      const newMessage = {
        from: name,
        value: inputValue.trim(),
        date: 'now',
      };

      RequestChatData(request, newMessage)

      setInputValue(""); // Limpiar el campo de texto si es necesario
      scrollChatToBottom();
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleButtonClick();
    }
  };

  const scrollChatToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  // Ejecutar scroll hacia abajo al cargar el componente o actualizar mensajes
  useLayoutEffect(() => {
    scrollChatToBottom();
  }, [chat]);

  return (
    <Box sx={styles.mainBox}>
      {loading ? <LoadingSpinner /> : <></>}
      <Box sx={styles.secondBox}>
        <Box sx={styles.backButtonContainer}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button
              onClick={handleClickToBackPage}
              startIcon={<ArrowBackIosIcon style={{ fontSize: 10 }} />}
              sx={{ textTransform: "none", color: "black" }}
            >
              Go back
            </Button>
          </Box>
        </Box>

        <Box sx={styles.mainPaper}>
          <Paper
            elevation={2}
            sx={{
              height: "559px",
              width: "100%",
              borderRadius: "12px",
              display: "flex",
              flexDirection: "column",
              padding: `0 0 ${Spaces.Size_8} 0`,
            }}
          >
            <Box sx={styles.chatTitle}>
              <Typography sx={{ fontFamily: TextVariables.Body_3_Bold }}>
                Chat history
              </Typography>
              <Box sx={styles.dateTypeBox}>
                <Typography
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "3px",
                    color: Colors.Secondary_400,
                  }}
                >
                  Request date:{" "}
                </Typography>
                <Typography sx={{ color: Colors.Secondary_900 }}>
                    {registerDate || ""}
                </Typography>
                <Typography
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "3px",
                    color: Colors.Secondary_400,
                  }}
                >
                  Request type:{" "}
                </Typography>
                <Typography sx={{ color: Colors.Secondary_900 }}>
                    {requestType || ""}
                </Typography>
              </Box>
            </Box>
            <Box ref={chatContainerRef} sx={styles.chatContainer}>
              {chat.map((item, index) => (
                <CardChat
                  key={index}
                  from={item.from}
                  value={item.value}
                  date={item.date}
                ></CardChat>
              ))}
            </Box>
            <Box sx={styles.inputText}>
              <TextField
                sx={styles.inputContent}
                value={inputValue}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
                placeholder="Type a message"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconButton edge="end" onClick={handleButtonClick}>
                        <span className="material-symbols-outlined">send</span>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              ></TextField>
            </Box>
          </Paper>
        </Box>
      </Box>
    </Box>
  );
}

export default Chat;
