import { Button } from "@mui/material";
import * as TextVariables from "../../assets/Variables/TextVariables";
import {Spaces} from "../../assets/Variables/SpaceVariables.js";
import Colors from "../../assets/Variables/ColorsVariables.js";

function ApplyButton({ children, HandleOnClick, disable }) {
  return (
    <Button
      onClick={HandleOnClick}
      disabled={disable ? disable : false}
      sx={
        disable ? {
          backgroundColor: "#d3d4d5",
          fontFamily: TextVariables.Caption_1_Bold,
          lineHeight: 1,
          color: "white",
          textTransform: "none",
          display: "flex",
          padding: Spaces.Size_3,
          justifyContent: "center",
          alignItems: "center",
          gap: Spaces.Size_2,
          borderRadius: Spaces.Size_2,
          height: "33px",
          "&:hover": {
            backgroundColor: "#FF0000", // Cambia el color al hacer hover
            opacity: 0.8, // Aplica opacidad al hacer hover
          },
        } : {
          backgroundColor: Colors.Primary_700,
          fontFamily: TextVariables.Caption_1_Bold,
          lineHeight: 1,
          color: "white",
          textTransform: "none",
          display: "flex",
          padding: Spaces.Size_3,
          justifyContent: "center",
          alignItems: "center",
          gap: Spaces.Size_2,
          borderRadius: Spaces.Size_2,
          height: "33px",
          "&:hover": {
            backgroundColor: "#FF0000", // Cambia el color al hacer hover
            opacity: 0.8, // Aplica opacidad al hacer hover
          },
        }
      }
    >
      {children}
    </Button>
  );
}

export default ApplyButton;
