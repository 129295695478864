import React, { useState } from "react";
import { Typography, Box, Button } from "@mui/material";
import * as styles from "./Styles.js";
import * as TextVariables from "../../../assets/Variables/TextVariables.js";
import { Spaces } from "../../../assets/Variables/SpaceVariables.js";
import Colors from "../../../assets/Variables/ColorsVariables.js";
import TitledInput from "../../../components/Inputs/TitledInput.js";
import * as MasterData from "../../../MasterData/MasterData.js";
import TextInputWithCounter from "../../../components/Inputs/TitledBigInput.js";
import TitledSelectorGeneral from "../../../components/Selectors/TitledSelectorGeneral.js";

function BitpayForm({ formValues, errors, handleChange }) {
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Box sx={styles.formBox}>
      {open && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: Spaces.Size_4,
            gap: Spaces.Size_3,
            borderRadius: Spaces.Size_2,
            border: `1px solid ${Colors.Warning_500}`,
            background: Colors.Warning_50,
          }}
        >
          <Box
            sx={{ display: "flex", flexDirection: "row", gap: Spaces.Size_2 }}
          >
            <span
              style={{ color: Colors.Warning_600 }}
              className="material-symbols-outlined"
            >
              error
            </span>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: Spaces.Size_2,
              }}
            >
              <Typography
                sx={{
                  fontFamily: TextVariables.Body_4_Bold,
                  lineHeight: "1",
                }}
              >
                Commission
              </Typography>
              <Typography
                sx={{ fontFamily: TextVariables.Caption_1, lineHeight: "1" }}
              >
                Crypto processor charges following fees: Exchange Fee (1.05%),
                Network Fee (0.0003 BTC), Withdrawal Fee (0.0003 BTC)
              </Typography>
            </Box>
          </Box>
          <Button
            variant="text"
            onClick={handleClose}
            sx={{
              minWidth: "auto",
              padding: 0,
              borderRadius: "50%",
              color: "inherit", // Esto hará que la "X" herede el color del texto padre.
              backgroundColor: "transparent",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "transparent", // Mantiene el fondo transparente al hacer hover.
                boxShadow: "none",
              },
            }}
          >
            <span className="material-symbols-outlined">close</span>
          </Button>
        </Box>
      )}
      <Typography sx={{ fontFamily: TextVariables.Body_3_Bold }}>
        BitPay account details
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "row", gap: Spaces.Size_7 }}>
        <Box flex={1}>
          <TitledInput
            name="associatedEmail"
            title="Associated email"
            placeholder="Please, enter your email"
            value={formValues.associatedEmail}
            handleChange={handleChange}
            error={errors.associatedEmail}
            helperText={
              errors.associatedEmail ? (
                <Box sx={styles.emptyFieldLabel}>
                  <span
                    className="material-symbols-outlined"
                    style={{ fontSize: Spaces.Size_3, color: "red" }}
                  >
                    error
                  </span>
                  <Typography
                    sx={{
                      fontFamily: TextVariables.Caption_1,
                      color: Colors.Error_700,
                    }}
                  >
                    Please, enter your email
                  </Typography>
                </Box>
              ) : (
                ""
              )
            }
          ></TitledInput>
        </Box>

        <Box flex={1}>
          <TitledInput
            name="wallet"
            title="Wallet"
            placeholder="Please, enter your wallet"
            value={formValues.wallet}
            handleChange={handleChange}
            error={errors.wallet}
            helperText={
              errors.wallet ? (
                <Box sx={styles.emptyFieldLabel}>
                  <span
                    className="material-symbols-outlined"
                    style={{ fontSize: Spaces.Size_3, color: "red" }}
                  >
                    error
                  </span>
                  <Typography
                    sx={{
                      fontFamily: TextVariables.Caption_1,
                      color: Colors.Error_700,
                    }}
                  >
                    Please, enter your wallet
                  </Typography>
                </Box>
              ) : (
                ""
              )
            }
          ></TitledInput>
        </Box>
      </Box>
    </Box>
  );
}

export default BitpayForm;
