import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Typography, Paper, Box, Button, TextField, MenuItem, Select, InputLabel} from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { WidthFull } from '@mui/icons-material';
import SteeperComponent from "../../../components/Steeper/SteeperComponent";

const steps = ['Tree set-up', 'Group selection', 'Splits'];

function TreeCreateStepTwoPage() {
  const navigate = useNavigate();
  const [t] = useTranslation("global");
  const [activeStep, setActiveStep] = useState(2);
  const [treeName, setTreeName] = useState('');
  const [treeNameError, setTreeNameError] = useState('');
  const [language, setLanguage] = useState('');
  const [buttonEnabled, setButtonEnabled] = useState(false);

  const handleNextStep = () => {
    navigate('/my-trees/create/step-tree');
  }
  const handleClickToBackPage = () => {
    navigate('/my-trees/create/step-one-optional');
  };
  const handleTreeNameChange = (event) => {
    setTreeName(event.target.value);
    if(event.target.value != "" && language != "")
        setButtonEnabled(true);
    else
        setButtonEnabled(false);
    if(event.target.value == "")
        setTreeNameError("Please enter a name for your tree");
    else
        setTreeNameError(null);
  };
  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
    if(event.target.value != "" && treeName != "")
        setButtonEnabled(true);
    else
        setButtonEnabled(false);
    if(event.target.value == "")
        setLanguage("Please select the language for the aplicication form");
    else
        setLanguageError(null);
  };

  return (
    <Paper elevation={0} sx={{ 
      padding: 4, 
      maxWidth: 'calc(100vw - 280px)',
      minHeight: 'calc(100vh - 100px)',
      marginTop: '0px',
      marginLeft: '10px',
      borderRadius: '12px',
      backgroundColor: "#F9F9F9"
    }}>
      <Box sx={{ width: "100%", display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px' }}>
        <Button onClick={handleClickToBackPage} startIcon={<ArrowBackIosIcon style={{ fontSize: 10 }} />} sx={{ textTransform: 'none', color: 'black', fontWeight:'bold'}}>
          Go back
        </Button>
      </Box>
      <Box>
        <Box sx={{ width: '70%', margin:"0 auto"}}>
          <SteeperComponent
            stepList={steps}
            stepNumber={activeStep}
          />
        </Box>
      </Box>
      <Box style={{ display: 'flex', gap: '16px', marginTop: '20px', WidthFull }}></Box>
      <Paper elevation={2} sx={{ 
        padding: 4, 
        maxWidth: '70%',
        minHeight: '300px',
        margin: '0 auto',
        borderRadius: '12px',
        }}>
            
            <Typography variant="h6" sx={{fontWeight:"bold"}}>Step 2: Group selection</Typography>
            <Box sx={{
                backgroundColor: '#f0f4ff',
                padding: '8px',
                borderRadius: '10px',
                display: 'inline-flex',
                alignItems: 'center',
                margin: '10px 0',
                
            }}>
                <Typography variant="body1">Main owner: </Typography>
                <Typography variant="body1" sx={{ marginLeft: '8px', fontWeight: 'bold' }}>Hiroshige Kobayashi</Typography>
            </Box>
                
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
                marginTop: '16px',
            }}>
                <Box sx={{
                    flex: '1',
                    margin: '8px',
                }}>
                    <InputLabel>Tree name</InputLabel>
                    <TextField 
                        variant="outlined" 
                        value={treeName} 
                        onChange={handleTreeNameChange} 
                        placeholder="Enter a name for your tree" 
                        error={!!treeNameError}
                        helperText={treeNameError}
                        sx={{width:'100%'}}
                    />
                </Box>
                <Box sx={{
                    flex: '1',
                    margin: '8px',
                }}>
                    <InputLabel>Language</InputLabel>
                    <Select
                        value={language}
                        onChange={handleLanguageChange}
                        variant="outlined"
                        placeholder="Select a language for the application form"
                        sx={{width:'100%'}}
                    >
                        <MenuItem value="English">English</MenuItem>
                        <MenuItem value="Spanish">Spanish</MenuItem>
                        <MenuItem value="French">French</MenuItem>
                        {/* Agrega más opciones de idioma según sea necesario */}
                    </Select>
                </Box>
            </Box>
                
            <Box sx={{ marginTop: '30px', display: 'flex', justifyContent: 'flex-end' }}> 
                <Button 
                    variant="contained" 
                    onClick={handleNextStep}
                    disabled={!buttonEnabled}
                    sx={{
                        marginTop: '16px',
                        backgroundColor: '#EB0000',
                        color: '#FFF',
                        '&:disabled': {
                          color: '#000',
                        },
                      }}
                    >
                    Continue
                </Button>
            </Box>

    </Paper>
      <Box sx={{ marginBottom: "100px" }}></Box>
    </Paper>
  );
}

export default TreeCreateStepTwoPage;
