import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Typography, Paper, Box, Button } from "@mui/material";
import TableComponent from "../../components/Table/TableComponent";
import TableToolTip from "../../components/ToolTips/TableToolTip";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import * as styles from "../Clients/Styles.js";
import IconComponent from "../../components/Icons/IconComponent";
import SearchBoxComponent from "../../components/SearchBox/SearchBoxComponent";
import { useCookies } from "../../Tools/CookieChecker.js";
import ClientInfoRequest from "../../Endpoints/ClientInfoRequest.js";
import { Spaces, mainBox } from "../../assets/Variables/SpaceVariables.js";
import {
  SetDashboardData,
  GetDashboardData,
  RemoveDashboardData,
  SetDashboardSelectors,
  GetDashboardSelectors,
} from "../../Tools/LocalStorage.js";
import TitledSelector from "../../components/Selectors/TitledSelector";
import ApplyButton from "../../components/Buttons/ApplyButton.js";
import dayjs from "dayjs";
import DateSelector from "../../components/Selectors/DateSelector.js";
import {
  getLocalTimeZoneOffsetInSeconds,
  getTimestamps,
} from "../../Tools/DateTimeConvertions";
import LoadingSpinner from "../../components/Loaders/SpinLoader";

const dateOptions = [
  { label: "Today", value: "Today" },
  { label: "This week", value: "This week" },
  { label: "This month", value: "This month" },
  { label: "This year", value: "This year" },
  { label: "Custom", value: "Custom" },
];

function ClientIndexPage() {
  const { user, name, type, token } = useCookies();
  const navigate = useNavigate();
  const [t] = useTranslation("global");
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState(rows);
  const [dateSelect, setDateSelect] = useState("Today");
  const [brokerSelect, setBrokerSelect] = useState("All");
  const [showDataPickers, setShowDataPickers] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [ibs, setIbs] = useState([]);
  const [applyDisable, setApplyDisable] = useState(false);
  const [loading, setLoading] = useState(false);

  const RequestDashboardData = async (datetime) => {
    try {
      setLoading(true);
      setApplyDisable(true);
      if (brokerSelect && brokerSelect != "All")
        datetime.ib_email = brokerSelect;
      const response = await ClientInfoRequest(datetime);
      if (response.data.data != null) {
        SetDashboardData(response, brokerSelect !== "All");
        const storedClients = response.data.data.clients;
        let actualRows = storedClients.map((client, index) => {
          let balance_client_currency = Math.round( (client.balance / client.factor + Number.EPSILON) * 100 ) / 100;
          let credit_client_currency = Math.round( (client.day_bonus / client.factor + Number.EPSILON) * 100 ) / 100;
          if (client.ccy === "JPY") {
            balance_client_currency = Math.ceil(balance_client_currency);
            credit_client_currency = Math.ceil(credit_client_currency);
          }

          let row = {
            id: index,
            register_date: client.registration_date
              .replace("T", " ")
              .replace("Z", " ")
              .replace("-", "/")
              .replace("-", "/"),
            account: client.login,
            full_name: client.name,
            email: client.email,
            platform: client.server,
            tree: client.tree_name,
            credit: (parseFloat(credit_client_currency) || 0.0).toFixed(2) + " " + client.ccy,
            balance: (parseFloat(balance_client_currency) || 0.0).toFixed(2) + " " + client.ccy,
            volume: (parseFloat(client.day_volume) || 0.0).toFixed(2),
            payout: (parseFloat(client.agent_commissions) || 0.0).toFixed(2),
            currency: client.ccy,
            balance_usd: (parseFloat(client.balance) || 0.0).toFixed(2),
            equity_usd: (parseFloat(client.equity) || 0.0).toFixed(2),
            credit_usd: (parseFloat(client.day_bonus) || 0.0).toFixed(2),
          };
          return row;
        });
        setRows(actualRows);
        setFilteredRows(actualRows);
        const formattedBrokers = response.data.data.ibs.map((item) => ({
          label: item.name,
          value: item.email_ib,
        }));

        if (brokerSelect && brokerSelect == "All")
          setIbs([{ label: "All", value: "All" }, ...formattedBrokers]);
        SetDashboardSelectors({
          dateSelector: dateSelect,
          fromDate: fromDate,
          toDate: toDate,
          ib: brokerSelect,
        });
      } else {
        RemoveDashboardData();
        SetDashboardSelectors({});
      }
    } catch (error) {
      console.log(error);
      RemoveDashboardData();
      SetDashboardSelectors({});
    }

    setLoading(false);
    setApplyDisable(false);
  };

  useEffect(() => {
    if (!GetLocalStorageData()) {
      let requestData = getTimestamps("Today");
      RequestDashboardData(requestData);
      SetDashboardSelectors({
        dateSelector: "Today",
        fromDate: null,
        toDate: null,
        ib: "All",
      });
      setDateSelect("Today");
      setFromDate(null);
      setToDate(null);
      setBrokerSelect("All");
    }
  }, []);

  const GetLocalStorageData = () => {
    let dataSelector = GetDashboardSelectors();
    if (dataSelector != null) {
      if (dataSelector.dateSelector == "Custom") 
        setShowDataPickers(true);
      setDateSelect(dataSelector.dateSelector);
      setFromDate(dayjs(dataSelector.fromDate));
      setToDate(dayjs(dataSelector.toDate));
      setBrokerSelect(dataSelector.ib);
    } else {
      setDateSelect("Today");
      setFromDate(null);
      setToDate(null);
      setBrokerSelect("All");
    }

    let dataResponse = GetDashboardData();
    if (dataResponse.dataExits) {
      const storedClients = dataResponse.clients;
      let actualRows = storedClients.map((client, index) => {
        let balance_client_currency =
          Math.round((client.balance / client.factor + Number.EPSILON) * 100) /
          100;
        let credit_client_currency =
          Math.round(
            (client.day_bonus / client.factor + Number.EPSILON) * 100
          ) / 100;
        if (client.ccy === "JPY") {
          balance_client_currency = Math.ceil(balance_client_currency);
          credit_client_currency = Math.ceil(credit_client_currency);
        }

        let row = {
          id: index,
          register_date: client.registration_date
            .replace("T", " ")
            .replace("Z", " ")
            .replace("-", "/")
            .replace("-", "/"),
          account: client.login,
          full_name: client.name,
          email: client.email,
          platform: client.server,
          tree: client.tree_name,
          credit: (parseFloat(credit_client_currency) || 0.0).toFixed(2) + " " + client.ccy,
          balance: (parseFloat(balance_client_currency) || 0.0).toFixed(2) + " " + client.ccy,
          volume: (parseFloat(client.day_volume) || 0.0).toFixed(2),
          payout: (parseFloat(client.agent_commissions)|| 0.0).toFixed(2),
          currency: client.ccy,
          balance_usd: (parseFloat(client.balance) || 0.0).toFixed(2),
          equity_usd: (parseFloat(client.equity) || 0.0).toFixed(2),
          credit_usd: (parseFloat(client.day_bonus) || 0.0).toFixed(2),
        };
        return row;
      });

      setRows(actualRows);
      setFilteredRows(actualRows);

      const formattedData = dataResponse.ibs.map((item) => ({
        label: item.name,
        value: item.email_ib,
      }));

      setIbs([{ label: "All", value: "All" }, ...formattedData]);

      return true;
    } else {
      console.log("No local data");
      return false;
    }
  };

  const handleDateSelector = (event) => {
    setDateSelect(event.target.value);
    if (event.target.value === "Custom") {
      setShowDataPickers(true);
    } else {
      setShowDataPickers(false);
    }
  };

  const handleBrokerIbSelector = (event) => {
    setBrokerSelect(event.target.value);
  };

  const handleFromChange = (date) => {
    setFromDate(date);
  };

  const handleToChange = (date) => {
    setToDate(date);
  };

  const HandleOnClick = () => {
    if (dateSelect != "Custom") {
      let datetime = getTimestamps(dateSelect);
      RequestDashboardData({
        from: datetime.from,
        to: datetime.to,
      });
      SetDashboardSelectors({
        dateSelector: dateSelect,
        fromDate: null,
        toDate: null,
        ib: brokerSelect,
      });
    } else {
      if (fromDate != null || toDate != null) {
        RequestDashboardData({
          from: fromDate / 1000 + getLocalTimeZoneOffsetInSeconds(),
          to: toDate / 1000 + getLocalTimeZoneOffsetInSeconds() + 86400,
        });
        SetDashboardSelectors({
          dateSelector: dateSelect,
          fromDate: fromDate,
          toDate: toDate,
          ib: brokerSelect,
        });
      }
    }
  };

  const handleDoubleRowClick = (params) => {
    navigate("/my-clients/" + params.row.id);
  };

  const handleFilteredRows = (rowsevent) => {
    setFilteredRows(rowsevent);
  };

  const handleDownload = (dataArray) => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(dataArray);
    XLSX.utils.book_append_sheet(wb, ws, "ClientData");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const fecha = new Date();
    const year = fecha.getFullYear();
    const month = String(fecha.getMonth() + 1).padStart(2, '0');
    const day = String(fecha.getDate()).padStart(2, '0'); 
    saveAs(blob, `${name}_clients_${year}_${month}_${day}.xlsx`);
  };

  const columns = [
    {
      field: "register_date",
      headerClassName: "header_table",
      renderHeader: () => (
        <TableToolTip title="The date of creation of the account">
          <Box>
            {"Register Date "}
            <IconComponent icon="help" size="12px" />
          </Box>
        </TableToolTip>
      ),
    },
    {
      field: "account",
      headerClassName: "header_table",
      renderHeader: () => <>{"Account"}</>,
    },
    {
      field: "full_name",
      headerClassName: "header_table",
      renderHeader: () => <>{"Name"}</>,
    },
    {
      field: "email",
      headerClassName: "header_table",
      renderHeader: () => <>{"Email"}</>,
    },
    {
      field: "platform",
      headerClassName: "header_table",
      renderHeader: () => <>{"Platform"}</>,
    },
    {
      field: "tree",
      headerClassName: "header_table",
      renderHeader: () => (
        <TableToolTip title="The payout structure established for this account.">
          <Box>
            {"Tree "}
            <IconComponent icon="help" size="12px" />
          </Box>
        </TableToolTip>
      ),
    },
    {
      field: "balance",
      headerClassName: "header_table",
      renderHeader: () => <>{"Balance"}</>,
    },
    {
      field: "balance_usd",
      headerClassName: "header_table",
      renderHeader: () => <>{"Balance (USD)"}</>,
    },
    {
      field: "credit",
      headerClassName: "header_table",
      renderHeader: () => <>{"Credit"}</>,
    },
    {
      field: "credit_usd",
      headerClassName: "header_table",
      renderHeader: () => <>{"Credit (USD)"}</>,
    },
    {
      field: "equity_usd",
      headerClassName: "header_table",
      renderHeader: () => <>{"Equity (USD)"}</>,
    },
    {
      field: "volume",
      headerClassName: "header_table",
      renderHeader: () => <>{"Vol"}</>,
    },
    {
      field: "payout",
      headerClassName: "header_table",
      renderHeader: () => <>{"Payout"}</>,
    }
  ];

  return (
    <Box sx={mainBox}>
      {loading ? <LoadingSpinner /> : <></>}
      <Paper
        elevation={2}
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: Spaces.Size_5,
          width: "calc(100vw - 280px)",
          maxWidth: "1600px",
          borderRadius: "12px",
        }}
      >
        <Box sx={styles.TitleBox}>
          <Typography
            variant="h5"
            component="h2"
            gutterBottom
            sx={styles.Title}
          >
            Clients
          </Typography>
          <Button
            variant="outlined"
            sx={styles.ExportButton}
            onClick={() => handleDownload(filteredRows)}
            disabled={filteredRows.length == 0}
          >
            <IconComponent icon="download" size="12px" />
            &nbsp;Export
          </Button>
        </Box>
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "flex-end" }}
        >
          <TitledSelector
            title="Period of time"
            value={dateSelect}
            handleChange={handleDateSelector}
            options={dateOptions}
          />
          {showDataPickers && (
            <>
              <DateSelector
                title="From"
                value={fromDate}
                handleChange={handleFromChange}
              />
              <DateSelector
                title="To"
                value={toDate}
                handleChange={handleToChange}
              />
            </>
          )}
          {type === "BROKER" || type === "MASTER_IB" ? (
            <TitledSelector
              title="IB selector"
              value={brokerSelect}
              handleChange={handleBrokerIbSelector}
              options={ibs}
            />
          ) : null}
          <TableToolTip title="The selected time period only affects the calculation of volume and profit. The client list will always display the user’s total accounts.">
            <Box>
              <ApplyButton HandleOnClick={HandleOnClick} disable={applyDisable}>
                Apply <IconComponent icon="help" size="12px" />
              </ApplyButton>
            </Box>
          </TableToolTip>
          <Box
            sx={{
              marginLeft: "auto",
              marginTop: "25px",
            }}
          >
            <SearchBoxComponent
              rows={rows}
              onFilteredRowsChange={handleFilteredRows}
              placeholder="Search by account number, client's name or email"
              filterFields={["account", "full_name", "email"]}
            ></SearchBoxComponent>
          </Box>
        </Box>
        <Box style={{ height: 602, width: "100%", marginTop: 30 }}>
          <TableComponent
            handleDoubleRowClick={handleDoubleRowClick}
            rows={filteredRows}
            columns={columns}
          />
        </Box>
      </Paper>
    </Box>
  );
}

export default ClientIndexPage;