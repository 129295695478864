import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import classes from "../../scss/LoginForm.module.scss";
import { useTranslation } from "react-i18next";
import swal from "sweetalert2";
import LoginRequest from "../../Endpoints/LoginRequest";
import LoadingLogin from "../../components/gadgets/loadingLogin"
import Show from "../../assets/show.png";
import Hide from "../../assets/hide.png";
import GetIpRequest from "../../Endpoints/GetIpRequest";
import { DeleteAllDataForLogin } from "../../Tools/LocalStorage";

function LoginForm(props) {
    const [t] = useTranslation("global");
    const [user, setUser] = useState("");
    const [pass, setPass] = useState("");
    const [remember, setRemember] = useState(false);
    const [ip, setIp] = useState("");
    const [loading, setLoading] = useState(false);
    const [showUrl, setShowUrl] = useState(false);
    const navigate = useNavigate();

    const cookies = new Cookies();
    cookies.remove("user", { path: '/' });
    cookies.remove("name", { path: '/' });
    cookies.remove("type", { path: '/' });
    cookies.remove("token", { path: '/' });
    DeleteAllDataForLogin();

    const getIp = async () => {
        setLoading(true);

        try{
            const response = await GetIpRequest();
            setLoading(false);
            if(response !== null){ 
                setIp(response.data); 
            }
            else{
                getIp();
            }
        }
        catch (error){
            setLoading(false);
            console.log(error);
            getIp();
        }
    };

    const handleLoginRequest = async (e) => {      
        e.preventDefault();

        if (user.length === 0 || pass.length === 0) {
            swal.fire({
                text: t("login.Please, fill in the missing data"),
                icon: "warning",
                iconColor: "red",
                color: "#363636",
                background: "#FFFFFF",
                confirmButtonColor: "#FF0000"
            });
        }
        else{   
            setLoading(true);
            
            try{
                const response = await LoginRequest({
                    email: user,
                    password: pass,
                    ip: ip,
                    remember: remember,
                });
                if(response.data != null){ 
                    var userResponse= response.data;                       
                    setUser("");
                    setPass("");
                    cookies.set("token", userResponse.data.token, { path: "/" });
                    cookies.set("user", userResponse.data.email, { path: "/" });
                    cookies.set("name", userResponse.data.name, { path: "/" });
                    cookies.set("type", userResponse.data.role, { path: "/" });
                    cookies.set("id", userResponse.data.id, { path: "/" });

                    navigate("/dashboard");
                }else{
                    swal.fire({
                        text: t("login.Invalid access credentials"),
                        icon: "error",
                        iconColor: "red",
                        color: "#363636",
                        background: "#FFFFFF",
                        confirmButtonColor: "#FF0000"
                    }); 
                }
            }
            catch (error){
                console.log(error);
                if (error.response && error.response.status === 400){
                    swal.fire({
                        text: error.response.data.message,
                        icon: "error",
                        iconColor: "red",
                        color: "#363636",
                        background: "#FFFFFF",
                        confirmButtonColor: "#FF0000"
                    }); 
                }
                else{
                    swal.fire({
                        text: t("login.Bad request, please check your account information"),
                        icon: "error",
                        iconColor: "red",
                        color: "#363636",
                        background: "#FFFFFF",
                        confirmButtonColor: "#FF0000"
                    }); 
                }
                
                setLoading(false);
            }
        }
    }

    // Function in charge of limit the number of characters in an input
    function enforceMinMax(el) {
        if (el.value !== "") {
            if (parseInt(el.value.length) > parseInt(el.max.length)) {  
            el.value = el.value.slice(0, el.max.length)
          }
          if (parseInt(el.value) < parseInt(el.min)) {
            el.value = el.min
          }
        }
      };

    // Function to handler when a user click on show password button in login form, allows to see the password written by user
    const showPassword = (e) => {
        setShowUrl(!showUrl);
        var tipo = document.getElementById("user-password");
        if(tipo.type === "password"){
            tipo.type = "text";
        }else{
            tipo.type = "password";
        }
    }

    //USe Effect hook triggered once per render, it calls a function to request the user ip
    useEffect(() => {
        getIp();
    }, []);

    return (
        <div>
            <div className={classes.formContainer}>
                <LoadingLogin loading={loading}/>
                <div className={classes.logoContainer}>
                    <h1 className={classes.welcomeText}>We've got you covered! Solutions for every need you have</h1>
                    <div className={classes.iconsUses}>
                        <div>
                            <img src="https://cabinet.tvmarkets.com/assets/images/login-option-1.svg" alt="Access your cabinet" />
                            <p className={classes.iconsText}>Access your</p>
                            <p className={classes.iconsText}>cabinet</p>
                        </div>
                        <div>
                            <img src="https://cabinet.tvmarkets.com/assets/images/login-option-2.svg" alt="Enter your IB portal" />
                            <p className={classes.iconsText}>Enter your</p>
                            <p className={classes.iconsText}>IB portal</p>
                        </div>
                        <div>
                            <img src="https://cabinet.tvmarkets.com/assets/images/login-option-3.svg" alt="Explore Prop reports" />
                            <p className={classes.iconsText}>Explore</p>
                            <p className={classes.iconsText}>Prop reports</p>
                        </div>
                    </div>
                </div>
                <form className={classes.form} id="form" >
                    <title className={classes.loginTitle}>{t("login.Welcome message")}</title>
                    <div>                   
                        <label className='settingLabel'>{t("GeneralUser.Login")}</label>
                        <input
                            className={classes.input}
                            type="email"
                            id="user-name"
                            name="user-name" 
                            onChange={(e) => setUser(e.target.value)}
                            required
                            disabled={loading}
                            placeholder="Enter your registered email"
                        ></input>
                    </div>

                    <div className={classes.input_wrapper}>
                        <label className='settingLabel'>{t("GeneralUser.Password")}</label>
                        <input
                            className={classes.input}
                            type="password"
                            id="user-password"
                            name="user-password"
                            autoComplete="off"
                            max="99999999999999999999"
                            onKeyUp={(e) => enforceMinMax(e.target)}
                            onChange={(e) => setPass(e.target.value)}
                            required
                            disabled={loading}
                            placeholder="enter your password"
                        ></input>
                        <button className={classes.showPassword} type='button' onClick={(e) => showPassword()}>
                                <img
                                src={showUrl === false ? Show : Hide}
                                alt="show Icon"
                                htmlFor="show-icon" />
                        </button>
                    </div>
                    <div className={classes.rememberForgot}>
                        <div className={classes.rememberMe}>
                            <input
                                type="checkbox"
                                id="rememberMe"
                                name="rememberMe"
                                checked={remember}
                                onChange={(e) => setRemember(e.target.checked)}
                            />
                            <label>Remember Me</label>
                        </div>
                        <div className={classes.forgotPassword}>
                            <a href="#">Forgot password?</a>
                        </div>
                    </div>

                    <button
                        className={classes.loginBtn}
                        onClick={handleLoginRequest}
                        disabled={loading}
                    >
                        {t("login.Login")}
                    </button>

                    <div className={classes.askForAccount}>
                    Don't have an account? <a href="#"> Ask for an account</a>
                    </div>
                </form>
            </div> 
            <div className={classes.disclaimerContainer}>
                <p>Disclaimer</p>
                <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                </p>
            </div>
        </div>
    );

}

export default LoginForm;
